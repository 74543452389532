import { Fragment, useState, useEffect } from 'react';
import { isEmail } from 'validator';
import {
  Grid,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey, lightBlue } from '@mui/material/colors';

import { prepareOptions, makeOption } from '../../../shared_client_utils/formUtils';
import GreyButton from '../../../shared_components/buttons/Grey';
import GreenButton from '../../../shared_components/buttons/Green';
import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../shared_components/CustomDialog';
import { AccountsApi } from '../../../client_http_api';
import { TickIcon, CloseIcon } from '../../../shared_components/icons';
import theme from '../../../shared_client_utils/theme';
import CircularProgressWithBackdrop from '../../../shared_components/CircularProgressWithBackdrop';
import InputLabel from './InputLabel';
import OutlinedInput from '../../../shared_components/Input';

const useStyles = makeStyles()(theme => ({
  newPasswordBox: {
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  inputBox: {
    display: 'flex',
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  passwordBox: {
    marginBottom: theme.spacing(),
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  inputRoot: {},
  newEmail: {
    color: lightBlue[600],
  },
  tickIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  divider: {
    borderTop: `1px solid ${grey[400]}`,
    paddingTop: theme.spacing(2),
  },
}));

const initializeUser = () => ({
  firstName: '',
  lastName: '',
  email: '',
  newEmail: '',
});

const checkDataChanging = (user, email, password) => {
  return (user.email !== email) || password;
};

const validPasswordLength = 8;
const isPasswordLengthValid = (password) => {
  return password.length >= validPasswordLength;
};

const isPasswordValid = (password) => isPasswordLengthValid(password);
const isPasswordConfirmationValid = (password, passwordConfirmation) => {
  return (
    passwordConfirmation === password
  );
};

const AccountSettings = (props) => {
  const { onClose, auth } = props;

  const {classes, cx} = useStyles();

  const [isLoading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const handleChangeEmail = (value) => {
    if (emailError && value) {
      setEmailError(false);
    }
    setEmail(value);
  }

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [
    passwordConfirmationError,
    setPasswordConfirmationError,
  ] = useState(false);
  const handleChangePasswordConfirmation = (value) => {
    if (passwordConfirmationError) {
      setPasswordConfirmationError(false);
    }

    setPasswordConfirmation(value);
  }
  const PasswordConfirmationAdornmentComponent = () => {
    if (!password || passwordConfirmation.length < password.length) {
      return '';
    }

    if (!isPasswordConfirmationValid(password, passwordConfirmation)) {
      return <CloseIcon color="error" />;
    }

    return (
      <TickIcon
        strokeColor={theme.palette.secondary.main}
        className={classes.tickIcon}
      />
    )
  }

  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [
    isCurrentPasswordCheckDone,
    setCurrentPasswordCheckDone,
  ] = useState(false);
  const [isCurrentPasswordValid, setCurrentPasswordValid] = useState(false);
  const CurrentPasswordAdornmentComponent = () => {
    if (!isCurrentPasswordCheckDone) {
      return '';
    }

    if (!isCurrentPasswordValid) {
      return <CloseIcon color="error" />;
    }

    return (
      <TickIcon
        strokeColor={theme.palette.secondary.main}
        className={classes.tickIcon}
      />
    )
  }

  const [user, setUser] = useState(initializeUser);
  useEffect(() => {
    AccountsApi.fetchUser(auth).then((u) => {
      setUser(u);
      setEmail(u.email);
    });
  }, []);

  const handleSave = async () => {
    const isDataChanged = checkDataChanging(user, email, password);
    if (!isDataChanged) {
      setCurrentPasswordError(false);
      setEmailError(false);
      return;
    }

    if (!email) {
      props.handleDisplayFlashMessage('Email cannot be blank', 'error');
      setEmailError(true);
      return;
    }

    if (!isEmail(email)) {
      props.handleDisplayFlashMessage('Enter correct email', 'error');
      setEmailError(true);
      return;
    }

    if (password && !isPasswordConfirmationValid(password, passwordConfirmation)) {
      props.handleDisplayFlashMessage('Confirm password', 'error');
      setPasswordConfirmationError(true);
      return;
    }

    if (currentPassword === '') {
      props.handleDisplayFlashMessage('Enter current password to save changes', 'error');
      setCurrentPasswordError(true);
      return;
    }

    setLoading(true);

    const { error } = await AccountsApi.checkCurrentPassword(
      currentPassword,
      auth,
    );
    if (error) {
      setCurrentPasswordCheckDone(true);
      setCurrentPasswordValid(false);
      setCurrentPasswordError(true);
      setLoading(false);

      if (error.name === 'WrongCurrentPasswordError') {
        props.handleDisplayFlashMessage(error.message, 'error');
      }

      return;
    };

    setCurrentPasswordCheckDone(true);
    setCurrentPasswordValid(true);
    setCurrentPasswordError(false);

    const preparedData = { email, password };
    AccountsApi.updateUser(preparedData, auth).then(({ error }) => {
      if (error && error.name === 'DuplicateEmailError') {
        props.handleDisplayFlashMessage(error.message, 'error');
        setEmailError(true);
        setLoading(false);
        return;
      }

      if (user.email !== email) {
        props.handleDisplayFlashMessage('We\'ve sent confirmation link to the new email');
      }

      onClose();
    });
  }

  return (
    <Fragment>
      <CustomDialogTitle onClose={onClose}>
        Account settings
      </CustomDialogTitle>

      <CustomDialogContent>
        <FormControl className={classes.inputBox}>
          <InputLabel
            shrink
            htmlFor="email"
            className={classes.label}
            focused={false}
            error={emailError}
          >
            Email
          </InputLabel>
          <OutlinedInput
            id="email"
            autoComplete="email"
            type="email"
            onChange={({ target: { value } }) => handleChangeEmail(value)}
            value={email}
            className={classes.inputRoot}
            error={emailError}
          />
          {user.email !== email && (
            <FormHelperText>
              We will send confirmation link to the email above
            </FormHelperText>
          )}
          {user.newEmail && (
            <FormHelperText>
              Confirmation link was sent to <span className={classes.newEmail}>{user.newEmail}</span>. Please check your email
            </FormHelperText>
          )}
        </FormControl>

        <Grid container className={classes.newPasswordBox}>
          <FormControl
            className={cx(
              classes.inputBox,
              classes.passwordBox,
            )}
          >
            <InputLabel
              shrink
              htmlFor="password"
              className={classes.label}
              focused={false}
            >
              New Password
            </InputLabel>
            <OutlinedInput
              id="password"
              autoComplete="new-password"
              type="password"
              onChange={({ target: { value } }) => setPassword(value)}
              value={password}
              className={classes.inputRoot}
              placeholder="Password"
              InputProps={{
                endAdornment: isPasswordValid(password) && (
                  <TickIcon
                    strokeColor={theme.palette.secondary.main}
                    className={classes.tickIcon}
                  />
                ),
              }}
            />
          </FormControl>

          <FormControl
            className={classes.inputBox}
            error={passwordConfirmationError}
          >
            <OutlinedInput
              id="confirm-password"
              autoComplete="new-password"
              type="password"
              onChange={({ target: { value } }) => {
                return handleChangePasswordConfirmation(value);
              }}
              value={passwordConfirmation}
              className={classes.inputRoot}
              placeholder="Confirm password"
              InputProps={{
                endAdornment: <PasswordConfirmationAdornmentComponent />,
              }}
            />
          </FormControl>
        </Grid>

        <FormControl
          className={cx(
            classes.inputBox,
            classes.divider,
          )}
        >
          <OutlinedInput
            id="currentPassword"
            autoComplete="current-password"
            type="password"
            onChange={({ target: { value } }) => setCurrentPassword(value)}
            value={currentPassword}
            className={classes.inputRoot}
            placeholder="Current password"
            error={currentPasswordError}
            InputProps={{
              endAdornment: <CurrentPasswordAdornmentComponent />,
            }}
          />
        </FormControl>
      </CustomDialogContent>

      <CustomDialogActions>
        <GreenButton
          variant="contained"
          size="small"
          onClick={handleSave}
        >
          Save
        </GreenButton>
        <GreyButton
          variant="contained"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </CustomDialogActions>

      <CircularProgressWithBackdrop loading={isLoading} />
    </Fragment>
  );
};

export default AccountSettings;
