import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { rootPath } from '../../../client_routes';

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingLeft: theme.spacing(),
    cursor: 'pointer',
  },
  img: {
    // width: theme.spacing(3),
    height: theme.spacing(17 / 5),
  },
}));

const AppBarLogo = ({ router }) => {
  const {classes} = useStyles();

  const handleRedirectToRootPath = () => {
    const root = rootPath();

    if (router.pathname === root) {
      window.location = root;
    } else {
      router.push(root);
    }
  };

  return (
    <Grid
      container
      className={classes.root}
      onClick={handleRedirectToRootPath}
    >
      <img src="/logo_new.png" className={classes.img} alt="logo"/>
    </Grid>
  );
};

export default AppBarLogo;
