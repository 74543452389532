/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import {
  FormControl,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isValidPhoneNumber } from 'react-phone-number-input';

import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../../shared_components/CustomDialog';
import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import Select from '../Select';
import { loadGiftCards } from '../../slices/giftCardsSlice';
import { prepareOptions, makeOption, validateObjectByMap } from '../../../../shared_client_utils/formUtils';
import { SearchApi } from '../../../../client_http_api';
import SearchInput, { SearchItem, SearchCell } from '../SearchInput';
import ClientForm from './ClientForm';
import Textarea from '../../../../shared_components/Textarea';
import InputLabel from '../InputLabel';

const useStyles = makeStyles()(theme => ({
  main: {
    '& > div:last-of-type': {
      marginBottom: 0,
    },
  },
  wholeBox: {
    marginBottom: '15px',
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  giftCardSelect: {
    width: '100%',
  },
}));

const initializeClientGiftCard = {
  giftCardId: '',
  sender: null,
  recipient: null,
  message: '',
};

const clientsErrorMap = {
  firstName: {
    isValid: value => value && value.length > 0,
  },
  lastName: {
    isValid: value => value && value.length > 0,
  },
  email: {
    isValid: value => value && isEmail(value),
  },
  mobileNumber: {
    isValid: value => value && isValidPhoneNumber(value),
  },
};

const giftCardErrorMap = {
  giftCardId: {
    isValid: value => value && value.length > 0,
  },
};

const formattedClientName = ({ fullName, email }) => {
  return `${fullName} (${email})`;
}

const IssueGiftCard = (props) => {
  const {
    handleCloseAddGiftCard,
    handleSaveChanges,
    giftCards,
    auth
  } = props;

  const {classes} = useStyles();

  const [clientGiftCard, setClientGiftCard] = useState(initializeClientGiftCard);
  const [clientGiftCardErrors, setClientGiftCardErrors] = useState({});
  const handleGiftCardChange = (name, value) => {
    setClientGiftCard({
      ...clientGiftCard,
      [name]: value,
    });
  }

  useEffect(() => {
    const { loadGiftCards, handleDisplayFlashMessage } = props;

    loadGiftCards()
      .catch(error => {
        if (error?.message?.includes('Session is expired')) {
          handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
        }
      })
  }, []);
  const giftCardOptions = prepareOptions(giftCards.byId).map(makeOption);
  const selectedGiftCard = giftCardOptions.find(card => {
    return card.id === clientGiftCard.giftCardId;
  });

  const handleSelectClient = name => (result, setSearchValue) => {
    setClientGiftCard({
      ...clientGiftCard,
      [name]: { ...result },
    });
    setSearchValue(formattedClientName(result));
  }
  const handleInitiateClient = name => (parsedFullName) => {
    setClientGiftCard({
      ...clientGiftCard,
      [name]: {
        ...parsedFullName,
      },
    });
  }
  const handleAddClient = (name, actions) => (client) => {
    const { onCloseForm, setSearchValue } = actions;
    setClientGiftCard({
      ...clientGiftCard,
      [name]: {
        ...client,
        status: 'new',
      },
    });
    setSearchValue(formattedClientName(client));
    onCloseForm();
  }

  const onAdd = () => {
    const { isValid, errors } = validateObjectByMap(
      clientGiftCard,
      giftCardErrorMap,
    );
    if (!isValid) {
      setClientGiftCardErrors(errors);
      return;
    }

    return handleSaveChanges(clientGiftCard);
  }

  return (
    <>
      <CustomDialogTitle onClose={handleCloseAddGiftCard}>
        Add gift card
      </CustomDialogTitle>

      <CustomDialogContent className={classes.main}>
        <FormControl className={classes.wholeBox}>
          <InputLabel
            shrink
            required
            htmlFor="giftCardId"
            className={classes.label}
            focused={false}
          >
            Gift card
          </InputLabel>
          <Select
            id="giftCardId"
            name="giftCardId"
            value={selectedGiftCard}
            options={giftCardOptions}
            onChange={value => handleGiftCardChange('giftCardId', value.value)}
            className={classes.giftCardSelect}
            error={clientGiftCardErrors.giftCardId}
          />
        </FormControl>
        <FormControl className={classes.wholeBox}>
          <InputLabel
            shrink
            htmlFor="sender"
            className={classes.label}
            focused={false}
          >
            From
          </InputLabel>
          <SearchInput
            showCreateLink
            showFormOnCreate
            clearResultsOnClick
            id="sender"
            onSearch={value => SearchApi.searchClients(value, auth)}
            onResultLineClick={handleSelectClient('sender')}
            onCreateLineClick={handleInitiateClient('sender')}
            onOpenPopper={({ isLoading, isDropdownOpened, isFormOpened }) => {
              return isLoading || isDropdownOpened || isFormOpened;
            }}
            form={(actions) => (
              <ClientForm
                client={clientGiftCard.sender}
                handleAddClient={handleAddClient('sender', actions)}
                onClose={actions.onCloseForm}
                clientsErrorMap={clientsErrorMap}
              />
            )}
          >
            {(result, { onClick }) => (
              <SearchItem onClick={onClick}>
                <SearchCell>{result.fullName}</SearchCell>
                <SearchCell align="right">{result.mobileNumber}</SearchCell>
              </SearchItem>
            )}
          </SearchInput>
        </FormControl>
        <FormControl className={classes.wholeBox}>
          <InputLabel
            shrink
            htmlFor="recipient"
            className={classes.label}
            focused={false}
          >
            To
          </InputLabel>
          <SearchInput
            showCreateLink
            showFormOnCreate
            clearResultsOnClick
            id="recipient"
            onSearch={value => SearchApi.searchClients(value, auth)}
            onResultLineClick={handleSelectClient('recipient')}
            onCreateLineClick={handleInitiateClient('recipient')}
            onOpenPopper={({ isLoading, isDropdownOpened, isFormOpened }) => {
              return isLoading || isDropdownOpened || isFormOpened;
            }}
            form={(actions) => (
              <ClientForm
                client={clientGiftCard.recipient}
                handleAddClient={handleAddClient('recipient', actions)}
                onClose={actions.onCloseForm}
                clientsErrorMap={clientsErrorMap}
              />
            )}
          >
            {(result, { onClick }) => (
              <SearchItem onClick={onClick}>
                <SearchCell>{result.fullName}</SearchCell>
                <SearchCell align="right">{result.mobileNumber}</SearchCell>
              </SearchItem>
            )}
          </SearchInput>
        </FormControl>
        <FormControl className={classes.wholeBox}>
          <InputLabel
            shrink
            htmlFor="recipientId"
            className={classes.label}
            focused={false}
          >
            Message
          </InputLabel>
          <Textarea
            id="message"
            name="message"
            value={clientGiftCard.message}
            onChange={({ target: { name, value }}) => {
              return handleGiftCardChange(name, value);
            }}
            minRows="5"
            classes={{
              root: classes.inputBox,
            }}
          />
        </FormControl>
      </CustomDialogContent>

      <CustomDialogActions>
        <GreenButton
          variant="contained"
          size="small"
          onClick={onAdd}
        >
          Add
        </GreenButton>
        <GreyButton
          variant="contained"
          size="small"
          onClick={handleCloseAddGiftCard}
        >
          Cancel
        </GreyButton>
      </CustomDialogActions>
    </>
  );
};

const mapStateToProps = ({ giftCards, auth }) => ({ giftCards, auth });

const mapDispatchToProps = dispatch => ({
  loadGiftCards: bindActionCreators(loadGiftCards, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueGiftCard);
