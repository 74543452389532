import { useState, useEffect } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const BaseSelect = (props) => {
  const {
    clearable,
    creatable,
    value,
    onChange,
    onCreate,
    blurOnSelect,
    ...restProps
  } = props;

  const [currentOption, setCurrentOption] = useState(() => value || null);
  const handleUpdateCurrentOption = (option) => {
    if (blurOnSelect) {
      return setCurrentOption(() => null);
    }

    return setCurrentOption(option);
  };
  useEffect(() => setCurrentOption(value), [value]);

  let handleChange;
  let filterOptions;
  if (creatable) {
    handleChange = async (event, option) => {
      if (typeof option === 'string') {
        return handleUpdateCurrentOption({ label: option });
      } else if (option && option.inputValue) {
        const { inputValue } = option;
        await onCreate(inputValue);
        const newOption = {
          label: inputValue,
          value: inputValue,
        };
        handleUpdateCurrentOption(newOption);
        return onChange({
          ...option,
          ...newOption,
        });
      } else {
        handleUpdateCurrentOption(option);
        return onChange(option);
      }
    };
    filterOptions = (options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          label: `Create "${inputValue}"`,
        });
      }

      return filtered;
    };
  } else {
    handleChange = (event, option) => {
      handleUpdateCurrentOption(option);
      return onChange(option);
    }
    filterOptions = (options, params) => filter(options, params);
  }

  return (
    <Autocomplete
      disablePortal
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      blurOnSelect={blurOnSelect}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, selected) => {
        return selected && option.value === selected.value;
      }}
      {...restProps}
      value={currentOption}
      onChange={handleChange}
      filterOptions={filterOptions}
      disableClearable={!clearable}
    />
  );
};

BaseSelect.defaultProps = {
  clearable: false,
  creatable: false,
  blurOnSelect: false,
  onChange: () => {},
  onCreate: () => {},
};

export default BaseSelect;
