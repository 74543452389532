/*
Duplicate file server/utils/invoice-utils.js
*/

import Dinero from 'dinero.js';
import moment from 'moment-timezone';

import { CreditsApi } from '../client_http_api';

const hundredPercent = 100;

const calculateUnitPriceFromPriceInclusiveTax = (price, taxRate) => {
  return price
    .multiply(hundredPercent)
    .divide(hundredPercent + taxRate);
};

const calculateUnitTaxFromPriceInclusiveTax = (priceWithTax, unitPrice) => {
  return priceWithTax.subtract(unitPrice);
};

const calculateUnitTaxFromUnitPrice = (unitPrice, taxRate) => {
  return unitPrice.allocate([hundredPercent - taxRate, taxRate])[1];
};

export const calculatePricesAndTax = ({ currency, quantity, ...options }) => {
  let total;
  const taxRate = Number(options.taxRate);

  const sourcePrice = Dinero({
    currency,
    amount: options.price,
  });

  if (!taxRate || taxRate === 0) {
    total = sourcePrice.multiply(quantity);

    return {
      price: sourcePrice.getAmount(),
      tax: 0,
      totalPrice: total.getAmount(),
    };
  }

  let unitPrice;
  let unitTax;
  if (options.isTaxIncluded) {
    unitPrice = calculateUnitPriceFromPriceInclusiveTax(sourcePrice, taxRate);
    unitTax = calculateUnitTaxFromPriceInclusiveTax(sourcePrice, unitPrice);
    total = sourcePrice.multiply(quantity);
  } else {
    unitPrice = sourcePrice;
    unitTax = calculateUnitTaxFromUnitPrice(unitPrice, taxRate);
    total = unitPrice.add(unitTax).multiply(quantity);
  }

  const tax = unitTax.multiply(quantity);

  return {
    price: unitPrice.getAmount(),
    tax: tax.getAmount(),
    totalPrice: total.getAmount(),
  };
};

export const calculateCreditBalance = async (clientId, currency, items, auth) => {
  const clientCreditBalance =
    await CreditsApi.getClientCreditBalance(clientId, auth);
  const creditBalance = Dinero({ currency, amount: clientCreditBalance });

  const creditsAmountFromItems = items
    .filter(item => item.type === 'creditRedeem' && item.status !== 'deleted')
    .reduce((acc, item) => {
      const newCreditAmount = Dinero({ currency, amount: item.newCreditAmount });
      return acc.add(newCreditAmount);
    }, Dinero({ currency, amount: 0 }));

  const freeBalanceFromDeletedItems = items
    .filter(item => item.type === 'creditRedeem' && item.status === 'deleted')
    .reduce((acc, item) => {
      const newCreditAmount = Dinero({ currency, amount: item.newCreditAmount });
      return acc.add(item.totalPrice).subtract(newCreditAmount);
    }, Dinero({ currency, amount: 0 }));

  const actualBalance = creditBalance
    .add(freeBalanceFromDeletedItems)
    .subtract(creditsAmountFromItems);

  return actualBalance.getAmount();
}

export const formatInvoiceDatetime = (datetimeTz) => {
  return moment(datetimeTz).format('ll');
};

export const openInvoiceQueryName = 'open-invoice';

export const formatAmount = (amount, isNegativePrice) => {
  return isNegativePrice ? `-${amount}` : amount;
};

export const calculateMaxOrder = (items) => {
  const orders = items.map((item) => item.order);
  return Math.max.apply(null, orders);
};

const formatInvoiceNumber = (number) => {
  if (!number) {
    return '0000';
  }

  const stringNumber = number.toString();
  if (stringNumber.length >= 4) {
    return stringNumber;
  }

  const zeroCount = 4 - stringNumber.length;
  return Array.from(Array(zeroCount)).reduce((acc) => `0${acc}`, stringNumber);
};
export const printInvoiceNumber = (number) => {
  const formattedNumber = formatInvoiceNumber(number);
  return `#${formattedNumber}`;
};
