/* eslint-disable no-prototype-builtins */
import { makeStyles } from 'tss-react/mui';
import {
  Grid
} from '@mui/material';
import { isEmpty } from 'lodash';

import {
  EmailIcon,
} from '../../../../shared_components/icons';
import RedButton from '../../../../shared_components/buttons/Red';
import WhiteButton from '../../../../shared_components/buttons/White';
import GreenButton from '../../../../shared_components/buttons/Green';
import Tooltip from '../Tooltip';

const useStyles = makeStyles()(theme => ({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: '1.5px solid #272727',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  topControls: {
    width: '100%',
    justifyContent: 'space-between',
  },
  middleControls: {
    display: 'flex',
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
  },
  bottomControls: {
    display: 'flex',
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
  },
  emailIcon: {
    height: '18px',
  },
  redButton: {
    width: '100%',
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  topButtonWrapper: {
    width: '48%',
  },
  topButton: {
    width: '100%',
  },
}));

const getTitleForCancelButton = (areAnyPayments, isUnsavedChanges) => {
  if (isUnsavedChanges) {
    return 'You have unsaved changes';
  } else if (areAnyPayments) {
    return 'Invoice with payments cannot be deleted';
  }
  return '';
};

const MobileBottomButtons = (props) => {
  const {
    invoice: { items, payments, ...invoice },
    preparedItems,
    isApplyPaymentDisabled,
    client,
    handleOpenApplyPayment,
    stripeConnected,
    isInvoiceNew,
    isInvoiceFullyPaid,
    isUnsavedChanges,
    handleOpenRequestOnlinePayment,
    onClickOpenEmailWindow,
    handlePrintInvoice,
    preparedPayments,
    onClickOpenDeleteInvoiceWindow,
    handleCreateInvoice,
    handleUpdateInvoice
  } = props;

  const {classes} = useStyles();
  const isApplyPaymentBtnDisabled = !client?.id?.length || !invoice?.locationId?.length || !items?.filter((i) => i?.hasOwnProperty('description'))?.length >= 1
  const isNotPaid = stripeConnected && !isInvoiceNew && !isInvoiceFullyPaid;
  return (
    <Grid container className={classes.main}>
      <Grid container className={classes.topControls}>
        {invoice?.isChangeable && !isEmpty(preparedItems) && !isApplyPaymentDisabled && (
          <Grid item className={isNotPaid ? classes.topButtonWrapper : classes.topButton}>
            <GreenButton
              disabled={isApplyPaymentBtnDisabled}
              variant="contained"
              size="small"
              onClick={handleOpenApplyPayment}
              className={classes.topButton}
            >
              Apply payment
            </GreenButton>
          </Grid>
        )}
        {isNotPaid && (
          <Tooltip
            open={isUnsavedChanges}
            title="You have unsaved changes"
          >
            <Grid item className={classes.topButtonWrapper}>
              <GreenButton
                variant="contained"
                size="small"
                disabled={isUnsavedChanges}
                onClick={handleOpenRequestOnlinePayment}
                className={classes.topButton}
              >
                Request payment
              </GreenButton>
            </Grid>
          </Tooltip>
        )}
      </Grid>
      {!isInvoiceNew && (
        <Grid container className={classes.middleControls}>
          <Tooltip
            open={isUnsavedChanges}
            title="You have unsaved changes"
          >
            <Grid item className={classes.topButtonWrapper}>
              <WhiteButton
                variant="contained"
                size="small"
                onClick={onClickOpenEmailWindow}
                disabled={isUnsavedChanges || invoice?.amountDue?.getAmount() > 0}
                startIcon={<EmailIcon className={classes.emailIcon}/>}
                className={classes.topButton}
              >
                Email
              </WhiteButton>
            </Grid>
          </Tooltip>

          <Tooltip
            open={isUnsavedChanges}
            title="You have unsaved changes"
          >
            <Grid item className={classes.topButtonWrapper}>
              <WhiteButton
                variant="contained"
                size="small"
                onClick={handlePrintInvoice}
                disabled={isUnsavedChanges}
                className={classes.topButton}
              >
                Print
              </WhiteButton>
            </Grid>
          </Tooltip>
        </Grid>
      )}
      <Grid container className={classes.bottomControls}>
        {!isInvoiceNew && (
          <Tooltip
            open={isUnsavedChanges || preparedPayments?.length !== 0}
            title={getTitleForCancelButton(
              preparedPayments?.length !== 0,
              isUnsavedChanges,
            )}
          >
            <Grid item className={classes.topButtonWrapper}>
              <RedButton
                variant="contained"
                size="small"
                onClick={onClickOpenDeleteInvoiceWindow}
                className={classes.redButton}
                disabled={
                  isUnsavedChanges || preparedPayments?.length > 0
                }
              >
                Cancel
              </RedButton>
            </Grid>
          </Tooltip>
        )}
        <Grid item className={classes.topButtonWrapper}>
          <GreenButton
            variant="contained"
            size="small"
            onClick={isInvoiceNew ? handleCreateInvoice : handleUpdateInvoice}
            disabled={!isUnsavedChanges || !client?.id?.length}
            className={classes.topButton}
          >
            {isInvoiceNew ? 'Create' : 'Save'}
          </GreenButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileBottomButtons;
