/* eslint-disable import/no-unresolved */
import { DialogContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    overflow: 'initial',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 3, 3),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2, 3),
    },
  },
}));

const CustomDialogContent = ({ children, className, contentStyle = {} }) => {
  const {classes} = useStyles();

  return (
    <DialogContent
      className={className}
      style={contentStyle}
      classes={{
        root: classes.root,
      }}
    >
      {children}
    </DialogContent>
  );
};

CustomDialogContent.defaultProps = {
  className: '',
};

export default CustomDialogContent;
