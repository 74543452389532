import { makeStyles } from 'tss-react/mui';

import IconButton from './Icon';

const useStyles = makeStyles()((theme) => ({
  iconRoot: {
    height: theme.spacing(10/4),
    width: 'auto',
  },
}));

const BaseIcon = (props) => {
  const {
    component,
    className,
    onClick,
    iconProps,
    classes: parentClasses,
  } = props;
  const {classes} = useStyles();
  const Component = component;

  return (
    <IconButton onClick={onClick} className={className} size="large">
      <Component
        {...iconProps}
        className={parentClasses.icon}
        classes={{
          root: classes.iconRoot,
        }}
      />
    </IconButton>
  );
};

BaseIcon.defaultProps = {
  component: '',
  className: '',
  classes: {
    icon: '',
  },
  iconProps: {},
};

export default BaseIcon;
