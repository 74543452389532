import Dinero from 'dinero.js';

const cents = 100;
export const amountInCents = amount => Number(amount) * cents;

export const formatMoney = money => money.toFormat('0.00');

const extractFieldsAndOptions = (args) => {
  let fields = args;
  let options = {};
  if (typeof args[0] === 'object') {
    fields = args.slice(1);
    options = args[0];
  }

  return { fields, options };
};
const moneytizeFunc = (fields, options) => (object) => {
  const moneyFields = fields.reduce((acc, field) => {
    const money = Dinero({
      amount: object[field],
      currency: options.currency || object.currency,
    });

    return {
      ...acc,
      [`${field}Money`]: money,
    };
  }, {});

  return {
    ...object,
    ...moneyFields,
  };
};
export const moneytizeObject = (object, ...args) => {
  const { fields, options } = extractFieldsAndOptions(args);
  return moneytizeFunc(fields, options)(object);
}
export const moneytizeObjects = (objects, ...args) => {
  const { fields, options } = extractFieldsAndOptions(args);
  return objects.map(moneytizeFunc(fields, options));
}

export const isPositive = (money) => {
  return money && !money.isZero() && money.isPositive();
};
