import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import MobileMenu from '../MobileMenu';

const useStyles = makeStyles()((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileMenu: {
    padding: theme.spacing(1/2),
  },
}));

const DropdownMenu = (props) => {
  const { children, actionMenuRef } = props;
  const {classes} = useStyles();

  return (
    <Grid
      container
      ref={actionMenuRef}
      className={classes.root}
    >
      <MobileMenu className={classes.mobileMenu}>
        {children}
      </MobileMenu>
    </Grid>
  );
};

export default DropdownMenu;
