import { useState, Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import Select from '../Select';
import OutlinedInput from '../../../../shared_components/Input';

import { prepareOptions, makeOption } from '../../../../shared_client_utils/formUtils';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
  },
  selectBox: {
    width: '170px',
    fontSize: '13px',
    marginTop: theme.spacing(),
  },
  inputBox: {
    width: '170px',
    marginTop: theme.spacing(15/8),
  },
  inputValue: {
    margin: 0,
  },
  buttonBox: {
    marginTop: theme.spacing(15/8),
    justifyContent: 'space-between',
  },
}));

const Discount = (props) => {
  const {
    onClose, discounts, handleAddItemDiscount, handleAddTotalDiscount,
    itemDiscountId,
  } = props;
  const preparedDiscounts = prepareOptions(discounts.byId);
  const discountOptions = preparedDiscounts.map(makeOption);

  const {classes} = useStyles();
  const [discountOption, setDiscountOption] = useState();
  const [value, setValue] = useState();
  const [valueError, setValueError] = useState(false);

  const onChangeDiscount = (option) => {
    const discount = preparedDiscounts.find(({ id }) => id === option.value);

    setDiscountOption(option);
    setValue(discount.formattedValue);
  }

  const applyDiscount = () => {
    if (!value || parseInt(value) <= 0) {
      setValueError(true);
      return;
    }

    if (itemDiscountId) {
      return handleAddItemDiscount(discountOption.value, value);
    } else {
      return handleAddTotalDiscount(discountOption.value, value);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography color="secondary" variant="subtitle1">
          Apply discount
        </Typography>
      </Grid>
      <Grid item className={classes.selectBox}>
        <Select
          id="discount"
          // placeholder="Choose discount"
          onChange={onChangeDiscount}
          options={discountOptions}
          value={discountOption}
        />
      </Grid>
      {discountOption && (
        <Fragment>
          <Grid item className={classes.inputBox}>
            <OutlinedInput
              name="value"
              value={value}
              onChange={({ target }) => setValue(target.value)}
              placeholder="Example, 5% or 5"
              autoComplete="off"
              className={classes.inputValue}
              error={valueError}
            />
          </Grid>
          <Grid container className={classes.buttonBox}>
            <GreenButton
              variant="contained"
              size="small"
              onClick={applyDiscount}
            >
              Add
            </GreenButton>
            <GreyButton
              variant="text"
              size="small"
              onClick={onClose}
            >
              Cancel
            </GreyButton>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default Discount;
