import { useState } from 'react';
import { isEmail } from 'validator';
import {
  FormControl,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../../shared_components/CustomDialog';
import GreenButton from '../../../../shared_components/buttons/Green';
import { formatMoney } from '../../../../shared_client_utils/moneyUtils';
import MoneyInput from '../MoneyInput';
import InputLabel from '../InputLabel';
import Textarea from '../../../../shared_components/Textarea';
import CircularProgressWithBackdrop from '../../../../shared_components/CircularProgressWithBackdrop';
import { InvoicesApi } from '../../../../client_http_api';
import OutlinedInput from '../../../../shared_components/Input';
import RedButton from '../../../../shared_components/buttons/Red';

const useStyles = makeStyles()(theme => ({
  main: {
    '& > div:last-of-type': {
      marginBottom: 0,
    },
  },
  inputBox: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  inputRoot: {
    width: '100%',
  },
  amountInput: {
    width: '35%',
  },
  button: {
    minWidth: '100px',
  },
  redButton: {
    minWidth: '100px',
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  }
}));

const RequestOnlinePayment = (props) => {
  const {
    onClose,
    invoice: { id, amountDue, clientEmail },
    business: { currency },
    auth,
    handleDisplayFlashMessage,
    deferredRequestLoadInvoiceEmails
  } = props;

  const {classes} = useStyles();

  const [email, setEmail] = useState(clientEmail);
  const [emailError, setEmailError] = useState(false);
  const handleChangeEmail = (value) => {
    if (emailError && value) {
      setEmailError(false);
    }
    setEmail(value);
  }

  const [message, setMessage] = useState('');

  const [isLoading, setLoading] = useState(false);

  const handleRequestOnlinePayment = async () => {
    if (!isEmail(email)) {
      setEmailError(true);
      return;
    }
    setLoading(true);

    const preparedRequest = {
      email,
      message,
      staffId: auth.currentStaff.id,
      amount: amountDue.getAmount(),
      currency: amountDue.getCurrency(),
    };
    try {
      await InvoicesApi.requestOnlinePayment(id, preparedRequest, auth);
      deferredRequestLoadInvoiceEmails()
      handleDisplayFlashMessage('Request has been sent succesfully');
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
    }
    onClose();
  }

  return (
    <>
      <CustomDialogTitle onClose={onClose}>
        Request online payment
      </CustomDialogTitle>

      <CustomDialogContent className={classes.main}>
        <FormControl className={classes.inputBox}>
          <InputLabel
            shrink
            htmlFor="amount"
            className={classes.label}
            focused={false}
          >
            Amount requested
          </InputLabel>
          <MoneyInput
            disabled
            id="amount"
            type="amount"
            value={formatMoney(amountDue)}
            currency={currency}
            className={classes.amountInput}
          />
        </FormControl>
        <FormControl
          className={classes.inputBox}
          error={emailError}
        >
          <InputLabel
            shrink
            required
            htmlFor="email"
            className={classes.label}
            focused={false}
          >
            Email address to send invoice to
          </InputLabel>
          <OutlinedInput
            id="email"
            autoComplete="email"
            type="email"
            onChange={({ target: { value } }) => handleChangeEmail(value)}
            value={email}
            className={classes.inputRoot}
          />
          {emailError && <FormHelperText>invalid email</FormHelperText>}
        </FormControl>
        <FormControl className={classes.inputBox}>
          <InputLabel
            shrink
            htmlFor="message"
            className={classes.label}
            focused={false}
          >
            Message
          </InputLabel>
          <Textarea
            id="message"
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
            className={classes.inputRoot}
            minRows="5"
          />
        </FormControl>
      </CustomDialogContent>

      <CustomDialogActions>
        <GreenButton
          variant="contained"
          size="small"
          onClick={handleRequestOnlinePayment}
          className={classes.button}
        >
          Send
        </GreenButton>
        <RedButton
          variant="contained"
          size="small"
          onClick={onClose}
          className={classes.redButton}
        >
          Cancel
        </RedButton>
      </CustomDialogActions>

      <CircularProgressWithBackdrop loading={isLoading} />
    </>
  );
};

export default RequestOnlinePayment;
