import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CardSection from './CardSection';

const useStyles = makeStyles()((theme) => ({
  main: {
    padding: '33px'
  },
  title: {
    color: '#808190',
    fontSize: '20px',
    lineHeight: '26px',
    marginBottom: theme.spacing(),
    paddingTop: '1.5rem',
  },
  pargraph: {
    color: '#808190',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: theme.spacing(),
  },
  body: {
    color: '#808190',
    fontSize: '14px',
    lineHeight: '20px',
  }
}));

function Payment(props) {
  const {
    creditCardHandler,
    handleDisplayFlashMessage,
    salesSettings,
    parentClasses = {},
    isLoading,
    title = 'Reserve your appointment with a card',
    pargraph = 'To reserve your appointment, we require a credit card on file. Your card won\'t be charged at this time, but it will be securely stored for any no-show or cancellation fees as per our policy.',
    type = 'client',
    disposition = 'horizontal'
  } = props;
  const {classes} = useStyles();

  if(salesSettings?.stripeAccountId?.length || type === 'business') {
    const stripeKey = process?.env?.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
    const stripePromise = loadStripe(stripeKey,
      type === 'business' ? null : { stripeAccount: salesSettings?.stripeAccountId }
    );

    return (
      <Box className={parentClasses.main || classes.main}>
        <Box>
          <Typography className={parentClasses.title || classes.title}>{title}</Typography>
          {pargraph?.length ? (<Typography variant="p" component="p" className={classes.pargraph}>{pargraph}</Typography>) : null}
        </Box>
        <Elements stripe={stripePromise}>
          <CardSection
            {...props}
            parentClasses={parentClasses}
            stripeAccountId={salesSettings?.stripeAccountId}
            handleDisplayFlashMessage={handleDisplayFlashMessage}
            creditCardHandler={creditCardHandler}
            isLoading={isLoading}
            type={type}
            disposition={disposition}
          />
        </Elements>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <LockIcon sx={{marginRight: '0.5rem', color: 'lightgrey'}}/>
          <Typography variant="body" component="p" className={classes.body}>Secured by Stripe</Typography>
        </Box>
      </Box>
    );
  }else{
    return <Typography variant="body" component="p" className={classes.body}>Stripe account ID not found</Typography>
  }
}

export default Payment