/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable quote-props */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/sort-comp */
import { Component, createRef } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import Dinero from 'dinero.js';
import { isEmail } from 'validator';
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Hidden
} from '@mui/material';
import {
  Brightness1 as CircleIcon,
} from '@mui/icons-material';
import { cx } from '@emotion/css'
import { withStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isMobile } from 'react-device-detect';

import {
  ScissorsIcon,
  PlusIcon,
  CreditIcon,
} from '../../../../shared_components/icons';
import CustomPopper from '../CustomPopper';
import WhiteButton from '../../../../shared_components/buttons/White';
import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import CloseButton from '../../../../shared_components/buttons/Close';
import ScissorsButton from '../../../../shared_components/buttons/Scissors';
import GiftButton from '../../../../shared_components/buttons/Gift';
import AddAppointment from './AddAppointment';
import ApplyPayment from './ApplyPayment';
import RequestOnlinePayment from './RequestOnlinePayment';
import Discount from './Discount';
import Email from './Email';
import IssueGiftCard from './IssueGiftCard';
import IssuePackage from './IssuePackage';
import RedeemGiftCard from './RedeemGiftCard';
import RedeemCredit from './RedeemCredit';
import AppointmentLine from './AppointmentLine';
import DiscountLine from './DiscountLine';
import ProductLine from './ProductLine';
import GiftCardLine from './GiftCardLine';
import CreditLine from './CreditLine';
import ClientForm from './ClientForm';
import PackageLine from './PackageLine';
import GratuityLine from './GratuityLine';
import CreditIssuedLine from './CreditIssuedLine';
import RedeemPackage from './RedeemPackage';
import ConfirmDeletionPopup from '../ConfirmDeletionPopup';
import { loadSalesSettings } from '../../slices/salesSettingsSlice';
import {
  loadInvoiceWithItemsAndPayments,
  updateInvoiceWithItems,
  deleteInvoice,
  createInvoiceWithItemsAndClient,
} from '../../slices/invoicesSlice';

import {
  createClientForBooking,
} from '../../../../shared_slices/paymentSlice';
import {
  loadInvoiceEmails,
  sendInvoiceEmail,
} from '../../slices/invoiceEmailsSlice';
import { loadDiscounts } from '../../slices/discountsSlice';
import { loadLocations } from '../../../../shared_slices/locationsSlice';
import { loadStaff } from '../../../../shared_slices/staffSlice';
import {
  prepareOptions,
  setInitialStatus,
  setNewStatus,
  setDeletedStatus,
  makeOptionWithField,
  makeOption,
} from '../../../../shared_client_utils/formUtils';
import {
  InvoicesApi,
  SearchApi,
  PackagesApi,
  ClientsApi,
  ProductsApi,
  SettingsApi,
} from '../../../../client_http_api';
import {
  calculatePricesAndTax,
  calculateCreditBalance,
  formatInvoiceDatetime,
  formatAmount,
  calculateMaxOrder,
} from '../../../../shared_client_utils/invoiceUtils';
import SearchInput, { SearchItem, SearchCell } from '../SearchInput';
import ConfirmDialog from '../ConfirmDialog';
import {
  timezoneizeObject,
  getCurrentClientTime,
  formattedDate,
} from '../../../../shared_client_utils/dateUtils';
import { OrangeInfoStrip } from '../../../../shared_components/InfoStrip';
import Select from '../Select';
import invoiceItemTypes from '../../configs/invoiceItemTypes';
import {
  moneytizeObjects,
  formatMoney,
  isPositive,
} from '../../../../shared_client_utils/moneyUtils';
import DropdownMenu from './DropdownMenu';
import { MenuItem } from '../MobileMenu';
import CustomDialog from '../../../../shared_components/CustomDialog';
import DatePicker from '../../../../shared_components/DatePickerInput';
import { getFullName } from '../../utils/personUtils';
import OutstandingBalanceStrip from '../OutstandingBalanceStrip';
import { createClientCredit } from '../../slices/clientCreditsSlice';
import CreditOrGratuity from './CreditOrGratuity';
import PaymentsComponent from './PaymentsComponent';
import BottomButtons from './BottomButtons';
import MobileBottomButtons from './MobileBottomButtons';

const styles = theme => ({
  root: {
    fontFamily: 'Roboto',
    display: "flex",
    height: 'auto',
    padding: theme.spacing(2, 5, 7),
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      padding: '.5rem',
    },
  },
  topButton: {
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(),
    },
  },
  noChangeReasonBox: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  headerGrid: {
    display: 'grid',
    gridTemplateColumns: '106px 1fr',
    gap: '24px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '5rem 1fr',
      gap: '12px',
      alignItems: 'start',
    },
  },
  clientAndLocation: {
    flexDirection: 'column',
    width: '70%',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingRight: 0
    },
  },
  clientInfo: {
    color: '#4C4C4C',
  },
  invoiceToTitle: {
    color: '#808190',
    fontSize: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: '18px'
    },
  },
  searchClientBox: {
    flexDirection: 'column',
    flex: 2,
  },
  topMargin: {
    marginTop: theme.spacing(1 / 2),
  },
  clientInfoInner: {
    flexDirection: 'column',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#808190',
  },
  clientName: {
    fontSize: theme.spacing(7 / 2),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      fontSize: '20px'
    },
  },
  amountOwingStrip: {
    alignItems: 'center',
    width: 'auto',
  },
  locationInfo: {
    marginTop: theme.spacing(),
  },
  locationTitle: {
    color: '#808190',
    justifyContent: 'flex-end',
    fontSize: theme.spacing(9 / 4),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  locationName: {
    fontSize: theme.spacing(9 / 4),
  },
  invoiceMetaInfo: {
    flexDirection: 'column',
    border: '1px solid #EFEFEF',
    borderRadius: '6px',
    padding: '15px',
    overflow: 'hidden',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      border: 'none',
      padding: '1rem 0 0 0',
    },
  },
  invoiceTitle: {
    color: '#808190',
    fontSize: '19px',
    lineHeight: '23px',
  },
  invoiceMetaInfoContent: {
    marginTop: theme.spacing(2),
  },
  invoiceSubMetaInfo: {
    flexDirection: 'column',
    width: 'auto',
    fontSize: '12px',
    lineHeight: '14px',
    marginRight: theme.spacing(3),

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  invoiceSubMetaInfoTitle: {
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  datepickerInput: {
    maxWidth: theme.spacing(15),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: '.5rem',
    },
  },
  headerCell: {
    color: '#808190',
    lineHeight: '20px',
    textAlign: 'right',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1 / 2),
    borderBottom: `1px solid ${grey[300]}`,
    [theme.breakpoints.down('md')]: {
      paddingRight: '5px',
      textAlign: 'center',
    },
  },
  quantityCell: {
    width: theme.spacing(41 / 4),
  },
  unitPriceCell: {
    width: theme.spacing(13),
    [theme.breakpoints.down('md')]: {
      minWidth: '5rem',
    },
  },
  taxCell: {
    width: theme.spacing(8),
  },
  amountCell: {
    width: theme.spacing(15),
    [theme.breakpoints.down('md')]: {
      minWidth: '5.6rem',
    },
  },
  bodyRow: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      lineHeight: '14px',
    },
  },
  bodyCell: {
    textAlign: 'right',
    padding: theme.spacing(12 / 8, 2, 12 / 8, 0),
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      paddingRight: '5px',
      textAlign: 'center',
    },
  },
  totalsCell: {
    textAlign: 'right',
    padding: theme.spacing(1, 2, 1, 0),
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  totalsDivider: {
    borderBottom: '2px solid #EFEFEF',
  },
  descriptionCell: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      minWidth: '14rem',
    },
  },
  actionsCell: {
    textAlign: 'left',
    width: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingRight: '5px',
      textAlign: 'center',
    },
  },
  actionsBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    '& > div': {
      marginRight: theme.spacing(1 / 2),

      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionMenuItem: {
    alignItems: 'center',
  },
  visibleIconBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    cursor: 'pointer',
  },
  actionItemIconBox: {
    marginRight: theme.spacing(),
  },
  icon: {
    height: '20px',
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  totalsRow: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      lineHeight: '14px',
    },
  },
  firstTotalsRow: {
    '& > td': {
      borderTop: `1px solid ${grey[300]}`,
      paddingTop: theme.spacing(2),
    },
  },
  totalPriceBox: {
    fontWeight: 'bold',

    '& > td:first-of-type': {
      borderBottom: 'none',
    },
  },
  itemsButtons: {
    gridColumn: '1 / 4',
    gridRow: '1 / 3',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  button: {
    textTransform: "none",
    margin: 0,
    width: 'auto',
    minWidth: '115px',
    paddingLeft: '13px',
    paddingRight: '13px',
    height: theme.spacing(33 / 8),
    flex: 1,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.24)",
    borderRadius: theme.spacing(),
    fontSize: "16px",
    lineHeight: "17px",

    [theme.breakpoints.down('lg')]: {
      minWidth: '90px',
    },
  },
  whiteGreenButton: {
    color: '#2FD475',
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: '#ddf8e8',
    },
  },
  redWhiteButton: {
    backgroundColor: '#FB4841',

    '&:hover': {
      backgroundColor: '#9b3939',
    },
  },
  addItemButton: {
    marginLeft: '18px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  footer: {
    marginTop: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: '1rem',
    },
  },
  footerBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  footerTitle: {
    color: '#808190',
    fontSize: '18px',
    lineHeight: '21px',
    borderBottom: '1px solid #EFEFEF',
    paddingBottom: '10px',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  footerContent: {
    marginLeft: '7px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  footerContentLine: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '21px',
  },
  footerContentFirstColumn: {
    marginLeft: '6px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  oldInvoiceBtn: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      width: '100%',
      paddingTop: '1rem',
      justifyContent: 'space-between',
    },
  },
  notSentYetEmail: {
    opacity: '0.3',
  },
  circleIconBox: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  circleIconMailBox: {
    alignItems: 'center',
  },
  circleIcon: {
    width: '6px',
    height: '6px',
  },
  circleIconMail: {
    width: '6px',
    height: '6px',
    margin: '0 5px 1.5px 0'
  },
  footerContentMailType: {
    marginRight: '5px'
  },
  paymentTrashIconBox: {
    marginBottom: '2px',
    paddingTop: '1px',
  },
  trashButton: {
    height: theme.spacing(2),
  },
  iconWithTitleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emailIconBox: {
    height: '18px',
    marginRight: '5px',
  },
  plusIconBox: {
    height: '16px',
    marginRight: '8px',
  },
  plusIcon: {
    height: '16px',
    width: '16px',
  },
  addAppointmentPaper: {
    maxWidth: '900px',
    width: '60%',
  },
  backdrop: {
    backgroundColor: 'rgb(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.drawer + 1,
  },
  fullWidthSelect: {
    width: '24rem',
    [theme.breakpoints.down('md')]: {
      width: '14rem',
    },
  },
  tableWrapper: {
    overflow: 'overlay',
  },
  middleWidthInput: {
    width: '7rem',
  },
  cancelButtonFromConfirmDialog: {
    marginLeft: theme.spacing(10),
  },
  saveButtonFromConfirmDialog: {
    marginRight: theme.spacing(2),
  },
  redButton: {
    minWidth: '100px',
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  }
});

const getSessionError = (err = {}, flashMessage) => {
  if (err?.message?.includes('Session is expired')) {
    return flashMessage('Session is expired, refresh the page please', 'error')
  }
}

const getDate = (datetime, timezone) => moment.tz(datetime, timezone).format('ll');
const getTime = (datetime, timezone) => moment.tz(datetime, timezone).format('LT');

const initializeInvoice = (invoice) => {
  const {
    currency,
    totalTax,
    totalPrice,
    lessAmountPaid,
    amountDue,
    gratuity,
    creditAmount,
  } = invoice;

  return {
    ...invoice,
    totalTax: Dinero({ currency, amount: totalTax }),
    totalPrice: Dinero({ currency, amount: totalPrice }),
    lessAmountPaid: Dinero({ currency, amount: lessAmountPaid }),
    amountDue: Dinero({ currency, amount: amountDue }),
    gratuity: Dinero({ currency, amount: gratuity || 0 }),
    creditAmount: Dinero({ currency, amount: creditAmount || 0 }),
  };
}

const initializeInvoiceItem = (item) => {
  const price = Dinero({
    amount: item.price,
    currency: item.currency,
  });
  const tax = Dinero({
    amount: item.tax,
    currency: item.currency,
  });
  const totalPrice = Dinero({
    amount: item.totalPrice,
    currency: item.currency,
  });

  const formattedPrice = formatAmount(formatMoney(price), item.isNegativePrice);
  const formattedTotalPrice = formatAmount(
    formatMoney(totalPrice),
    item.isNegativePrice,
  );

  return {
    ...item,
    price,
    formattedPrice,
    totalPrice,
    formattedTotalPrice,
    tax,
  };
};

const discountAppointmentItemMaps = {
  [invoiceItemTypes.discount.type]: {
    mark: ({ appliedDiscountCount = 0, ...item }) => ({
      ...item,
      isDiscountApplied: true,
      appliedDiscountCount: appliedDiscountCount + 1,
    }),
    unmark: ({ isDiscountApplied, ...item }) => {
      let appliedOptions = {};
      const appliedDiscountCount = item.appliedDiscountCount - 1;
      if (appliedDiscountCount > 0) {
        appliedOptions = { isDiscountApplied, appliedDiscountCount };
      }

      return {
        ...item,
        ...appliedOptions,
      };
    },
  },
  [invoiceItemTypes.packageRedeem.type]: {
    mark: (item) => ({
      ...item,
      isPackageApplied: true,
    }),
    unmark: ({ isPackageApplied, ...item }) => item,
  },
};
const markAppointmentItemDiscounted = (items, { sourceItemId, type }) => {
  const dispatchers = discountAppointmentItemMaps[type];
  if (!dispatchers) {
    return items;
  }

  const sourceItem = items.find(({ id }) => id === sourceItemId);
  const updatedAppointmentItem = dispatchers.mark(sourceItem);

  const index = items.indexOf(sourceItem);
  const updatedItems = [
    ...items.slice(0, index),
    updatedAppointmentItem,
    ...items.slice(index + 1),
  ];

  return updatedItems;
};
const markAppointmentItemsDiscounted = (items) => (item) => {
  if (item.type !== invoiceItemTypes.appointment.type) {
    return item;
  }

  const childItems = items.filter((childItem) => {
    return item.id === childItem.sourceItemId;
  });
  if (_.isEmpty(childItems)) {
    return item;
  }

  let updatedAppointmentItem = { ...item };
  childItems.forEach(({ type }) => {
    const dispatchers = discountAppointmentItemMaps[type];
    updatedAppointmentItem = dispatchers.mark(updatedAppointmentItem);
  });

  return updatedAppointmentItem;
};
const unmarkAppointmentItemDiscounted = (items, { sourceItemId, type }) => {
  const dispatchers = discountAppointmentItemMaps[type];
  if (!dispatchers) {
    return items;
  }

  const sourceItem = items.find(({ id }) => id === sourceItemId);
  const updatedAppointmentItem = dispatchers.unmark(sourceItem);

  const index = items.indexOf(sourceItem);
  return [
    ...items.slice(0, index),
    updatedAppointmentItem,
    ...items.slice(index + 1),
  ];
};

const setNewAppointmentItemAttrsByPackage = (items, item) => {
  const { sourceItemId, price, totalPrice, tax, taxName, taxRate } = item;
  const appointmentItem = items.find(({ id }) => id === sourceItemId);
  const updatedAppointmentItem = initializeInvoiceItem({
    ...appointmentItem,
    taxName,
    taxRate,
    price: price.getAmount(),
    totalPrice: totalPrice.getAmount(),
    tax: tax.getAmount(),
  });

  const index = items.indexOf(appointmentItem);
  return [
    ...items.slice(0, index),
    updatedAppointmentItem,
    ...items.slice(index + 1),
  ];
};

const recalculateItemTotal = (item) => {
  const { price, tax, totalPrice } = calculatePricesAndTax({
    quantity: item.quantity,
    currency: item.currency,
    taxRate: item.taxRate,
    isTaxIncluded: item.isTaxIncluded,
    price: item.originalPrice,
  });

  return {
    ...item,
    price,
    tax,
    totalPrice,
  };
}

const resetAppointmentItemAttrsByPackage = (items, { sourceItemId }) => {
  const appointmentItem = items.find(({ id }) => id === sourceItemId);
  const updatedAppointmentItem = {
    ...appointmentItem,
    taxRate: appointmentItem.originalTaxRate,
    taxName: appointmentItem.originalTaxName,
  };
  const initializedAppointmentItem = initializeInvoiceItem(
    recalculateItemTotal(updatedAppointmentItem)
  );

  const index = items.indexOf(appointmentItem);
  return [
    ...items.slice(0, index),
    initializedAppointmentItem,
    ...items.slice(index + 1),
  ];
};

const increaseAvailableQtyForService = (clientPackages, clientPackageId) => {
  const clientPackageItem = clientPackages.find((pkg) => {
    return pkg.id === clientPackageId;
  });

  const availableQty = clientPackageItem.availableQty + 1;
  const updatedItem = {
    ...clientPackageItem,
    availableQty,
  };

  const index = clientPackages.indexOf(clientPackageItem);
  return [
    ...clientPackages.slice(0, index),
    updatedItem,
    ...clientPackages.slice(index + 1),
  ];
};
const decreaseAvailableQtyForService = (clientPackages, clientPackageId) => {
  const clientPackageItem = clientPackages.find((pkg) => {
    return pkg.id === clientPackageId;
  });

  const availableQty = clientPackageItem.availableQty - 1;
  const updatedItem = {
    ...clientPackageItem,
    availableQty,
  };

  const index = clientPackages.indexOf(clientPackageItem);
  return [
    ...clientPackages.slice(0, index),
    updatedItem,
    ...clientPackages.slice(index + 1),
  ];
};

const initializePayment = (businessTimezone) => (payment) => {
  const amount = Dinero({
    amount: payment.amount,
    currency: payment.currency,
  });
  const formattedAmount = formatAmount(formatMoney(amount));

  const newPayment = {
    ...payment,
    amount,
    formattedAmount,
  };

  return timezoneizeObject(newPayment, businessTimezone, 'datetime');
};

const addDiscountItemAndReorderItems = (items, discountItem) => {
  const sourceItem = items.find(({ id }) => id === discountItem.sourceItemId);
  const [discountSourceItems, restItems] = _.partition(items, ({ sourceItemId }) => {
    return sourceItemId
    && sourceItemId.length > 0
    && sourceItemId === sourceItem.id;
  });

  let newDiscountOrder;
  if (_.isEmpty(discountSourceItems)) {
    newDiscountOrder = sourceItem.order + 1;
  } else {
    const orders = discountSourceItems.map(item => item.order);
    const maxOrder = Math.max.apply(null, orders);
    newDiscountOrder = maxOrder + 1;
  }

  const unchangedItems = restItems.filter(({ order }) => order <= sourceItem.order);
  const updatedDiscountItem = {
    ...discountItem,
    order: newDiscountOrder,
  };
  const updatedItems = restItems
    .filter(({ order }) => order >= newDiscountOrder)
    .map(item => ({ ...item, order: item.order + 1 }));

  return [
    ...unchangedItems,
    ...discountSourceItems,
    updatedDiscountItem,
    ...updatedItems,
  ];
};

const addClientPackageItemAndReorderItems = (items, packageItem) => {
  const sourceItem = items.find(({ id }) => id === packageItem.sourceItemId);
  const newPackageOrder = sourceItem.order + 1;
  const updatedPackageItem = {
    ...packageItem,
    order: newPackageOrder,
  };

  const unchangedItems = items.filter(({ order }) => order <= sourceItem.order);
  const updatedItems = items
    .filter(({ order }) => order >= newPackageOrder)
    .map(item => ({ ...item, order: item.order + 1 }));

  return [
    ...unchangedItems,
    updatedPackageItem,
    ...updatedItems,
  ];
};

const updateOrInitializeCreditItemsInAllItems = (items, creditItems) => {
  let newCreditItems = [...creditItems];

  const newItems = items.map((item) => {
    const creditItem = creditItems.find(cItem => cItem.id === item.id);

    if (!creditItem) {
      return item;
    }

    newCreditItems = newCreditItems.filter(({ id }) => id !== creditItem.id);

    return initializeInvoiceItem(creditItem);
  });

  return newItems.concat(newCreditItems.map(initializeInvoiceItem).map(setNewStatus));
};

const addTotalDiscount = (items, discountItem, maxOrder) => {
  const item = {
    ...discountItem,
    order: maxOrder + 1,
  }

  return items.concat(item);
}

const prepareInvoiceData = (invoice, items, payments, client = {}) => {
  const notInitialItems = items.filter(({ status }) => status !== 'initial');
  const newItems = notInitialItems.map((options) => {
    const { price, tax, totalPrice, ...item } = options;

    return {
      ...item,
      price: price.getAmount(),
      tax: tax.getAmount(),
      totalPrice: totalPrice.getAmount(),
    };
  });

  const newPayments = payments.map(({ amount, ...payment }) => ({
    ...payment,
    amount: amount.getAmount(),
  }));

  return {
    client,
    invoice: {
      ...invoice,
      totalTax: invoice.totalTax.getAmount(),
      totalPrice: invoice.totalPrice.getAmount(),
      lessAmountPaid: invoice.lessAmountPaid.getAmount(),
      amountDue: invoice.amountDue.getAmount(),
      gratuity: invoice.gratuity ? invoice.gratuity.getAmount() : 0,
      creditAmount: invoice.creditAmount ? invoice.creditAmount.getAmount() : 0,
      items: newItems,
    },
    payments: newPayments,
  };
}

const noShowItems = [
  'gratuity',
  'creditIssued'
]

const prepareInvoiceItems = (items) => {
  const notDeletedItems = items.filter(({ status, type }) => status !== 'deleted' && !noShowItems.includes(type));
  return [...notDeletedItems].sort((a, b) => a.order - b.order);
}

const preparePayments = (payments) => {
  return payments.filter(({ status }) => status !== 'deleted');
}

const prepareEmails = (emails, invoiceId) => {
  return prepareOptions(emails)
    .filter(email => email.invoiceId === invoiceId);
}

const validateClient = ({ id, firstName, lastName }) => {
  if (id) return [true];

  if (!firstName || !lastName) {
    return [false, 'Enter client']
  }

  return [true];
}

const validateInvoice = ({ totalTax, amountDue, datetime }, items) => {
  const filteredItems = items.filter(({ status }) => {
    return status !== 'deleted' && status !== 'initial';
  });
  if (_.isEmpty(filteredItems)) {
    return [false, 'You need to add at least one item'];
  }

  if (amountDue.isNegative() || totalTax.isNegative()) {
    return [false, 'Totals should be greater than or equal to zero'];
  }

  const invalidItems = items.filter(({ quantity }) => !quantity);
  if (!_.isEmpty(invalidItems)) {
    return [false, 'All empty fields must be filled in'];
  }

  if (!datetime) {
    return [false, 'Invalid invoice date'];
  }

  return [true];
}

const itemsLineMap = {
  appointment: AppointmentLine,
  discount: DiscountLine,
  product: ProductLine,
  giftCardIssue: GiftCardLine,
  giftCardRedeem: GiftCardLine,
  creditRedeem: CreditLine,
  packageIssue: PackageLine,
  packageRedeem: PackageLine,
  gratuity: GratuityLine,
  creditIssued: CreditIssuedLine
};

const freshReorderItems = (items) => {
  const sortedItems = [...items].sort((a, b) => a.order - b.order);

  let order = 0;
  return sortedItems.map((item) => {
    if (item.status === 'deleted') {
      return item;
    }

    order += 1;
    return { ...item, order };
  });
};

const initializeClient = {
  id: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
  fullName: '',
  outstandingBalance: 0,
};

const clientsErrorMap = {
  firstName: {
    isValid: value => value && value.length > 0,
  },
  lastName: {
    isValid: value => value && value.length > 0,
  },
  email: {
    isValid: value => value && isEmail(value),
  },
  mobileNumber: {
    isValid: value => value && isValidPhoneNumber(value),
  },
};

const updateClientNameInItems = (items, clientName) => {
  return items.map((item) => {
    if (
      item.type === invoiceItemTypes.appointment.type
      || !['new', 'initial'].includes(item.status)
    ) {
      return item;
    }

    return { ...item, clientName };
  });
};

let printTimeoutid;

class Invoice extends Component {
  constructor(props) {
    super(props);

    const currency = 'USD';

    this.state = {
      isAddAppointmentDialogOpened: false,
      isItemDiscountWindowOpen: false,
      itemDiscountId: '',
      itemDiscountAnchor: null,
      isGiftWindowOpen: false,
      giftAnchor: null,
      isCreditWindowOpen: false,
      creditAnchor: null,
      isDeleteItemWindowOpen: false,
      deleteItemId: '',
      deleteItemAnchor: null,
      isDeletePaymentWindowOpen: false,
      deletePaymentId: '',
      deletePaymentAnchor: null,
      isApplyPaymentDialogOpened: false,
      isRequestOnlinePaymentDialogOpened: false,
      isEmailWindowOpen: false,
      emailAnchor: null,
      isInvoiceFullyPaid: false,
      isLoading: true,
      maxOrder: 0,
      invoice: {
        currency: '',
        formattedInvoiceNumber: '',
        totalTax: Dinero({ currency, amount: 0 }),
        totalPrice: Dinero({ currency, amount: 0 }),
        lessAmountPaid: Dinero({ currency, amount: 0 }),
        amountDue: Dinero({ currency, amount: 0 }),
        gratuity: Dinero({ currency, amount: 0 }),
        datetime: getCurrentClientTime(),
        creditAmount: Dinero({ currency, amount: 0 }),
        clientName: '',
        locationId: '',
        locationName: '',
        items: [],
        payments: [],
        isChangeable: true,
      },
      client: { ...initializeClient },
      negativeAmountDue: Dinero({ currency, amount: 0 }),
      isDeleteInvoiceWindowOpen: false,
      isAddGiftCardDialogOpened: false,
      isAddPackageDialogOpened: false,
      isConfirmCreditOrGratuityDialogOpened: false,
      isUnsavedChanges: false,
      doCreditsExist: false,
      isConfirmCloseDialogOpened: false,
      clientPackages: [],
      isPackageItemWindowOpened: false,
      appointmentItem: null,
      packageItemAnchor: null,
      isInvoiceNew: true,
      saveOldPayments: [],
      products: [],
      salesSettings: {
        ...props.salesSettings,
      },
    };

    this.handleOpenAddAppointment = this.handleOpenAddAppointment.bind(this);
    this.handleCloseAddAppointment = this.handleCloseAddAppointment.bind(this);
    this.handleAddAppointments = this.handleAddAppointments.bind(this);
    this.handleOpenApplyPayment = this.handleOpenApplyPayment.bind(this);
    this.handleCloseApplyPayment = this.handleCloseApplyPayment.bind(this);
    this.handleOpenRequestOnlinePayment = this.handleOpenRequestOnlinePayment.bind(this);
    this.handleCloseRequestOnlinePayment = this.handleCloseRequestOnlinePayment.bind(this);
    this.onClickOpenItemDiscountWindow = this.onClickOpenItemDiscountWindow.bind(this);
    this.onClickCloseItemDiscountWindow = this.onClickCloseItemDiscountWindow.bind(this);
    this.onClickOpenGiftWindow = this.onClickOpenGiftWindow.bind(this);
    this.onClickCloseGiftWindow = this.onClickCloseGiftWindow.bind(this);
    this.onClickOpenDeleteItemWindow = this.onClickOpenDeleteItemWindow.bind(this);
    this.onClickCloseDeleteItemWindow = this.onClickCloseDeleteItemWindow.bind(this);
    this.handleDeleteInvoiceItem = this.handleDeleteInvoiceItem.bind(this);
    this.onClickOpenEmailWindow = this.onClickOpenEmailWindow.bind(this);
    this.onClickCloseEmailWindow = this.onClickCloseEmailWindow.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.recalculateInvoiceTotals = this.recalculateInvoiceTotals.bind(this);
    this.handleCreateInvoice = this.handleCreateInvoice.bind(this);
    this.handleUpdateInvoice = this.handleUpdateInvoice.bind(this);
    this.handleAddPayment = this.handleAddPayment.bind(this);
    this.onClickOpenDeletePaymentWindow = this.onClickOpenDeletePaymentWindow.bind(this);
    this.onClickCloseDeletePaymentWindow = this.onClickCloseDeletePaymentWindow.bind(this);
    this.handleDeletePayment = this.handleDeletePayment.bind(this);
    this.handlePrintInvoice = this.handlePrintInvoice.bind(this);
    this.handleAddItemDiscount = this.handleAddItemDiscount.bind(this);
    this.handleAddTotalDiscount = this.handleAddTotalDiscount.bind(this);
    this.onClickOpenDeleteInvoiceWindow = this.onClickOpenDeleteInvoiceWindow.bind(this);
    this.onClickCloseDeleteInvoiceWindow = this.onClickCloseDeleteInvoiceWindow.bind(this);
    this.handleCancelInvoice = this.handleCancelInvoice.bind(this);
    this.handleAddProductItemTemplate = this.handleAddProductItemTemplate.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleUnitPriceChange = this.handleUnitPriceChange.bind(this);
    this.handleSelectClient = this.handleSelectClient.bind(this);
    this.handleInitiateClient = this.handleInitiateClient.bind(this);
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.handleChangeInvoiceDate = this.handleChangeInvoiceDate.bind(this);
    this.handleOpenAddGiftCard = this.handleOpenAddGiftCard.bind(this);
    this.handleCloseAddGiftCard = this.handleCloseAddGiftCard.bind(this);
    this.handleIssueGiftCard = this.handleIssueGiftCard.bind(this);
    this.handleRedeemGiftCard = this.handleRedeemGiftCard.bind(this);
    this.handleOpenAddPackage = this.handleOpenAddPackage.bind(this);
    this.handleCloseAddPackage = this.handleCloseAddPackage.bind(this);
    this.handleIssuePackage = this.handleIssuePackage.bind(this);
    this.addUnsavedChanges = this.addUnsavedChanges.bind(this);
    this.saveUnsavedChanges = this.saveUnsavedChanges.bind(this);
    this.onClickOpenCreditWindow = this.onClickOpenCreditWindow.bind(this);
    this.onClickCloseCreditWindow = this.onClickCloseCreditWindow.bind(this);
    this.handleRedeemCredit = this.handleRedeemCredit.bind(this);
    this.handleOpenConfirmCloseDialog = this.handleOpenConfirmCloseDialog.bind(this);
    this.handleCloseConfirmCloseDialog = this.handleCloseConfirmCloseDialog.bind(this);
    this.handleSaveInvoiceWhileClosingWindow = this.handleSaveInvoiceWhileClosingWindow.bind(this);
    this.handleCloseInvoiceWithoutSaving = this.handleCloseInvoiceWithoutSaving.bind(this);
    this.onClickOpenItemPackageWindow = this.onClickOpenItemPackageWindow.bind(this);
    this.onClickCloseItemPackageWindow = this.onClickCloseItemPackageWindow.bind(this);
    this.handleRedeemPackage = this.handleRedeemPackage.bind(this);
    this.handleCloseConfirmCreditOrGratuityDialogOpened = this.handleCloseConfirmCreditOrGratuityDialogOpened.bind(this);
    this.handleAddClientCredit = this.handleAddClientCredit.bind(this);
    this.deferredRequestLoadInvoiceEmails = this.deferredRequestLoadInvoiceEmails.bind(this);
    this.loadEmails = this.loadEmails.bind(this);

    this.actionMenuRef = createRef(null);
  }

  async componentDidMount() {
    const {
      currentInvoiceId,
      auth,
      isInvoiceNew,
      isIssuePackage,
      business: { timezone, currency },
      loadInvoiceWithItemsAndPayments,
      handleDisplayFlashMessage,
      handleCloseInvoiceDialog,
      loadInvoiceEmails
    } = this.props;

    if (currentInvoiceId) {
      let loadedInvoice = {};
      try {
        const { payload } = await loadInvoiceWithItemsAndPayments(
          currentInvoiceId,
        );
        loadedInvoice = payload;
      } catch (error) {
        if (error.name === 'InvoiceNotFoundError') {
          handleDisplayFlashMessage(error.message, 'error');
          this.setState({
            isLoading: false,
          }, handleCloseInvoiceDialog);
          return;
        }
        getSessionError(error, handleDisplayFlashMessage)
        console.log('loadInvoiceWithItems_ERROR', error);
        this.setState({isLoading: false});
      }

      const outstandingBalance = await ClientsApi.fetchOutstandingBalance(
        loadedInvoice.clientId,
        auth,
      );
      const outstandingBalanceMoney = Dinero(outstandingBalance);

      const { items, payments, client, ...invoice } = loadedInvoice;
      const newItems = items
        .map(initializeInvoiceItem)
        .map(markAppointmentItemsDiscounted(items));
      const newPayments = payments.map(initializePayment(timezone));

      const creditBalance = await calculateCreditBalance(
        invoice.clientId,
        invoice.currency,
        newItems,
        auth,
      );
      loadInvoiceEmails(currentInvoiceId);
      const newInvoice = timezoneizeObject(
        initializeInvoice(invoice),
        timezone,
        'datetime',
      );

      const clientPackages = await PackagesApi.fetchPaidClientPackages(
        invoice.clientId,
        auth,
      );
      const monetizedClientPackages = moneytizeObjects(
        clientPackages,
        { currency: invoice.currency },
        'newServicePrice',
      );
      const calendarSettings = await SettingsApi.fetchCalendarSettings(auth);
      this.setState({
        client: {
          ...client,
          outstandingBalance,
          outstandingBalanceMoney,
        },
        clientPackages: monetizedClientPackages,
        isLoading: false,
        invoice: {
          ...newInvoice,
          items: newItems,
          payments: newPayments,
        },
        doCreditsExist: creditBalance > 0,
        calendarSettings
      }, () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
      });
    } else if (isIssuePackage) {
      const { client: originalClient } = this.props;
      const { invoice: originalInvoice } = this.state;
      const client = originalClient;

      const newInvoice = timezoneizeObject(
        originalInvoice,
        timezone,
        'datetime',
      );

      this.setState({
        client,
        invoice: {
          ...newInvoice,
          currency,
          clientName: client.fullName,
        },
        isLoading: false,
        isAddPackageDialogOpened: true,
      });
    } else if (isInvoiceNew) {
      const { invoice: originalInvoice } = this.state;
      const newInvoice = timezoneizeObject(
        originalInvoice,
        timezone,
        'datetime',
      );

      this.setState({
        invoice: {
          ...newInvoice,
          currency,
        },
        isLoading: false,
      }, this.handleAddProductItemTemplate);
    }

    this.setState({ isInvoiceNew });
    const { loadSalesSettings, loadDiscounts, loadLocations, loadStaff } = this.props;

    loadSalesSettings();
    loadDiscounts();
    loadLocations();
    loadStaff();
  }

  componentWillUnmount() {
    if (printTimeoutid) clearTimeout(printTimeoutid);
  }

  loadEmails() {
    const { loadInvoiceEmails, currentInvoiceId, handleDisplayFlashMessage } = this.props;
    try {
      loadInvoiceEmails(currentInvoiceId)
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
    }
  }

  deferredRequestLoadInvoiceEmails(time = 3000) {
    setTimeout(this.loadEmails, time)
  }

  handleOpenAddAppointment() {
    this.setState({
      isAddAppointmentDialogOpened: true,
    });
  }

  handleCloseAddAppointment() {
    this.setState({
      isAddAppointmentDialogOpened: false,
    });
  }

  handleAddAppointments = appointmentIds => async () => {
    this.setState({ isAddAppointmentDialogOpened: false, isLoading: true });
    const { maxOrder, invoice: { currency }} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;

    try {
      const itemData = JSON.stringify({
        maxOrder,
        currency,
        appointmentIds,
      });
      const appointmentItems = await InvoicesApi.fetchItemFromAppointments(
        itemData, auth,
      );
      const newItems = appointmentItems
        .map(initializeInvoiceItem)
        .map(setNewStatus)

      this.setState(({ invoice }) => ({
        invoice: {
          ...invoice,
          items: [
            ...invoice.items,
            ...newItems,
          ],
        },
        isLoading: false,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log('handleAddAppointments_ERROR', error)
      this.setState({isLoading: false});
    }
  }

  handleOpenApplyPayment() {
    this.setState({
      isApplyPaymentDialogOpened: true,
    });
  }

  handleCloseApplyPayment() {
    this.setState({
      isApplyPaymentDialogOpened: false,
    });
  }

  handleOpenRequestOnlinePayment() {
    this.setState({
      isRequestOnlinePaymentDialogOpened: true,
    });
  }

  handleCloseRequestOnlinePayment() {
    this.setState({
      isRequestOnlinePaymentDialogOpened: false,
    });
  }

  onClickOpenItemDiscountWindow(id, itemDiscountAnchor) {
    this.setState({
      itemDiscountAnchor,
      isItemDiscountWindowOpen: true,
      itemDiscountId: id,
    });
  }

  onClickCloseItemDiscountWindow() {
    this.setState({
      isItemDiscountWindowOpen: false,
      itemDiscountId: '',
      itemDiscountAnchor: null,
    });
  }

  async handleAddItemDiscount(discountId, discountValue) {
    const {
      itemDiscountId,
      invoice: { items },
    } = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;

    this.setState({
      isLoading: true,
      isItemDiscountWindowOpen: false,
      itemDiscountId: '',
      itemDiscountAnchor: null,
    });

    const item = items.find(({ id }) => id === itemDiscountId);
    const data = {
      discountId,
      discountValue,
      sourceItemId: item.id,
      totalPrice: item.totalPrice.getAmount(),
      currency: item.currency,
      staffId: item.staffId,
      staffName: item.staffName,
      clientName: item.clientName,
    };

    const appointmentItem = items?.find(item => item.type === 'appointment')
    if (appointmentItem) {
      data.staffId = appointmentItem.StaffId || appointmentItem.staffId
      data.staffName = appointmentItem.staffName
    }
    const itemData = JSON.stringify(data);

    try {
      const discountItem = await InvoicesApi.fetchItemFromDiscount(
        itemData,
        auth,
      );
      const preparedItem = setNewStatus(initializeInvoiceItem(discountItem));
      let newItems = addDiscountItemAndReorderItems(items, preparedItem);
      newItems = markAppointmentItemDiscounted(newItems, preparedItem);

      this.setState(({ invoice }) => ({
        isLoading: false,
        invoice: {
          ...invoice,
          items: newItems,
        },
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log('handleAddItemDiscount_ERROR', error)
      this.setState({isLoading: false});
    }
  }

  async handleAddTotalDiscount(discountId, discountValue) {
    const {
      invoice: {
        items,
        totalPrice,
        currency,
        clientName,
      },
      maxOrder,
    } = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id, firstName, lastName } = auth.currentStaff;

    this.setState({
      isLoading: true,
      isItemDiscountWindowOpen: false,
      itemDiscountId: '',
      itemDiscountAnchor: null,
    });

    const data = {
      discountId,
      discountValue,
      currency,
      clientName,
      totalPrice: totalPrice.getAmount(),
      staffId: id,
      staffName: getFullName(firstName, lastName),
      isTotalDiscount: true,
    };

    const appointmentItem = items?.find(item => item.type === 'appointment')
    if (appointmentItem) {
      data.staffId = appointmentItem.StaffId || appointmentItem.staffId
      data.staffName = appointmentItem.staffName
    }
    const itemData = JSON.stringify(data);

    try {
      const discountItem = await InvoicesApi.fetchItemFromDiscount(
        itemData,
        auth,
      );
      const preparedItem = setNewStatus(initializeInvoiceItem(discountItem));
      const newItems = addTotalDiscount(items, preparedItem, maxOrder);

      this.setState(({ invoice }) => ({
        isLoading: false,
        invoice: {
          ...invoice,
          items: newItems,
        },
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log('handleAddTotalDiscount_ERROR', error)
      this.setState({isLoading: false});
    }
  }

  onClickOpenGiftWindow(event) {
    this.setState({
      isGiftWindowOpen: true,
      giftAnchor: event.target,
    });
  }

  onClickCloseGiftWindow() {
    this.setState({
      isGiftWindowOpen: false,
      giftAnchor: null,
    });
  }

  onClickOpenCreditWindow(creditAnchor) {
    this.setState({
      creditAnchor,
      isCreditWindowOpen: true,
    });
  }

  onClickCloseCreditWindow() {
    this.setState({
      isCreditWindowOpen: false,
      creditAnchor: null,
    });
  }

  async handleSelectClient(result, setSearchValue) {
    const { invoice: { items, currency }} = this.state;
    const { auth } = this.props;

    const creditBalance = await calculateCreditBalance(
      result.id,
      currency,
      items,
      auth,
    );

    const clientName = result.fullName;
    const updatedItems = updateClientNameInItems(items, clientName);

    const outstandingBalance = await ClientsApi.fetchOutstandingBalance(
      result.id,
      auth,
    );
    const outstandingBalanceMoney = Dinero(outstandingBalance);

    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        clientName,
        items: updatedItems,
      },
      client: {
        ...result,
        outstandingBalance,
        outstandingBalanceMoney,
      },
      doCreditsExist: creditBalance > 0,
    }));

    setSearchValue(clientName);
  }

  handleInitiateClient(parsedFullName) {
    this.setState({
      client: {
        ...initializeClient,
        ...parsedFullName,
      },
      doCreditsExist: false,
    });
  }

  handleAddClient = ({ onCloseForm, setSearchValue }) => async (client) => {
    const { createClient, salesSettings: { BusinessId } } = this.props;
    this.setState({ isLoading: true });
    const { payload } = await createClient({...client, businessId: BusinessId})
    this.setState({ isLoading: false});
    this.setState(({ client }) => ({
      client: {
        ...client,
        id: payload?.id,
        firstName: payload?.firstName,
        lastName: payload?.lastName,
        mobileNumber: payload?.mobileNumber,
        email: payload?.email,
        fullName: `${payload?.firstName} ${payload?.lastName}`,
        status: 'new'
      },
    }))

    const { invoice: { items } } = this.state;

    const clientName = client.fullName;
    const updatedItems = updateClientNameInItems(items, clientName);

    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        clientName,
        items: updatedItems,
      },
    }), () => {
      onCloseForm();
      setSearchValue(clientName);
    });
  }

  handleChangeLocation({ label, value }) {
    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        locationId: value,
        locationName: label,
      },
    }));
  }

  handleChangeInvoiceDate(value) {
    const { business: { timezone } } = this.props;
    const { invoice: originalInvoice } = this.state;

    const date = moment(value);
    const datetime = date.isValid() ? date.toISOString() : '';

    let invoice = { ...originalInvoice, datetime };
    invoice = timezoneizeObject(invoice, timezone, 'datetime');

    this.setState({ invoice }, this.addUnsavedChanges);
  }

  onClickOpenDeleteItemWindow = id => (event) => {
    this.setState({
      isDeleteItemWindowOpen: true,
      deleteItemId: id,
      deleteItemAnchor: event.currentTarget,
    });
  }

  onClickCloseDeleteItemWindow() {
    this.setState({
      isDeleteItemWindowOpen: false,
      deleteItemId: '',
      deleteItemAnchor: null,
    });
  }

  async handleAddProductItemTemplate() {
    this.setState({ isLoading: true });

    const { maxOrder, invoice: { currency, clientName }} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id, firstName, lastName } = auth.currentStaff;

    try {
      const productsPayload = await ProductsApi.fetchProductsWithCategory(auth);
      const products = productsPayload.map(makeOption);

      const itemData = JSON.stringify({
        currency,
        clientName,
        order: maxOrder + 1,
        staffId: id,
        staffName: getFullName(firstName, lastName),
      });
      const productItem = await InvoicesApi.fetchProductItemTemplate(
        itemData, auth,
      );

      const newItem = setInitialStatus(initializeInvoiceItem(productItem));

      this.setState(({ invoice }) => ({
        products,
        invoice: {
          ...invoice,
          items: [
            ...invoice.items,
            newItem,
          ],
        },
        isLoading: false,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log('handleAddProductItemTemplate_ERROR', error)
      this.setState({isLoading: false});
    }
  }

  handleAddProduct = (id) => async (product) => {
    this.setState({ isLoading: true });

    const { invoice: { items }} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;

    const item = items.find(item => item.id === id);

    try {
      const data = {
        id,
        order: item.order,
        currency: item.currency,
        quantity: item.quantity,
        productId: product.id,
        staffId: item.staffId,
        staffName: item.staffName,
        clientName: item.clientName,
      };

      const appointmentItem = items?.find(item => item.type === 'appointment')
      if (appointmentItem) {
        data.staffId = appointmentItem.StaffId || appointmentItem.staffId
        data.staffName = appointmentItem.staffName
      }
      const itemData = JSON.stringify(data);

      const productItem = await InvoicesApi.fetchItemFromProduct(
        itemData,
        auth,
      );
      const newItem = setNewStatus(initializeInvoiceItem(productItem));

      const index = items.indexOf(item);
      const newItems = [
        ...items.slice(0, index),
        newItem,
        ...items.slice(index + 1),
      ];

      this.setState(({ invoice }) => ({
        invoice: {
          ...invoice,
          items: newItems,
        },
        isLoading: false,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log('handleAddProduct_ERROR', error)
      this.setState({isLoading: false});
    }
  }

  handleQuantityChange(itemId, value) {
    const { invoice: { items }} = this.state;
    const item = items.find(item => item.id === itemId);
    const updatedItem = { ...item, quantity: value };

    let newItem = updatedItem;
    if (value) {
      newItem = initializeInvoiceItem(recalculateItemTotal(updatedItem));
    }

    const index = items.indexOf(item);
    const newItems = [
      ...items.slice(0, index),
      newItem,
      ...items.slice(index + 1),
    ];

    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        items: newItems,
      },
    }), () => {
      this.recalculateInvoiceTotals();
      this.addUnsavedChanges();
    });
  }

  handleUnitPriceChange(itemId, value) {
    const { invoice: { items } } = this.state;
    const item = items.find(item => item.id === itemId);
    value === '.' ? value = '0.00' : value

    const integerValue = Math.round(Number(value) * 100)
    const updatedItem = {
      ...item,
      originalPrice: integerValue,
      price: Dinero({ currency: item.currency, amount: integerValue }),
      totalPrice: Dinero({ currency: item.currency, amount: integerValue }),
      formattedPrice: value,
      formattedTotalPrice: Dinero({ amount: integerValue}).toFormat().substring(1)
    };

    let newItem = updatedItem;
    if (value) {
      newItem = initializeInvoiceItem(recalculateItemTotal(updatedItem));
    }

    const newItems = items.map((i) => {
      if(i.id === itemId) {
        i = newItem
        i.userFriendlyValue = updatedItem.formattedPrice
      }
      return i
    })

    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        items: newItems,
      },
    }), () => {
      this.recalculateInvoiceTotals();
      this.addUnsavedChanges();
    });
  }

  onClickOpenDeletePaymentWindow = id => (event) => {
    this.setState({
      isDeletePaymentWindowOpen: true,
      deletePaymentId: id,
      deletePaymentAnchor: event.currentTarget,
    });
  }

  onClickCloseDeletePaymentWindow() {
    this.setState({
      isDeletePaymentWindowOpen: false,
      deletePaymentId: '',
      deletePaymentAnchor: null,
    });
  }

  onClickOpenDeleteInvoiceWindow() {
    this.setState({
      isDeleteInvoiceWindowOpen: true,
    });
  }

  onClickCloseDeleteInvoiceWindow() {
    this.setState({
      isDeleteInvoiceWindowOpen: false,
    });
  }

  async recalculateInvoiceTotals(isOnlinePayment) {
    const { invoice: { currency, items, payments }, isInvoiceNew } = this.state;

    const notDeletedItems = items.filter(({ status}) => status !== 'deleted');

    const totalTax = notDeletedItems.reduce((acc, item) => {
      return acc.add(item.tax);
    }, Dinero({ currency, amount: 0 }));

    const totalPrice = notDeletedItems.reduce((acc, item) => {
      if (item.isNegativePrice) {
        return acc.subtract(item.totalPrice);
      }
      return acc.add(item.totalPrice);
    }, Dinero({ currency, amount: 0, }));

    const preparedPayments = preparePayments(payments);
    const lessAmountPaid = preparedPayments.reduce((acc, { amount }) => {
      return acc.add(amount);
    }, Dinero({ currency, amount: 0 }));

    const amountDue = totalPrice.subtract(lessAmountPaid);

    if (amountDue.isNegative()) {
      this.setState({isConfirmCreditOrGratuityDialogOpened: true, negativeAmountDue: amountDue})
    }

    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        totalTax,
        totalPrice,
        lessAmountPaid,
        amountDue
      },
      isInvoiceFullyPaid: amountDue.isZero(),
    }), () => {
      if (isOnlinePayment) {
        if (isInvoiceNew) {
          this.handleCreateInvoice()
        } else {
          this.handleUpdateInvoice()
        }
      }
    });
  }

  updateMaxOrder() {
    const { invoice: { items }} = this.state;
    if (_.isEmpty(items)) {
      this.setState({ maxOrder: 0 });
      return;
    }

    const maxOrder = calculateMaxOrder(items);
    this.setState({ maxOrder });
  }

  addUnsavedChanges() {
    this.setState({ isUnsavedChanges: true });
  }

  saveUnsavedChanges() {
    this.setState({ isUnsavedChanges: false });
  }

  handleAddPayment(payment) {
    const { invoice: { payments: oldPayments }} = this.state;
    const { business: { timezone } } = this.props;

    const newPayment = setNewStatus(
      initializePayment(timezone)(payment),
    );
    const payments = oldPayments.concat(newPayment);

    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        payments,
      },
      saveOldPayments: oldPayments,
      isApplyPaymentDialogOpened: false,
    }), () => {
      const isOnlinePayment = payment?.paymentTypeName === 'Credit card';
      this.recalculateInvoiceTotals(isOnlinePayment);
      this.addUnsavedChanges();
    });
  }

  handleDeletePayment() {
    const {
      deletePaymentId, invoice: { payments: oldPayments, currency, items },
    } = this.state;

    const payment = oldPayments.find(({ id }) => id === deletePaymentId);

    let payments;
    if (payment.status === 'new') {
      payments = oldPayments.filter(({ id }) => id !== payment.id);
    } else {
      const deletedPayment = setDeletedStatus(payment);

      const index = oldPayments.indexOf(payment);
      payments = [
        ...oldPayments.slice(0, index),
        deletedPayment,
        ...oldPayments.slice(index + 1),
      ];
    }

    const gratuity = Dinero({currency, amount: 0})
    const creditAmount = Dinero({currency, amount: 0})
    const newItems = [...items].filter(({ type }) => type !== 'gratuity' && type !== 'creditIssued');

    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        items: newItems,
        gratuity,
        creditAmount,
        payments,
      },
      isDeletePaymentWindowOpen: false,
      deletePaymentId: '',
      deletePaymentAnchor: null,
    }), () => {
      this.recalculateInvoiceTotals();
      this.addUnsavedChanges();
    });
  }

  handlePrintInvoice() {
    const { invoice: { id, datetimeTz, Client: { firstName, lastName }}} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;

    // MacOs Safari doesn't open new window which was called inside async function
    const newWindow = window.open('', '', 'width=1000,height=1000');
    const title = `invoice-${firstName}-${lastName}-${moment(datetimeTz).format('MMM-DD-YYYY')}`;
    InvoicesApi.fetchEmailHtml(id, auth)
      .then((preparedHtml) => {
        newWindow.document.write(preparedHtml);
        newWindow.document.title = title;
        newWindow.document.close();
        newWindow.history.replaceState({}, title, '/');

        printTimeoutid = setTimeout(() => {
          newWindow.focus();
          newWindow.print();
        }, 500);
      })
      .catch(err => getSessionError(err, handleDisplayFlashMessage))
  }

  onClickOpenEmailWindow(event) {
    this.setState({
      isEmailWindowOpen: true,
      emailAnchor: event.currentTarget,
    });
  }

  onClickCloseEmailWindow() {
    this.setState({
      isEmailWindowOpen: false,
      emailAnchor: null,
    });
  }

  handleSendEmail = (email, message) => async () => {
    this.setState({
      isLoading: true,
      isEmailWindowOpen: false,
      emailAnchor: null,
    });

    const { invoice: { id, currency, totalPrice, amountDue, gratuity }} = this.state;
    const { sendInvoiceEmail, handleDisplayFlashMessage } = this.props;
    const preparedEmail = {
      email,
      message,
      currency,
      totalPrice: totalPrice.getAmount(),
      amountDue: amountDue.getAmount(),
      gratuity: gratuity?.getAmount() || 0,
      invoiceId: id,
    };

    try {
      await sendInvoiceEmail(preparedEmail);
      handleDisplayFlashMessage(`invoice sent to email "${email}"`);
      this.deferredRequestLoadInvoiceEmails()
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log('handleSendEmail_ERROR', error)
    }

    this.setState({ isLoading: false });
  }

  handleOpenAddGiftCard() {
    this.setState({
      isAddGiftCardDialogOpened: true,
    });
  }

  handleCloseAddGiftCard() {
    this.setState({
      isAddGiftCardDialogOpened: false,
    });
  }

  async handleIssueGiftCard(clientGiftCard) {
    this.setState({ isAddGiftCardDialogOpened: false, isLoading: true });
    console.log('handleIssueGiftCard', clientGiftCard)
    const { maxOrder, invoice: { currency, clientName, items }} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id, firstName, lastName } = auth.currentStaff;

    try {
      const data = {
        maxOrder,
        currency,
        clientGiftCard,
        clientName: clientGiftCard.recipient?.fullName || clientName,
        staffId: id,
        staffName: getFullName(firstName, lastName),
      };

      const appointmentItem = items?.find(item => item.type === 'appointment')
      if (appointmentItem) {
        data.staffId = appointmentItem.StaffId || appointmentItem.staffId
        data.staffName = appointmentItem.staffName
      }
      const itemData = JSON.stringify(data);

      const giftCardItem = await InvoicesApi.fetchItemFromGiftCard(
        itemData,
        auth,
      );
      const newItem = setNewStatus(initializeInvoiceItem(giftCardItem));

      this.setState(({ invoice }) => ({
        invoice: {
          ...invoice,
          items: [
            ...invoice.items,
            newItem,
          ],
        },
        isLoading: false,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log(error)
    }
  }

  async handleAddClientCredit() {
    const {
      maxOrder,
      negativeAmountDue,
      invoice: { ClientId, currency, clientName, formattedInvoiceNumber, items, id: invoiceId },
      client: { id }
    } = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id: currentStaffId, firstName, lastName } = auth.currentStaff;
    const initialValue = Math.abs(negativeAmountDue.getAmount())

    this.setState({ isLoading: true });
    try {
      let creditAmount = Dinero({currency, amount: initialValue})
      const amountDue = Dinero({currency, amount: 0})

      if (!items.some(({ type }) => type === 'creditIssued')) {
        const data = {
          currency,
          invoiceId,
          clientName,
          maxOrder,
          creditAmount,
          clientId: ClientId || id,
          staffId: currentStaffId,
          formattedInvoiceNumber,
          staffName: getFullName(firstName, lastName),
        };

        const appointmentItem = items?.find(item => item.type === 'appointment')
        if (appointmentItem) {
          data.staffId = appointmentItem.StaffId || appointmentItem.staffId
          data.staffName = appointmentItem.staffName
        }
        const itemData = JSON.stringify(data);

        const creditItem = await InvoicesApi.fetchItemFromClientCredit(
          itemData,
          auth,
        );
        const newItem = setNewStatus(initializeInvoiceItem(creditItem));
        this.setState(({ invoice }) => ({
          invoice: {
            ...invoice,
            amountDue,
            creditAmount,
            items: [...invoice.items, newItem]
          },
          isInvoiceFullyPaid: amountDue.isZero(),
        }));
      } else {
        creditAmount = Dinero({currency, amount: 0})
        const newItems = items.filter(({ type }) => type !== 'creditIssued')
        this.setState(({ invoice }) => ({
          invoice: {
            ...invoice,
            items: newItems,
            amountDue,
            creditAmount
          },
          isInvoiceFullyPaid: amountDue.isZero(),
        }));
      }
    } catch (error) {
      console.log('handleAddClientCredit_ERROR', error)
      if (error.name) {
        handleDisplayFlashMessage(error.message, 'error')
      }
      getSessionError(error, handleDisplayFlashMessage)
    }
    this.setState({ isLoading: false, isConfirmCreditOrGratuityDialogOpened: false })
  }

  handleAddGratuity = async () => {
    this.setState({ isLoading: true });

    const { maxOrder, invoice: { currency, clientName, id: invoiceId, clientId, items }, negativeAmountDue} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id: staffId, firstName, lastName } = auth.currentStaff;

    const amount = Math.abs(negativeAmountDue.getAmount())
    let gratuity = Dinero({currency, amount})
    const amountDue = Dinero({currency, amount: 0})
    try {
      if (!items.some(({ type }) => type === 'gratuity')) {
        const data = {
          currency,
          invoiceId,
          clientName,
          maxOrder,
          gratuity,
          clientId,
          staffId,
          staffName: getFullName(firstName, lastName),
        }

        const appointmentItem = items?.find(item => item.type === 'appointment')
        if (appointmentItem) {
          data.staffId = appointmentItem.StaffId || appointmentItem.staffId
          data.staffName = appointmentItem.staffName
        }
        const itemData = JSON.stringify(data);
        const gratuityItem = await InvoicesApi.fetchItemFromClientGratuity(
          itemData,
          auth,
        );
        const newItem = setNewStatus(initializeInvoiceItem(gratuityItem));

        this.setState(({ invoice }) => ({
          invoice: {
            ...invoice,
            amountDue,
            gratuity,
            items: [
              ...invoice.items,
              newItem,
            ],
          }
        }));
      } else {
        gratuity = Dinero({currency, amount: 0})
        const newItems = items.filter(({ type }) => type !== 'gratuity')
        this.setState(({ invoice }) => ({
          invoice: {
            ...invoice,
            items: newItems,
            amountDue,
            gratuity
          },
          isInvoiceFullyPaid: amountDue.isZero(),
        }));
      }
      this.setState({ isLoading: false, isConfirmCreditOrGratuityDialogOpened: false });
    } catch (error) {
      console.log('handleAddGratuity_ERROR', error)
      getSessionError(error, handleDisplayFlashMessage)
      this.setState({ isLoading: false });
    }
  }

  async handleRedeemGiftCard(code, currentClientTime, amount) {
    this.setState({ isGiftWindowOpen: false, isLoading: true });

    const { maxOrder, invoice: { currency, clientName, items }} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id, firstName, lastName } = auth.currentStaff;

    try {
      const data = {
        maxOrder,
        currency,
        code,
        currentClientTime,
        amount,
        clientName,
        staffId: id,
        staffName: getFullName(firstName, lastName),
      };

      const appointmentItem = items?.find(item => item.type === 'appointment')
      if (appointmentItem) {
        data.staffId = appointmentItem.StaffId || appointmentItem.staffId
        data.staffName = appointmentItem.staffName
      }
      const itemData = JSON.stringify(data);

      const giftCardItem = await InvoicesApi.fetchItemFromClientGiftCard(
        itemData,
        auth,
      );
      const newItem = setNewStatus(initializeInvoiceItem(giftCardItem));

      this.setState(({ invoice }) => ({
        invoice: {
          ...invoice,
          items: [
            ...invoice.items,
            newItem,
          ],
        },
        isLoading: false,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      this.setState({ isLoading: false });
      if (error.name === 'NotFoundClientGiftCardError') {
        handleDisplayFlashMessage(error.message, 'error');
        return;
      }
      getSessionError(error, handleDisplayFlashMessage)
      console.log('handleRedeemGiftCard_ERROR', error);
    }
  }

  async handleRedeemCredit(amount) {
    this.setState({ isCreditWindowOpen: false, isLoading: true });

    const {
      maxOrder,
      invoice: {
        currency,
        items,
        clientId,
        clientName,
      },
    } = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id, firstName, lastName } = auth.currentStaff;

    const addedCreditItems = items
      .filter(item => item.type === 'creditRedeem')
      .map(item => {
        return {
          ...item,
          price: item.price.getAmount(),
          tax: item.tax.getAmount(),
          totalPrice: item.totalPrice.getAmount(),
        };
      });

    try {
      const data = {
        maxOrder,
        currency,
        amount,
        addedCreditItems,
        clientId,
        clientName,
        staffId: id,
        staffName: getFullName(firstName, lastName),
      };

      const appointmentItem = items?.find(item => item.type === 'appointment')
      if (appointmentItem) {
        data.staffId = appointmentItem.StaffId || appointmentItem.staffId
        data.staffName = appointmentItem.staffName
      }
      const itemData = JSON.stringify(data);

      const creditItems = await InvoicesApi.fetchItemsFromClientCredits(
        itemData,
        auth,
      );

      const allItems = updateOrInitializeCreditItemsInAllItems(
        items,
        creditItems,
      );

      const creditBalance = await calculateCreditBalance(
        clientId,
        currency,
        allItems,
        auth,
      );

      this.setState(({ invoice }) => ({
        invoice: {
          ...invoice,
          items: allItems,
        },
        isLoading: false,
        doCreditsExist: creditBalance > 0,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      // if (error.name === 'NotFoundClientGiftCardError') {
      //   this.props.handleDisplayFlashMessage(error.message);
      //   return;
      // }
      getSessionError(error, handleDisplayFlashMessage)
      console.log(error);
    }
  }

  handleOpenAddPackage() {
    this.setState({
      isAddPackageDialogOpened: true,
    });
  }

  handleCloseAddPackage() {
    this.setState({
      isAddPackageDialogOpened: false,
    });
  }

  async handleIssuePackage(clientPackage) {
    this.setState({ isAddPackageDialogOpened: false, isLoading: true });

    const { maxOrder, invoice: { currency, items }} = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id, firstName, lastName } = auth.currentStaff;

    try {
      const data = {
        maxOrder,
        currency,
        clientPackage,
        clientName: clientPackage.client.fullName,
        staffId: id,
        staffName: getFullName(firstName, lastName),
      };

      const appointmentItem = items?.find(item => item.type === 'appointment')
      if (appointmentItem) {
        data.staffId = appointmentItem.StaffId || appointmentItem.staffId
        data.staffName = appointmentItem.staffName
      }
      const itemData = JSON.stringify(data);

      const packageItem = await InvoicesApi.fetchItemFromPackage(
        itemData,
        auth,
      );
      const newItem = setNewStatus(initializeInvoiceItem(packageItem));

      this.setState(({ invoice }) => ({
        invoice: {
          ...invoice,
          items: [
            ...invoice.items,
            newItem,
          ],
        },
        isLoading: false,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      getSessionError(error, handleDisplayFlashMessage)
      console.log(error)
    }
  }

  onClickOpenItemPackageWindow(item, packageItemAnchor) {
    this.setState({
      packageItemAnchor,
      isPackageItemWindowOpened: true,
      appointmentItem: item,
    });
  }

  onClickCloseItemPackageWindow() {
    this.setState({
      isPackageItemWindowOpened: false,
      appointmentItem: null,
      packageItemAnchor: null,
    });
  }

  async handleRedeemPackage(clientPackage) {
    this.setState({ isGiftWindowOpen: false, isLoading: true });

    const {
      maxOrder,
      appointmentItem,
      invoice: { currency, clientName, items },
      clientPackages: originalClientPackages
    } = this.state;
    const { auth, handleDisplayFlashMessage } = this.props;
    const { id, firstName, lastName } = auth.currentStaff;

    this.setState({
      isLoading: true,
      isPackageItemWindowOpened: false,
      appointmentItem: null,
      packageItemAnchor: null,
    });

    try {
      const data = {
        maxOrder,
        currency,
        clientName,
        clientPackage,
        sourceItemId: appointmentItem.id,
        staffId: id,
        staffName: getFullName(firstName, lastName),
      };

      const invoiceAppointmentItem = items?.find(item => item.type === 'appointment')
      if (invoiceAppointmentItem) {
        data.staffId = invoiceAppointmentItem.StaffId || invoiceAppointmentItem.staffId
        data.staffName = invoiceAppointmentItem.staffName
      }
      const itemData = JSON.stringify(data);

      const packageItem = await InvoicesApi.fetchItemFromClientPackage(
        itemData,
        auth,
      );
      const preparedItem = setNewStatus(initializeInvoiceItem(packageItem));
      let newItems = addClientPackageItemAndReorderItems(items, preparedItem);
      newItems = markAppointmentItemDiscounted(newItems, preparedItem);
      newItems = setNewAppointmentItemAttrsByPackage(newItems, preparedItem);

      const clientPackages = decreaseAvailableQtyForService(
        originalClientPackages,
        preparedItem.clientPackageId,
      );

      this.setState(({ invoice }) => ({
        clientPackages,
        invoice: {
          ...invoice,
          items: newItems,
        },
        isLoading: false,
      }), () => {
        this.updateMaxOrder();
        this.recalculateInvoiceTotals();
        this.addUnsavedChanges();
      });
    } catch (error) {
      if (error.name === 'NotFoundClientPackageError') {
        handleDisplayFlashMessage(error.message, 'error');
        return;
      }
      getSessionError(error, handleDisplayFlashMessage)
      console.log(error);
      this.setState({isLoading: false})
    }
  }

  handleOpenConfirmCloseDialog() {
    this.setState({
      isConfirmCloseDialogOpened: true,
    });
  }

  handleCloseConfirmCloseDialog() {
    this.setState({
      isConfirmCloseDialogOpened: false,
    });
  }

  handleCloseConfirmCreditOrGratuityDialogOpened() {
    const { saveOldPayments } = this.state;
    this.setState(({ invoice }) => ({
      invoice: {
        ...invoice,
        payments: saveOldPayments,
      },
      isConfirmCreditOrGratuityDialogOpened: false,
    }), () => {
      this.recalculateInvoiceTotals();
    });
  }

  handleCloseInvoiceWithoutSaving() {
    const { handleCloseInvoiceDialog } = this.props;
    this.handleCloseConfirmCloseDialog();
    handleCloseInvoiceDialog();
  }

  handleSaveInvoiceWhileClosingWindow() {
    const { isInvoiceNew } = this.state;
    this.handleCloseConfirmCloseDialog();
    if (isInvoiceNew) {
      this.handleCreateInvoice()
    } else {
      this.handleUpdateInvoice()
    }
  }

  async handleCreateInvoice() {
    const { invoice: { items, payments, ...invoice }, client } = this.state;
    const { auth,
      business: { timezone },
      handleDisplayFlashMessage,
      createInvoiceWithItemsAndClient,
      onExitCallback,
    } = this.props;

    const [isValidClient, clientMessage] = validateClient(client);
    if (!isValidClient) {
      handleDisplayFlashMessage(clientMessage, 'error');
      return;
    }

    if (!invoice.locationId) {
      handleDisplayFlashMessage('Enter location', 'error');
      return;
    }

    const [isValidInvoice, invoiceMessage] = validateInvoice(invoice, items);
    if (!isValidInvoice) {
      handleDisplayFlashMessage(invoiceMessage, 'error');
      return;
    }

    this.setState({ isLoading: true });

    const preparedData = prepareInvoiceData(invoice, items, payments, client);
    const currentClientTime = getCurrentClientTime();

    try {
      const options = {
        currentClientTime,
        currentStaffId: auth.currentStaff.id,
      };
      const { payload } = await createInvoiceWithItemsAndClient(
        preparedData,
        options,
      );

      const newInvoice = timezoneizeObject(
        initializeInvoice(payload),
        timezone,
        'datetime',
      );

      const outstandingBalance = await ClientsApi.fetchOutstandingBalance(
        newInvoice.clientId,
        auth,
      );
      const outstandingBalanceMoney = Dinero(outstandingBalance);

      this.setState(({ client }) => ({
        client: {
          ...client,
          outstandingBalance,
          outstandingBalanceMoney,
        },
        invoice: {
          ...newInvoice,
          items: payload.items.map(initializeInvoiceItem),
          payments: payload.payments.map(initializePayment(timezone)),
        },
        isInvoiceNew: false,
        isLoading: false,
      }), () => {
        this.saveUnsavedChanges();
        onExitCallback();
      });
      if (payments?.some(pay => pay.status === 'new') && payload?.id) {
        const newDue = newInvoice?.amountDue?.getAmount()
        if (newInvoice && (!newDue || newDue === 0)) {
          handleDisplayFlashMessage(`invoice sent to email "${payload?.Client?.email}"`);
        }
        this.deferredRequestLoadInvoiceEmails()
      }
    } catch (error) {
      console.log('handleCreateInvoice_ERROR', error)
      if (error.name) {
        handleDisplayFlashMessage(error.message, 'error');
      }
      getSessionError(error, handleDisplayFlashMessage)
      this.setState({ isLoading: false });
    }
  }

  async handleUpdateInvoice() {
    const { invoice: { items, payments, ...invoice }} = this.state;
    const { business: { timezone },
      auth: { currentStaff },
      currentInvoiceId,
      handleDisplayFlashMessage,
      updateInvoiceWithItems,
      onExitCallback,
    } = this.props;

    const [isValid, message] = validateInvoice(invoice, items);
    if (!isValid) {
      handleDisplayFlashMessage(message, 'error');
      return;
    }

    this.setState({ isLoading: true });

    const preparedData = prepareInvoiceData(invoice, items, payments);
    const currentClientTime = getCurrentClientTime();

    try {
      const options = {
        currentClientTime,
        currentStaffId: currentStaff.id,
      };
      const { payload } = await updateInvoiceWithItems(
        invoice.id,
        preparedData,
        options,
      );

      const newInvoice = timezoneizeObject(
        initializeInvoice(payload),
        timezone,
        'datetime',
      );

      this.setState({
        invoice: {
          ...newInvoice,
          items: payload.items.map(initializeInvoiceItem),
          payments: payload.payments.map(initializePayment(timezone)),
        },
        isLoading: false,
      }, () => {
        this.saveUnsavedChanges();
        onExitCallback();
        // handleCloseInvoiceDialog();
      });

      if (payments?.some(pay => pay.status === 'new') && currentInvoiceId) {
        const newDue = newInvoice?.amountDue?.getAmount()
        if (!newDue || newDue === 0) {
          handleDisplayFlashMessage(`invoice sent to email "${invoice.Client.email}"`);
        }
        this.deferredRequestLoadInvoiceEmails()
      }
    } catch (error) {
      console.log('handleUpdateInvoice_ERROR', error)
      if (error.name) {
        handleDisplayFlashMessage(error.message, 'error');
      }
      getSessionError(error, handleDisplayFlashMessage)
      this.setState({ isLoading: false });
    }
  }

  async handleCancelInvoice() {
    this.setState({
      isLoading: true,
      isDeleteInvoiceWindowOpen: false,
    });
    const { invoice: { id }} = this.state;
    const { auth: { currentStaff }, deleteInvoice, onExitCallback, handleCloseInvoiceDialog, handleDisplayFlashMessage } = this.props;
    try {
      const options = {
        currentStaffId: currentStaff.id,
      };
      await deleteInvoice(id, options);
      onExitCallback();
      handleCloseInvoiceDialog();
    } catch (error) {
      console.log(error);
      getSessionError(error, handleDisplayFlashMessage)
      this.setState({ isLoading: false });
    }
  }

  async handleDeleteInvoiceItem() {
    const {
      deleteItemId,
      invoice: { clientId, currency, items: oldItems },
      clientPackages: originalClientPackages
    } = this.state;
    const { auth } = this.props;

    const deletingItems = oldItems.filter(({ id, sourceItemId }) => {
      return (id === deleteItemId) || (sourceItemId && sourceItemId === deleteItemId);
    });

    let items = [...oldItems];
    deletingItems.forEach((item) => {
      if (item.status === 'new') {
        const index = items.indexOf(item);
        items = [
          ...items.slice(0, index),
          ...items.slice(index + 1),
        ];
      } else {
        const deletedItem = setDeletedStatus(item);

        const index = items.indexOf(item);
        items = [
          ...items.slice(0, index),
          deletedItem,
          ...items.slice(index + 1),
        ];
      }
    });
    items = freshReorderItems(items);

    const deletingItem = oldItems.find(({ id }) => id === deleteItemId);
    if (deletingItem.sourceItemId) {
      items = unmarkAppointmentItemDiscounted(items, deletingItem);
    }

    let clientPackages = [...originalClientPackages];
    const packageItem = deletingItems.find(({ type }) => {
      return type === invoiceItemTypes.packageRedeem.type;
    });
    if (packageItem) {
      clientPackages = increaseAvailableQtyForService(
        clientPackages,
        packageItem.clientPackageId,
      );

      if (packageItem.sourceItemId !== deleteItemId) {
        items = resetAppointmentItemAttrsByPackage(items, packageItem);
      }
    }

    const creditBalance = await calculateCreditBalance(
      clientId,
      currency,
      items,
      auth,
    );

    this.setState(({ invoice }) => ({
      clientPackages,
      invoice: {
        ...invoice,
        items,
      },
      isDeleteItemWindowOpen: false,
      deleteItemId: '',
      deleteItemAnchor: null,
      doCreditsExist: creditBalance > 0,
    }), () => {
      this.recalculateInvoiceTotals();
      this.addUnsavedChanges();
    });
  }

  render() {
    const {
      isAddAppointmentDialogOpened,
      isItemDiscountWindowOpen,
      itemDiscountAnchor,
      isGiftWindowOpen,
      giftAnchor,
      isDeleteItemWindowOpen,
      deleteItemAnchor,
      isApplyPaymentDialogOpened,
      isRequestOnlinePaymentDialogOpened,
      isEmailWindowOpen,
      emailAnchor,
      client,
      invoice: { items, payments, ...invoice },
      isLoading,
      isDeletePaymentWindowOpen,
      deletePaymentAnchor,
      isDeleteInvoiceWindowOpen,
      isAddGiftCardDialogOpened,
      isAddPackageDialogOpened,
      isUnsavedChanges,
      isCreditWindowOpen,
      creditAnchor,
      doCreditsExist,
      isConfirmCloseDialogOpened,
      isPackageItemWindowOpened,
      isConfirmCreditOrGratuityDialogOpened,
      packageItemAnchor,
      isInvoiceNew,
      // negativeAmountDue
    } = this.state;

    const {
      classes,
      isClientInputShown,
      handleCloseInvoiceDialog,
      ...restProps
    } = this.props;
    const {
      salesSettings: {
        invoiceTitle,
        businessRegistrationType,
        businessRegistrationNumber,
        stripeConnected,
      },
      invoiceEmails,
      business: { timezone },
      locations,
      staff,
      auth: { currentStaff }
    } = restProps;

    const preparedItems = prepareInvoiceItems(items);

    const preparedPayments = preparePayments(payments);

    const preparedEmails = prepareEmails(invoiceEmails.byId, invoice.id)

    const preparedLocations = prepareOptions(locations.byId);
    const selectedLocation = preparedLocations.find((location) => {
      return location.id === invoice.locationId;
    });

    const preparedStaff = prepareOptions(staff.byId)
      .map(makeOptionWithField({ labelColumn: 'alias' }));

    const isApplyPaymentDisabled = items?.filter(({type}) => type === 'creditIssued' || type === 'gratuity')?.length === 2

    return (
      <Grid container className={classes.root}>
        <Grid container className={classes.topButton}>
          {isUnsavedChanges ? (
            <CloseButton onClick={this.handleOpenConfirmCloseDialog} />
          ) : (
            <CloseButton onClick={handleCloseInvoiceDialog} />
          )}
        </Grid>
        {invoice.isAppointmentCancelled && (
          <OrangeInfoStrip className={classes.noChangeReasonBox}>
            <Typography variant="subtitle1">
              The invoice cannot be changed because its appointment is cancelled
            </Typography>
          </OrangeInfoStrip>
        )}
        <header className={classes.header}>
          <Grid container className={classes.clientAndLocation}>
            <Grid
              container
              className={cx(classes.clientInfo, classes.headerGrid)}
            >
              <Grid item className={classes.invoiceToTitle}>Invoice to</Grid>
              {(isInvoiceNew && isClientInputShown) ? (
                <Grid container className={classes.searchClientBox}>
                  <SearchInput
                    showCreateLink
                    showFormOnCreate
                    clearResultsOnClick
                    id="client-search"
                    label="Client"
                    onSearch={(value) => {
                      return SearchApi.searchClients(value, restProps.auth);
                    }}
                    onResultLineClick={this.handleSelectClient}
                    onCreateLineClick={this.handleInitiateClient}
                    onOpenPopper={({ isLoading, isDropdownOpened, isFormOpened }) => {
                      return isLoading || isDropdownOpened || isFormOpened;
                    }}
                    form={(actions) => (
                      <ClientForm
                        client={client}
                        handleAddClient={this.handleAddClient(actions)}
                        onClose={actions.onCloseForm}
                        clientsErrorMap={clientsErrorMap}
                      />
                    )}
                  >
                    {(result, { onClick }) => (
                      <SearchItem onClick={onClick}>
                        <SearchCell>{result.fullName}</SearchCell>
                        <SearchCell align="right">
                          {result.mobileNumber}
                        </SearchCell>
                      </SearchItem>
                    )}
                  </SearchInput>
                  {client.status === 'new' && (
                    <Grid container className={classes.topMargin}>
                      <Grid container className={classes.clientInfoInner}>
                        <Grid item>
                          Client {client.firstName} {client.lastName} will be created.
                        </Grid>
                        {client.mobileNumber && (
                          <Grid item>
                            <Grid>Mobile number: {client.mobileNumber}</Grid>
                          </Grid>
                        )}
                        {client.email && (
                          <Grid item>
                            <Grid>Email: {client.email}</Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {isPositive(client.outstandingBalanceMoney) && (
                    <Grid
                      container
                      className={cx(
                        classes.amountOwingStrip,
                        classes.topMargin,
                      )}
                    >
                      <OutstandingBalanceStrip
                        amount={client.outstandingBalanceMoney.toFormat()}
                        clientId={client.id}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container>
                  <Grid item className={classes.clientName}>
                    {invoice.clientName}
                  </Grid>
                  {isPositive(client.outstandingBalanceMoney) && (
                    <Grid container className={classes.amountOwingStrip}>
                      <OutstandingBalanceStrip
                        amount={client.outstandingBalanceMoney.toFormat()}
                        clientId={client.id}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid
              container
              className={cx(classes.locationInfo, classes.headerGrid)}
            >
              <Grid container className={classes.locationTitle}>Location</Grid>
              {isInvoiceNew ? (
                <Select
                  id="locationId"
                  onChange={(option) => this.handleChangeLocation(option)}
                  options={preparedLocations}
                  value={selectedLocation}
                />
              ) : (
                <Grid item className={classes.locationName}>
                  {invoice.locationName}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container className={classes.invoiceMetaInfo}>
            <Grid item className={classes.invoiceTitle}>
              {invoiceTitle}&nbsp;
              {invoice.formattedInvoiceNumber}
            </Grid>
            <Grid container className={classes.invoiceMetaInfoContent}>
              <Grid container className={classes.invoiceSubMetaInfo}>
                <Grid item className={classes.invoiceSubMetaInfoTitle}>
                  Invoice date:
                </Grid>
                <Grid item>
                  {invoice.isChangeable ? (
                    <DatePicker
                      id="datetime"
                      selected={formattedDate(invoice.datetime)}
                      onChange={this.handleChangeInvoiceDate}
                      rootComponentProps={{
                        className: classes.datepickerInput,
                      }}
                    />
                  ) : (
                    formatInvoiceDatetime(invoice.datetimeTz)
                  )}
                </Grid>
              </Grid>
              {businessRegistrationType && businessRegistrationNumber && (
                <Grid container className={classes.invoiceSubMetaInfo}>
                  <Grid item className={classes.invoiceSubMetaInfoTitle}>
                    {businessRegistrationType}:
                  </Grid>
                  <Grid item>{businessRegistrationNumber}</Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </header>
        <main className={classes.main}>
          <div className={classes.tableWrapper}>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={cx(
                      classes.headerCell,
                      classes.descriptionCell,
                    )}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.headerCell,
                      classes.quantityCell,
                    )}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.headerCell,
                      classes.unitPriceCell,
                    )}
                  >
                    Unit Price
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.headerCell,
                      classes.taxCell,
                    )}
                  >
                    Tax
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.headerCell,
                      classes.amountCell,
                    )}
                  >
                    Amount {invoice.currency}
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.headerCell,
                      classes.actionsCell,
                    )}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {preparedItems.map(item => {
                  const Component = itemsLineMap[item.type];
                  return (
                    <Component
                      {...this.state}
                      {...restProps}
                      key={item.id}
                      item={item}
                      classes={classes}
                      isChangeable={invoice.isChangeable}
                      preparedStaff={preparedStaff}
                      onClickOpenItemDiscountWindow={this.onClickOpenItemDiscountWindow}
                      onClickOpenItemPackageWindow={this.onClickOpenItemPackageWindow}
                      onClickOpenDeleteItemWindow={this.onClickOpenDeleteItemWindow}
                      handleAddProduct={this.handleAddProduct}
                      handleQuantityChange={this.handleQuantityChange}
                      handleUnitPriceChange={this.handleUnitPriceChange}
                    />
                  );
                })}
                {!_.isEmpty(preparedItems) && (
                  <>
                    <TableRow
                      className={cx(
                        classes.totalsRow,
                        classes.firstTotalsRow,
                      )}
                    >
                      <TableCell colSpan={isMobile ? "1" : "4"} className={classes.totalsCell}>
                        Includes tax of:
                      </TableCell>
                      <TableCell className={classes.totalsCell}>
                        {formatMoney(invoice.totalTax)}
                      </TableCell>
                      <Hidden mdDown>
                        <TableCell className={classes.totalsCell} />
                      </Hidden>
                    </TableRow>
                    <TableRow
                      className={cx(
                        classes.totalsRow,
                        classes.totalPriceBox,
                      )}
                    >
                      <Hidden mdDown>
                        <TableCell colSpan="2" />
                      </Hidden>
                      <TableCell
                        colSpan={isMobile ? "1" : "2"}
                        className={cx(
                          classes.totalsCell,
                          classes.totalsDivider,
                        )}
                      >
                        <strong>Total {invoice.currency}:</strong>
                      </TableCell>
                      <TableCell
                        className={cx(
                          classes.totalsCell,
                          classes.totalsDivider,
                        )}
                      >
                        <strong>{formatMoney(invoice.totalPrice)}</strong>
                      </TableCell>
                      <TableCell
                        className={cx(
                          classes.totalsCell,
                          classes.totalsDivider,
                          classes.actionsCell,
                        )}
                      >
                        {invoice.isChangeable && (
                          <Grid container className={classes.actionsBox}>
                            {doCreditsExist ? (
                              <Grid container>
                                <DropdownMenu
                                  classes={classes}
                                  actionMenuRef={this.actionMenuRef}
                                >
                                  <MenuItem>
                                    <Grid
                                      container
                                      onClick={() => {
                                        return this.onClickOpenItemDiscountWindow(
                                          null,
                                          this.actionMenuRef.current,
                                        );
                                      }}
                                      className={classes.actionMenuItem}
                                    >
                                      <Grid
                                        container
                                        className={cx(
                                          classes.visibleIconBox,
                                          classes.actionItemIconBox,
                                        )}
                                      >
                                        <ScissorsIcon className={classes.icon}/>
                                      </Grid>
                                      <Grid item>Apply discount</Grid>
                                    </Grid>
                                  </MenuItem>
                                  <MenuItem>
                                    <Grid
                                      container
                                      onClick={() => {
                                        return this.onClickOpenCreditWindow(
                                          this.actionMenuRef.current,
                                        );
                                      }}
                                      className={classes.actionMenuItem}
                                    >
                                      <Grid
                                        container
                                        className={cx(
                                          classes.visibleIconBox,
                                          classes.actionItemIconBox,
                                        )}
                                      >
                                        <CreditIcon className={classes.icon}/>
                                      </Grid>
                                      <Grid item>Apply credit</Grid>
                                    </Grid>
                                  </MenuItem>
                                </DropdownMenu>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                onClick={(event) => {
                                  return this.onClickOpenItemDiscountWindow(
                                    null,
                                    event.target,
                                  );
                                }}
                                className={classes.visibleIconBox}
                              >
                                <ScissorsButton />
                              </Grid>
                            )}
                            <Grid
                              container
                              onClick={this.onClickOpenGiftWindow}
                              className={classes.visibleIconBox}
                            >
                              <GiftButton />
                            </Grid>
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                    {!_.isEmpty(preparedItems) && (
                      <>
                        <TableRow className={classes.totalsRow}>
                          <TableCell colSpan={isMobile ? "1" : "4"} className={classes.totalsCell}>
                            Less amount paid:
                          </TableCell>
                          <TableCell className={classes.totalsCell}>
                            {formatMoney(invoice.lessAmountPaid)}
                          </TableCell>
                          <Hidden mdDown>
                            <TableCell className={classes.totalsCell} />
                          </Hidden>
                        </TableRow>
                        {
                          invoice?.gratuity?.getAmount() > 0 && (
                            <TableRow className={classes.totalsRow}>
                              <TableCell colSpan={isMobile ? "1" : "4"} className={classes.totalsCell}>
                                <strong>Gratuity:</strong>
                              </TableCell>

                              <TableCell className={classes.totalsCell}>
                                <strong>{formatMoney(invoice?.gratuity)}</strong>
                              </TableCell>

                              <Hidden mdDown>
                                <TableCell className={classes.totalsCell} />
                              </Hidden>
                            </TableRow>
                          )
                        }
                        {
                          invoice?.creditAmount?.getAmount() > 0 && (
                            <TableRow className={classes.totalsRow}>
                              <TableCell colSpan={isMobile ? "1" : "4"} className={classes.totalsCell}>
                                <strong>Credit issued:</strong>
                              </TableCell>
                              <TableCell className={classes.totalsCell}>
                                <strong>{formatMoney(invoice?.creditAmount)}</strong>
                              </TableCell>
                              <Hidden mdDown>
                                <TableCell className={classes.totalsCell} />
                              </Hidden>
                            </TableRow>
                          )
                        }
                        <TableRow className={classes.totalsRow}>
                          <TableCell colSpan={isMobile ? "1" : "4"} className={classes.totalsCell}>
                            <strong>Amount due:</strong>
                          </TableCell>
                          <TableCell className={classes.totalsCell}>
                            <strong>{formatMoney(invoice.amountDue)}</strong>
                          </TableCell>
                          <Hidden mdDown>
                            <TableCell className={classes.totalsCell} />
                          </Hidden>
                        </TableRow>
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </div>

          <Grid container className={classes.topButtons}>
            {invoice.isChangeable && (
              <Grid container className={classes.itemsButtons}>
                <Grid item className={classes.addItemButton}>
                  <WhiteButton
                    variant="contained"
                    size="small"
                    onClick={this.handleAddProductItemTemplate}
                    startIcon={<PlusIcon className={classes.plusIcon}/>}
                  >
                    Product
                  </WhiteButton>
                </Grid>
                <Grid item className={classes.addItemButton}>
                  <WhiteButton
                    variant="contained"
                    size="small"
                    onClick={this.handleOpenAddGiftCard}
                    startIcon={<PlusIcon className={classes.plusIcon}/>}
                  >
                    Gift Card
                  </WhiteButton>
                </Grid>
                <Grid item className={classes.addItemButton}>
                  <WhiteButton
                    variant="contained"
                    size="small"
                    onClick={this.handleOpenAddPackage}
                    startIcon={<PlusIcon className={classes.plusIcon}/>}
                  >
                    Package
                  </WhiteButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Hidden mdDown>
            <BottomButtons
              {...this.state}
              {...this.props}
              preparedItems={preparedItems}
              stripeConnected={stripeConnected}
              preparedPayments={preparedPayments}
              isApplyPaymentDisabled={isApplyPaymentDisabled}
              handleOpenApplyPayment={this.handleOpenApplyPayment}
              handleOpenRequestOnlinePayment={this.handleOpenRequestOnlinePayment}
              onClickOpenEmailWindow={this.onClickOpenEmailWindow}
              handlePrintInvoice={this.handlePrintInvoice}
              onClickOpenDeleteInvoiceWindow={this.onClickOpenDeleteInvoiceWindow}
              handleCreateInvoice={this.handleCreateInvoice}
              handleUpdateInvoice={this.handleUpdateInvoice}
            />
          </Hidden>
          <Hidden mdUp>
            <MobileBottomButtons
              {...this.state}
              {...this.props}
              preparedItems={preparedItems}
              stripeConnected={stripeConnected}
              preparedPayments={preparedPayments}
              isApplyPaymentDisabled={isApplyPaymentDisabled}
              handleOpenApplyPayment={this.handleOpenApplyPayment}
              handleOpenRequestOnlinePayment={this.handleOpenRequestOnlinePayment}
              onClickOpenEmailWindow={this.onClickOpenEmailWindow}
              handlePrintInvoice={this.handlePrintInvoice}
              onClickOpenDeleteInvoiceWindow={this.onClickOpenDeleteInvoiceWindow}
              handleCreateInvoice={this.handleCreateInvoice}
              handleUpdateInvoice={this.handleUpdateInvoice}
            />
          </Hidden>
        </main>
        <footer className={classes.footer}>
          <Grid container className={classes.footerBox}>
            <Grid item className={classes.footerTitle}>
              Payments
            </Grid>
            <Grid container className={classes.footerContent}>
              {preparedPayments.map(payment => (
                < PaymentsComponent
                  key={payment.id}
                  payment={payment}
                  currentStaff={currentStaff}
                  onClickOpenDeletePaymentWindow={this.onClickOpenDeletePaymentWindow}
                  classes={classes}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container className={classes.footerBox}>
            <Grid item className={classes.footerTitle}>
              Emails sent
            </Grid>
            <Grid container className={classes.footerContent}>
              {preparedEmails.map(email => (
                <Grid
                  container
                  key={email.id}
                  className={classes.footerContentLine}
                >
                  {/* <Grid container className={classes.circleIconBox}>
                    <CircleIcon className={classes.circleIcon}/>
                  </Grid> */}
                  <div className={classes.footerContentFirstColumn}>
                    {email.datetime ? (
                      <div className={classes.circleIconMailBox}>
                        <CircleIcon className={classes.circleIconMail}/>
                        <span className={classes.footerContentMailType}>
                          {email.type === 'paymentRequest' ? 'Payment request' : 'Invoice'} was sent to
                        </span>
                        <span>
                          {email.email} on {getDate(email.datetime, timezone)} at {getTime(email.datetime, timezone)}
                        </span>
                      </div>
                    ) : (
                      <span
                        className={classes.notSentYetEmail}
                        title="In progress"
                      >
                        Invoice was sent to {email.email}
                      </span>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </footer>
        <CustomDialog
          maxWidth="md"
          open={isAddAppointmentDialogOpened}
          classes={{
            paper: classes.addAppointmentPaper,
          }}
        >
          <AddAppointment
            {...this.state}
            {...restProps}
            handleCloseAddAppointment={this.handleCloseAddAppointment}
            handleSaveChanges={this.handleAddAppointments}
          />
        </CustomDialog>
        <CustomPopper
          open={isItemDiscountWindowOpen}
          anchorEl={itemDiscountAnchor}
          onClickAway={this.onClickCloseItemDiscountWindow}
          placement="bottom-end"
        >
          <Discount
            {...this.state}
            {...restProps}
            onClose={this.onClickCloseItemDiscountWindow}
            handleAddItemDiscount={this.handleAddItemDiscount}
            handleAddTotalDiscount={this.handleAddTotalDiscount}
          />
        </CustomPopper>
        <CustomDialog
          fullWidth
          maxWidth="sm"
          scroll="body"
          open={isAddGiftCardDialogOpened}
        >
          <IssueGiftCard
            {...this.state}
            {...restProps}
            handleCloseAddGiftCard={this.handleCloseAddGiftCard}
            handleSaveChanges={this.handleIssueGiftCard}
          />
        </CustomDialog>
        <CustomPopper
          open={isGiftWindowOpen}
          anchorEl={giftAnchor}
          onClickAway={this.onClickCloseGiftWindow}
          placement="bottom-end"
        >
          <RedeemGiftCard
            {...this.state}
            {...restProps}
            onClose={this.onClickCloseGiftWindow}
            handleSaveChanges={this.handleRedeemGiftCard}
          />
        </CustomPopper>
        <CustomDialog
          fullWidth
          maxWidth="sm"
          scroll="body"
          open={isAddPackageDialogOpened}
        >
          <IssuePackage
            {...this.state}
            {...restProps}
            onClose={this.handleCloseAddPackage}
            handleSaveChanges={this.handleIssuePackage}
          />
        </CustomDialog>
        <ConfirmDeletionPopup
          open={isDeleteItemWindowOpen}
          anchorEl={deleteItemAnchor}
          onClickAway={this.onClickCloseDeleteItemWindow}
          placement="bottom-end"
          onCancel={this.onClickCloseDeleteItemWindow}
          onDelete={this.handleDeleteInvoiceItem}
        />
        <ConfirmDeletionPopup
          open={isDeletePaymentWindowOpen}
          anchorEl={deletePaymentAnchor}
          onClickAway={this.onClickCloseDeletePaymentWindow}
          title="Delete this payment?"
          placement="bottom-end"
          onCancel={this.onClickCloseDeletePaymentWindow}
          onDelete={this.handleDeletePayment}
        />
        <CustomDialog
          fullWidth
          scroll="body"
          maxWidth="sm"
          open={isApplyPaymentDialogOpened}
          onClose={this.handleCloseApplyPayment}
        >
          <ApplyPayment
            {...this.state}
            {...restProps}
            currency={invoice.currency}
            outstandingAmount={invoice.amountDue}
            onClose={this.handleCloseApplyPayment}
            handleAddPayment={this.handleAddPayment}
          />
        </CustomDialog>
        <CustomDialog
          fullWidth
          maxWidth="xs"
          scroll="body"
          open={isRequestOnlinePaymentDialogOpened}
        >
          <RequestOnlinePayment
            {...this.state}
            {...restProps}
            deferredRequestLoadInvoiceEmails={this.deferredRequestLoadInvoiceEmails}
            onClose={this.handleCloseRequestOnlinePayment}
          />
        </CustomDialog>
        <CustomPopper
          open={isEmailWindowOpen}
          anchorEl={emailAnchor}
          onClickAway={this.onClickCloseEmailWindow}
          placement="top-start"
          popperStyle={isMobile ? {width: '100%'} : {}}
        >
          <Email
            {...this.state}
            {...this.props}
            onClose={this.onClickCloseEmailWindow}
            handleSendEmail={this.handleSendEmail}
          />
        </CustomPopper>
        <CustomPopper
          open={isCreditWindowOpen}
          anchorEl={creditAnchor}
          onClickAway={this.onClickCloseCreditWindow}
          placement="bottom-end"
        >
          <RedeemCredit
            {...this.state}
            {...restProps}
            onClose={this.onClickCloseCreditWindow}
            handleSaveChanges={this.handleRedeemCredit}
          />
        </CustomPopper>
        <ConfirmDialog
          title="Cancel invoice?"
          description="Are you sure you want to cancel the invoice?"
          open={isDeleteInvoiceWindowOpen}
          onClose={this.onClickCloseDeleteInvoiceWindow}
          onCancel={this.onClickCloseDeleteInvoiceWindow}
          onSuccess={this.handleCancelInvoice}
          successButtonText="Cancel invoice"
        />
        <ConfirmDialog
          title="You have unsaved changes"
          open={isConfirmCloseDialogOpened}
          onClose={this.handleCloseConfirmCloseDialog}
        >
          <Grid container>
            <GreenButton
              variant="contained"
              size="small"
              onClick={this.handleSaveInvoiceWhileClosingWindow}
              className={classes.saveButtonFromConfirmDialog}
            >
              Save
            </GreenButton>
            <GreyButton
              variant="text"
              size="small"
              onClick={this.handleCloseInvoiceWithoutSaving}
            >
              Close invoice without saving
            </GreyButton>
          </Grid>
          <GreyButton
            variant="text"
            size="small"
            onClick={this.handleCloseConfirmCloseDialog}
            className={classes.cancelButtonFromConfirmDialog}
          >
            Cancel
          </GreyButton>
        </ConfirmDialog>
        <ConfirmDialog
          open={isConfirmCreditOrGratuityDialogOpened}
          onClose={this.handleCloseConfirmCreditOrGratuityDialogOpened}
          titleStyle={{paddingTop: '0'}}
        >
          <CreditOrGratuity
            currentItems={items}
            handleCloseConfirmCreditOrGratuityDialogOpened={this.handleCloseConfirmCreditOrGratuityDialogOpened}
            handleAddClientCredit={this.handleAddClientCredit}
            handleAddGratuity={this.handleAddGratuity}
          />
        </ConfirmDialog>
        <CustomPopper
          open={isPackageItemWindowOpened}
          anchorEl={packageItemAnchor}
          onClickAway={this.onClickCloseItemPackageWindow}
          placement="bottom-end"
        >
          <RedeemPackage
            {...this.state}
            {...restProps}
            onClose={this.onClickCloseItemPackageWindow}
            handleRedeemPackage={this.handleRedeemPackage}
          />
        </CustomPopper>
        <Backdrop open={isLoading} className={classes.backdrop}>
          <CircularProgress />
        </Backdrop>
      </Grid>
    );
  }
}

const mapStateToProps = ({
  salesSettings,
  business,
  payments,
  discounts,
  invoiceEmails,
  auth,
  locations,
  staff,
}) => ({
  salesSettings,
  business,
  payments,
  discounts,
  invoiceEmails,
  auth,
  locations,
  staff,
});

const mapDispatchToProps = (dispatch) => ({
  loadSalesSettings: bindActionCreators(loadSalesSettings, dispatch),
  createInvoiceWithItemsAndClient: bindActionCreators(
    createInvoiceWithItemsAndClient, dispatch,
  ),
  updateInvoiceWithItems: bindActionCreators(updateInvoiceWithItems, dispatch),
  loadInvoiceWithItemsAndPayments: bindActionCreators(
    loadInvoiceWithItemsAndPayments,
    dispatch,
  ),
  loadDiscounts: bindActionCreators(loadDiscounts, dispatch),
  loadInvoiceEmails: bindActionCreators(loadInvoiceEmails, dispatch),
  sendInvoiceEmail: bindActionCreators(sendInvoiceEmail, dispatch),
  deleteInvoice: bindActionCreators(deleteInvoice, dispatch),
  loadLocations: bindActionCreators(loadLocations, dispatch),
  loadStaff: bindActionCreators(loadStaff, dispatch),
  createClientCredit: bindActionCreators(createClientCredit, dispatch),
  createClient: bindActionCreators(createClientForBooking, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(Invoice, styles));
