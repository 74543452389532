import { Fragment } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GreyButton from '../../../../shared_components/buttons/Grey';
import RedButton from '../../../../shared_components/buttons/Red';
import CircularProgressWithBackdrop from '../../../../shared_components/CircularProgressWithBackdrop';

const useStyles = makeStyles()(theme => ({
  buttonBox: {
    justifyContent: 'space-between',
  },
}));

const ConfirmDialog = (props) => {
  const {
    open,
    onClose,
    onCancel,
    onSuccess,
    title,
    description,
    successButtonText,
    cancelButtonText,
    children,
    titleStyle = {},
    descriptionWrapperStyle = {},
    isLoading = false
  } = props;

  const {classes} = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="confirm-dialog"
      onClose={onClose}
    >
      <DialogTitle style={titleStyle} id="confirm-dialog">{title}</DialogTitle>
      {description && (
        <DialogContent style={descriptionWrapperStyle}>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions className={classes.buttonBox}>
        {children ? (
          children
        ) : (
          <Fragment>
            <RedButton
              variant="text"
              size="small"
              onClick={onSuccess}
            >
              {successButtonText}
            </RedButton>
            <GreyButton
              variant="text"
              size="small"
              onClick={onCancel}
            >
              {cancelButtonText}
            </GreyButton>
          </Fragment>
        )}
      </DialogActions>
      <CircularProgressWithBackdrop loading={isLoading} />
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  successButtonText: 'Yes',
  cancelButtonText: 'No',
};

export default ConfirmDialog;
