import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import CustomPopper from '../CustomPopper';
import GreyButton from '../../../../shared_components/buttons/Grey';
import RedButton from '../../../../shared_components/buttons/Red';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
  },
  buttonBox: {
    marginTop: theme.spacing(),
  },
  deleteButton: {
    marginRight: theme.spacing(2),
  },
}));

const ConfirmDeletionPopup = (props) => {
  const { onCancel, onDelete, title, ...restProps } = props;

  const {classes} = useStyles();

  return (
    <CustomPopper {...restProps}>
      <Grid container className={classes.root}>
        <Grid item>
          <Typography color="initial" variant="subtitle1">
            {title}
          </Typography>
        </Grid>
        <Grid container className={classes.buttonBox}>
          <RedButton
            stretched
            variant="contained"
            size="small"
            onClick={onDelete}
            className={classes.deleteButton}
          >
            Delete
          </RedButton>
          <GreyButton
            variant="text"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </GreyButton>
        </Grid>
      </Grid>
    </CustomPopper>
  );
};

ConfirmDeletionPopup.defaultProps = {
  title: 'Delete this item?',
};

export default ConfirmDeletionPopup;
