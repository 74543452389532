import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    width: '200px',
    backgroundColor: '#ffffff',
    padding: '15px 0',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    display: "block",
    padding: "5px",
    cursor: "pointer",
    textAlign: "center",
    width: "100%",
    "&:hover": {
      color: "#FFF",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SupportMenu = (props) => {
  const { name, email } = props;

  const {classes} = useStyles();
  const openWidget = () => {
    FreshworksWidget('identify', 'ticketForm', { name, email });
    FreshworksWidget('open');
  }

  return (
    <Grid container className={classes.mainContainer}>
      <a
        href="https://hivemanager.io/new-features/"
        className={classes.link}
        title="New Features"
        target="_blank"
        rel="noopener noreferrer"
      >New Features</a>
      <a
        href="https://hivemanager.io/new-features/"
        className={classes.link}
        title="Guide"
        target="_blank"
        rel="noopener noreferrer"
      >Guide</a>
      <a
        href="https://status.hivemanager.io"
        className={classes.link}
        title="Status"
        target="_blank"
        rel="noopener noreferrer"
      >Status</a>
      <a
        onClick={openWidget}
        className={classes.link}
        title="Contact Support"
        target="_blank"
        rel="noopener noreferrer"
      >Contact Support</a>
      <a
        title="Community"
        href="https://www.facebook.com/groups/hivemanager"
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
      >Community
      </a>
    </Grid>
  );
}

export default SupportMenu;
