import BaseInput from './Base';

const OutlinedInput = ({variant = "outlined", ...props}) => (
  <BaseInput
    {...props}
    size="small"
    variant={variant}
  />
);

export default OutlinedInput;
