/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import HelpIcon from '@mui/icons-material/HelpOutline';
import { withStyles } from 'tss-react/mui';
import Link from 'next/link';

const styles = (theme) => ({
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(47, 212, 117)',
    fontWeight: '600',
    padding: '3px 1rem',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
  },
  action: {
    width: '15rem',
    padding: '2px',
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '.2rem',
    color: 'black',
    fontWeight: '700',
    cursor: 'pointer',
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f5f5f5',
      color: '#f29200'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '.75rem',
      width: '7rem',
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: '.75rem',
    },
  },
  icon: {
    marginRight: '0.5rem',
  },
});

function NotificationBar(props) {
  const { showStepsBar, showCriticalBar } = props;
  const { text = '', buttonText = '', buttonUrl = '/', classes } = props;

  if (!showStepsBar) {
    return null
  }

  return (
    <div className={classes.bar} style={{ top: showCriticalBar && window.innerWidth < 600 ? '1.7rem' : showCriticalBar ? '2.1rem' : 0 }}>
      <div className={classes.textContainer}>
        <HelpIcon className={classes.icon}/>
        <div>{text}</div>
      </div>
      <Link href={buttonUrl}>
        <a className={classes.action}>{buttonText}</a>
      </Link>
    </div>
  )
}

export default withStyles(NotificationBar, styles);