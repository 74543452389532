/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unknown-property */
import { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import withFlashMessage from '../../../shared_hocs/withFlashMessage';
import NavBar from '../components/NavBar';
import theme, { navbarHeightMultiplier } from '../../../shared_client_utils/theme';
import CircularProgressWithBackdrop from '../../../shared_components/CircularProgressWithBackdrop';
import CriticalBar from '../components/CriticalBar';
import NotificationBar from '../components/NotificationBar';

const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: "center",
    fontFamily: "Roboto",
    height: '100%',
  },
});

const withLayout = (Page, showNavBar = false) => {
  class LayoutPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLeftSidebarOpened: false,
        isLoading: false,
      };
      this.handleOpenLeftSidebar = this.handleOpenLeftSidebar.bind(this);
      this.handleCloseLeftSidebar = this.handleCloseLeftSidebar.bind(this);
      this.handleStartLoading = this.handleStartLoading.bind(this);
      this.handleStopLoading = this.handleStopLoading.bind(this);
    }

    handleOpenLeftSidebar() {
      this.setState({ isLeftSidebarOpened: true });
    }

    handleCloseLeftSidebar() {
      this.setState({ isLeftSidebarOpened: false });
    }

    handleStartLoading() {
      this.setState({ isLoading: true });
    }

    handleStopLoading() {
      this.setState({ isLoading: false });
    }

    render() {
      const { classes, ...restProps } = this.props;
      const { isLoading } = this.state;
      const { appointments = {}, business, auth } = this.props;
      const showCriticalBar = appointments?.isCritical > 0
      const showStepsBar = business?.getStarted?.filter(value => value?.isComplete)?.length !== business?.getStarted?.length
        && auth?.currentStaff?.roleName === 'Owner';
      const pTop = showNavBar ? theme.spacing(
        (showCriticalBar && showStepsBar) ? 16 : (showCriticalBar || showStepsBar) ? 12 : navbarHeightMultiplier
      ) : undefined

      return (
        <div
          className={classes.root}
          style={{
            paddingTop: pTop,
            display: showNavBar ? 'block' : 'flex',
          }}
        >
          {showNavBar && (
            <>
              <CriticalBar {...restProps}/>
              {
                showStepsBar && (
                  <NotificationBar
                    {...restProps}
                    showCriticalBar={showCriticalBar}
                    showStepsBar={showStepsBar}
                    text={isMobile ? "Getting Started! Please complete..." : "Getting Started! Please complete your setup to enable appointment bookings."}
                    buttonText="Click here to begin"
                    buttonUrl="/settings/get-started"
                  />
                )
              }
              <NavBar
                {...this.state}
                {...restProps}
                showCriticalBar={showCriticalBar}
                showStepsBar={showStepsBar}
                handleOpenLeftSidebar={this.handleOpenLeftSidebar}
                handleCloseLeftSidebar={this.handleCloseLeftSidebar}
                handleStartLoading={this.handleStartLoading}
                handleStopLoading={this.handleStopLoading}
              />
            </>
          )}
          <Page
            {...this.state}
            {...restProps}
            handleOpenLeftSidebar={this.handleOpenLeftSidebar}
            handleCloseLeftSidebar={this.handleCloseLeftSidebar}
            handleStartLoading={this.handleStartLoading}
            handleStopLoading={this.handleStopLoading}
          />
          <style jsx>{` * { box-sizing: border-box; }`}</style>

          <CircularProgressWithBackdrop loading={isLoading} />
        </div>
      );
    }
  }
  const mapStateToProps = ({
    appointments,
    business,
    auth
  }) => ({
    appointments,
    business,
    auth
  });

  return compose(
    withFlashMessage,
    connect(mapStateToProps, null)
  )(withStyles(LayoutPage, styles));
};

export default withLayout;
