import { memo } from 'react';

import BaseInput from './Base';
import OutlinedInput from '../Input';

const OutlinedPhoneInput = (props) => (
  <BaseInput {...props} component={OutlinedInput} />
);

const shouldNotUpdate = (prevProps, nextProps) => (
  (prevProps.value === nextProps.value) &&
  (prevProps.error === nextProps.error) &&
  (prevProps.disabled === nextProps.disabled)
);

export default memo(OutlinedPhoneInput, shouldNotUpdate);
