import { forwardRef } from 'react';
import BaseButton from './Base';

const OrangeButton = forwardRef((props, ref) => {
  return (
    <BaseButton
      color="primary"
      {...props}
      ref={ref}
    />
  );
});

export default OrangeButton;
