import { forwardRef } from 'react';
import { IconButton as IconButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(),
  },
}));

const IconButton = forwardRef((props, ref) => {
  const {classes} = useStyles();

  return (
    <IconButtonBase
      {...props}
      classes={classes}
      ref={ref}
    />
  );
});

export default IconButton;
