import { forwardRef } from 'react';
import { green } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import BaseButton from './Base';

const useStyles = makeStyles()({
  text: {
    '&:hover': {
      backgroundColor: green[50],
    },
  },
});

const GreenButton = forwardRef((props, ref) => {
  const {classes} = useStyles();

  return (
    <BaseButton
      color="secondary"
      classes={{
        text: classes.text,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default GreenButton;
