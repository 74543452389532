/* eslint-disable no-prototype-builtins */
import { makeStyles } from 'tss-react/mui';
import {
  Grid
} from '@mui/material';
import { isEmpty } from 'lodash';

import {
  EmailIcon,
} from '../../../../shared_components/icons';
import RedButton from '../../../../shared_components/buttons/Red';
import WhiteButton from '../../../../shared_components/buttons/White';
import GreenButton from '../../../../shared_components/buttons/Green';
import Tooltip from '../Tooltip';

const useStyles = makeStyles()(theme => ({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: '1.5px solid #272727',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  leftControls: {
    width: 'auto',

    '& > div': {
      marginRight: '20px',

      '&:last-of-type': {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      '& > div': {
        marginRight: 0,
      },
    },
  },
  rightControls: {
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginTop: theme.spacing(2),
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  emailIcon: {
    height: '18px',
  },
  redButton: {
    minWidth: '100px',
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const getTitleForCancelButton = (areAnyPayments, isUnsavedChanges) => {
  if (isUnsavedChanges) {
    return 'You have unsaved changes';
  } else if (areAnyPayments) {
    return 'Invoice with payments cannot be deleted';
  }
  return '';
};

const BottomButtons = (props) => {
  const {
    invoice: { items, payments, ...invoice },
    preparedItems,
    isApplyPaymentDisabled,
    client,
    handleOpenApplyPayment,
    stripeConnected,
    isInvoiceNew,
    isInvoiceFullyPaid,
    isUnsavedChanges,
    handleOpenRequestOnlinePayment,
    onClickOpenEmailWindow,
    handlePrintInvoice,
    preparedPayments,
    onClickOpenDeleteInvoiceWindow,
    handleCreateInvoice,
    handleUpdateInvoice
  } = props;

  const {classes} = useStyles();
  const isApplyPaymentBtnDisabled = !client?.id?.length || !invoice?.locationId?.length || !items?.filter((i) => i?.hasOwnProperty('description'))?.length >= 1

  return (
    <Grid container className={classes.main}>
      <Grid container className={classes.leftControls}>
        {invoice.isChangeable && (
          <>
            {!isEmpty(preparedItems) && !isApplyPaymentDisabled && (
              <Grid item>
                <GreenButton
                  disabled={isApplyPaymentBtnDisabled}
                  variant="contained"
                  size="small"
                  onClick={handleOpenApplyPayment}
                >
                  Apply payment
                </GreenButton>
              </Grid>
            )}

            {(stripeConnected && !isInvoiceNew && !isInvoiceFullyPaid) && (
              <Tooltip
                open={isUnsavedChanges}
                title="You have unsaved changes"
              >
                <Grid item>
                  <GreenButton
                    variant="contained"
                    size="small"
                    disabled={isUnsavedChanges}
                    onClick={handleOpenRequestOnlinePayment}
                  >
                    Request payment
                  </GreenButton>
                </Grid>
              </Tooltip>
            )}

            {!isInvoiceNew && (
              <>
                <Tooltip
                  open={isUnsavedChanges}
                  title="You have unsaved changes"
                >
                  <Grid item>
                    <WhiteButton
                      variant="contained"
                      size="small"
                      onClick={onClickOpenEmailWindow}
                      disabled={isUnsavedChanges || invoice?.amountDue?.getAmount() > 0}
                      startIcon={<EmailIcon className={classes.emailIcon}/>}
                    >
                      Email
                    </WhiteButton>
                  </Grid>
                </Tooltip>

                <Tooltip
                  open={isUnsavedChanges}
                  title="You have unsaved changes"
                >
                  <Grid item>
                    <WhiteButton
                      variant="contained"
                      size="small"
                      onClick={handlePrintInvoice}
                      disabled={isUnsavedChanges}
                    >
                      Print
                    </WhiteButton>
                  </Grid>
                </Tooltip>
              </>
            )}
          </>
        )}

        {!isInvoiceNew && (
          <Tooltip
            open={isUnsavedChanges || preparedPayments.length !== 0}
            title={getTitleForCancelButton(
              preparedPayments.length !== 0,
              isUnsavedChanges,
            )}
          >
            <Grid item>
              <RedButton
                variant="contained"
                size="small"
                onClick={onClickOpenDeleteInvoiceWindow}
                className={classes.redButton}
                disabled={
                  isUnsavedChanges || preparedPayments.length > 0
                }
              >
                Cancel
              </RedButton>
            </Grid>
          </Tooltip>
        )}
      </Grid>

      <Grid container className={classes.rightControls}>
        <Grid item>
          <GreenButton
            variant="contained"
            size="small"
            onClick={isInvoiceNew ? handleCreateInvoice : handleUpdateInvoice}
            disabled={!isUnsavedChanges || !client?.id?.length}
          >
            {isInvoiceNew ? 'Create' : 'Save'}
          </GreenButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BottomButtons;
