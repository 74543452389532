import OutlinedInput from '../../../shared_components/Input';

// Value should only contain digits
const isValid = (value) => !value.match(/[^0-9]/);

const NaturalNumberInput = ({ onChange, ...props }) => {
  const handleChange = (event) => {
    if (!isValid(event.target.value)) return;

    return onChange(event);
  }

  return (
    <OutlinedInput
      {...props}
      type="text"
      onChange={handleChange}
    />
  );
};

export default NaturalNumberInput;
