import moment from 'moment-timezone';

export const formatAppointmentDatetime = (datetimeTz) => {
  return moment(datetimeTz).format('ll @ LT');
};

export const composeOverlapByDurationErrorMessage = ({ startTime, endTime, location }, timezone) => {
  const start = moment.tz(startTime, timezone).format('LT');
  const end = moment.tz(endTime, timezone).format('LT');

  return `The appointment overlaps another appointment ${start} - ${end} at ${location}`;
};

export const composeOverlapByPaddingsErrorMessage = ({
  startTime, endTime, paddingBefore, paddingAfter, location,
}, timezone) => {
  const start = moment.tz(startTime, timezone).format('LT');
  const end = moment.tz(endTime, timezone).format('LT');

  const beforeText = `${paddingBefore} minutes padding before`;
  const afterText = `${paddingAfter} minutes padding after`;

  let paddingText = 'paddings';
  if (paddingBefore > 0 && paddingAfter > 0) {
    paddingText = `${beforeText} and ${afterText}`;
  } else if (paddingBefore > 0) {
    paddingText = `${beforeText}`;
  } else if (paddingAfter > 0) {
    paddingText = `${afterText}`;
  }

  return `
    The appointment overlaps another appointment ${start} - ${end} at ${location}
    by ${paddingText}. You can save this appointment anyway
  `;
};

export const composeOverlapByBusyTimeErrorMessage = (params, timezone) => {
  const { startTime, endTime } = params;
  const start = moment.tz(startTime, timezone).format('LT');
  const end = moment.tz(endTime, timezone).format('LT');

  return `
    The appointment overlaps busy time ${start} - ${end}. You can save this appointment anyway
  `;
};

export const composeAppointmentBusyResourceErrorMessage = (options, timezone) => {
  const { fullStartTime, fullEndTime, staffName } = options;
  const start = moment.tz(fullStartTime, timezone).format('LT');
  const end = moment.tz(fullEndTime, timezone).format('LT');

  return `
    The resource is unavailable due to it's already used in another appointment ${start} - ${end} by ${staffName}
  `;
};

// !no remove this is an unused error handling from the back, it may be needed
// export const checkNewStaffByPositionErrorMessage = (options) => {
//   const {oldStaff, staff, oldPosition, newPosition } = options;
//   const { alias: oldStaffAlias} = oldStaff
//   const { alias: staffAlias} = staff
//   const { name: oldPositionName} = oldPosition
//   const { name: newPositionName} = newPosition

//   return `
//   The selected ${staffAlias} has a position "${newPositionName?.toUpperCase()}" but need a position "${oldPositionName.toUpperCase()}" as ${oldStaffAlias}
//   `;
// };
// if (error.name === 'CheckNewStaffByPositionError') {
//   const message = checkNewStaffByPositionErrorMessage(error.body)
//   handleDisplayFlashMessage(message, 'error');
// }

export const getFlashMessageWhileMovingAppointment = (appointment) => {
  const { isCompleted, areThereAnyPayments, isSoapLocked } = appointment;

  if (isCompleted) {
    return 'The appointment cannot be moved because it\'s completed';
  } else if (areThereAnyPayments) {
    return 'The appointment cannot be moved because its invoice has payments';
  } else if (isSoapLocked) {
    return 'The appointment cannot be moved because its Chart is locked';
  } else {
    return 'The appointment cannot be moved';
  }
};

export const getNoChangeAppointmentReason = (appointment) => {
  const { isCompleted, areThereAnyPayments, isSoapLocked } = appointment;

  if (isCompleted) {
    return 'The appointment cannot be changed or canceled because it\'s completed';
  } else if (areThereAnyPayments) {
    return 'The appointment cannot be changed or canceled because its invoice has payments';
  } else if (isSoapLocked) {
    return 'The appointment cannot be changed or canceled because its Chart is locked';
  } else {
    return 'The appointment cannot be changed or canceled';
  }
};

export const isTimeWentBy = (endTimeM, currentTimeM) => {
  return currentTimeM.isSameOrAfter(endTimeM);
};
