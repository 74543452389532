import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    padding: '10px',
  },
}));

const Orange = ({ children, className }) => {
  const {classes} = useStyles();

  return (
    <Grid
      container
      className={className}
      classes={{
        root: classes.root,
      }}
    >
      {children}
    </Grid>
  );
};

Orange.defaultProps = {
  className: '',
};

export default Orange;
