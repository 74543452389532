import { Grid } from '@mui/material';
import {
  Brightness1 as CircleIcon,
} from '@mui/icons-material';
import TrashButton from '../../../../shared_components/buttons/Trash';
import { formatPaymentDatetime } from '../../utils/paymentUtils';

const PaymentsComponent = (props) => {
  const { classes, payment, currentStaff, onClickOpenDeletePaymentWindow } = props;
  return (
    <Grid
      container
      key={payment.id}
      className={classes.footerContentLine}
    >
      <Grid container className={classes.circleIconBox}>
        <CircleIcon className={classes.circleIcon}/>
      </Grid>
      <div className={classes.footerContentFirstColumn}>
        {formatPaymentDatetime(payment.datetimeTz)}
      </div>
      <div>&nbsp;|&nbsp;</div>
      <div>{payment?.formattedAmount}</div>
      {payment.online ? (
        <>
          <div>&nbsp;|&nbsp;</div>
          <div>
            {payment.paymentTypeName} ({payment.cardBrand} *{payment.cardLast4Digits})
          </div>
          <div>&nbsp;|&nbsp;</div>
          <div style={{display: 'flex'}}>
            <div style={{margin: 'auto 0'}}>{payment.processedBy}</div>
            {
            currentStaff?.roleName === 'Owner' || currentStaff?.access?.includes('deleteOnlineRequestPayment') ? (
              <Grid
                item
                onClick={onClickOpenDeletePaymentWindow(payment.id)}
                className={classes.paymentTrashIconBox}
              >
                <TrashButton classes={{ icon: classes.trashButton }} />
              </Grid>
            ) : null
          }
          </div>
        </>
      ) : (
        <>
          <div>&nbsp;|&nbsp;</div>
          {payment?.StripeCardOperation?.ClientCard?.brand
            ? <div>{`Online - ${payment?.StripeCardOperation?.ClientCard?.brand?.toUpperCase()} *** ${payment?.StripeCardOperation?.ClientCard?.last4}`}</div>
            : <div>{payment.paymentTypeName}</div>}
          <div>&nbsp;|&nbsp;</div>
          <div style={{display: 'flex'}}>
            <div style={{margin: 'auto 0'}}>{payment.isOnlinePurchase ? 'Online Payment' : `Processed by ${payment.processedBy}`}</div>
            <Grid
              item
              onClick={onClickOpenDeletePaymentWindow(payment.id)}
              className={classes.paymentTrashIconBox}
            >
              <TrashButton classes={{ icon: classes.trashButton }} />
            </Grid>
          </div>
        </>
      )}

      {/* {
      payment?.online ? (
        currentStaff?.roleName === 'Owner' || currentStaff?.access?.includes('deleteOnlineRequestPayment') ? (
          <Grid
            item
            onClick={onClickOpenDeletePaymentWindow(payment.id)}
            className={classes.paymentTrashIconBox}
          >
            <TrashButton classes={{ icon: classes.trashButton }} />
          </Grid>
        ) : null
      ) : (
        <Grid
          item
          onClick={onClickOpenDeletePaymentWindow(payment.id)}
          className={classes.paymentTrashIconBox}
        >
          <TrashButton classes={{ icon: classes.trashButton }} />
        </Grid>
      )
    } */}
    </Grid>
  )
}

export default PaymentsComponent;