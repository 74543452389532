import { useRef, useEffect } from 'react';
import {
  Grid,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  DialogTitle,
  IconButton,
  Icon,
} from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import Avatar from './Avatar';
import OrangeButton from '../../../shared_components/buttons/Orange';
import { getInitials } from '../../../shared_client_utils/formUtils';
import { composeGCSUrl } from '../../../shared_client_utils/googleCloudStorageUtils';
import OutlinedInput from '../../../shared_components/Input';

const useStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
    padding: "10px 55px",
    maxWidth: "305px",
  },
  header: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  content: {
    padding: 0,
    margin: "0 0 10px 0",
  },
  footer: {
    justifyContent: "center",
    margin: "0 0 24px 0",
  },
  avatarBox: {
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
  },
  bigFirstName: {
    fontSize: "22px",
    fontWeight: '500',
    marginTop: theme.spacing(),
    textAlign: 'center',
  },
  closeButtonBox: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  closeButton: {
    padding: 0,
    margin: "12px",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  password: {
    overflow: "hidden",
    "& input": {
      padding: "10px 14px",
      fontWeight: "normal",
    },
  },
  outlinedInput: {
    marginTop: "5px",
  },
}));

const StaffLoginDialog = (props) => {
  const {
    newStaff: { firstName, lastName, avatarUrl },
    password,
    passwordError,
    handleStaffPassword,
    handleCloseStaffLogin,
    staffLoginLoading,
    handleLoginAsAnotherStaff,
    customerPortalLink,
    newStaff
  } = props;

  const {classes} = useStyles();
  const buttonDisabled = password.length < 8 || staffLoginLoading;

  const passwordInput = useRef(null);
  useEffect(() => passwordInput.current.focus(), []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleLoginAsAnotherStaff();
  };

  const stripeRedirect = () => {
    window.location.href = customerPortalLink
  }

  return (
    <div className={classes.root}>
      <DialogTitle id="staff-login-dialog-title" className={classes.header}>
        <Grid container className={classes.avatarBox}>
          <Avatar
            size="large"
            src={composeGCSUrl(avatarUrl)}
          >
            {getInitials(firstName, lastName)}
          </Avatar>
          <Grid item className={classes.bigFirstName}>{firstName}</Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <FormControl className={classes.password}>
          <form onSubmit={handleFormSubmit} autoComplete="off">
            <OutlinedInput
              required
              fullWidth
              id="password"
              inputRef={passwordInput}
              autoComplete="current-password"
              className={classes.outlinedInput}
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={handleStaffPassword}
            />
          </form>
          <FormHelperText error={!!passwordError}>{passwordError}</FormHelperText>
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.footer}>
        { customerPortalLink && newStaff.isOwner ? (
          <OrangeButton
            disabled={!customerPortalLink}
            variant="contained"
            size="medium"
            sx={{width: '100%', padding: '6px 22px'}}
            onClick={stripeRedirect}
          >
            Details
          </OrangeButton>
        ) : (
          <OrangeButton
            fullWidth
            variant="contained"
            size="large"
            disabled={buttonDisabled || customerPortalLink?.length > 0}
            onClick={handleLoginAsAnotherStaff}
          >
            Log in
          </OrangeButton>

        )}
      </DialogActions>

      <div className={classes.closeButtonBox}>
        <IconButton
          aria-label="Close"
          onClick={handleCloseStaffLogin}
          className={classes.closeButton}
          size="large">
          <Icon>clear</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default StaffLoginDialog;
