/* eslint-disable import/no-unresolved */
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { makeStyles } from 'tss-react/mui';
import AddCardIcon from '@mui/icons-material/AddCard';
import { useState } from 'react';
import DoneIcon from "@mui/icons-material/Done";
import { Typography } from '@mui/material';
import clsx from "clsx";
import OrangeButton from '../buttons/Orange';
import CircularProgressWithBackdrop from '../CircularProgressWithBackdrop';

const useStyles = makeStyles()((theme) => ({
  cardFormBase: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  cardFormSlim: {
    flexDirection: "column",
  },

  cardFormFull: {
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  cardButton: {
    width: "100%",
    marginTop: "1rem",

    [theme.breakpoints.up("md")]: {
      marginTop: "0",
      marginLeft: "1rem",
      maxWidth: "150px",
    },
  },
  cardElement: {
    width: "100%",
    borderBottom: "2px solid #ccc",
    transition: "border-color 0.2s ease-in-out",
    "&:hover": {
      borderBottom: "2px solid orange"
    },
    padding: '10px 0.5rem 10px 0.3rem'

  },

  cardButtonSlim: {
    width: '100%',
    maxWidth: "9.5rem",
    marginTop: "1rem",
    maxHeight: "2.2rem"
  },
}));

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection(props) {
  const {
    creditCardHandler,
    handleDisplayFlashMessage,
    stripeAccountId,
    cardElementOptions,
    parentClasses = {},
    isLoading,
    client,
    user,
    buttonText = 'Add Card',
    title,
    pargraph,
    type = "client",
    // disposition,
    billingBusiness
  } = props;

  const { classes } = useStyles();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const card = elements.getElement(CardElement);
    try {
      const email = type === "client" ? client?.email : user?.email;
      const name = `${type === "client" ? client?.firstName : user?.firstName} ${
        type === "client" ? client?.lastName : user?.lastName}`;
      const phone = type === "client" ? "" : client?.mobileNumber
      const billingDetails = {
        email,
        name,
        phone
      }

      if (billingBusiness) {
        const line1 = billingBusiness?.streetNumber && billingBusiness?.route ? `${billingBusiness?.streetNumber} ${billingBusiness?.route}` : billingBusiness?.address;
        billingDetails.address = {
          city: billingBusiness?.city,
          country: billingBusiness?.countryCode,
          line1,
          postal_code: billingBusiness?.postalCode,
          state: billingBusiness?.provinceCode,
        }
      }

      const payload = await stripe.createPaymentMethod(
        {
          type: "card",
          card,
          billing_details: billingDetails,
        },
        type === "client" ? { stripeAccount: stripeAccountId } : {}
      );
      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
        handleDisplayFlashMessage(error, 'error');
        creditCardHandler({ error: payload.error })
      } else {
        setError(null);
        setSucceeded(true);
        creditCardHandler(payload);
      }
    } catch (error) {
      setError(error.message || error);
      handleDisplayFlashMessage(error.message || error, "error");
    }
    setProcessing(false);
  };

  // const cardFormSlim = clsx(classes.cardFormBase, classes.cardFormSlim);
  // const cardFormFull = clsx(classes.cardFormBase, classes.cardFormFull);

  if (billingBusiness) {
    CARD_ELEMENT_OPTIONS.hidePostalCode = true
  }

  return (
    <form
      id="payment-form"
      required
      onSubmit={handleSubmit}
      className={classes.cardFormBase}
    >
      {
        succeeded ? <Typography className={classes.title}>Payment method was successfully added</Typography> : (
          <CardElement
            title={title}
            pargraph={pargraph}
            required
            disabled={!stripe}
            id="card-element"
            onChange={handleChange}
            className={parentClasses.cardElement || classes.cardElement}
            options={cardElementOptions || CARD_ELEMENT_OPTIONS}
            style={!parentClasses ? {background: 'red'} : {}}
          />
        )
      }
      <OrangeButton
        disabled={processing || disabled || succeeded}
        required
        type="submit"
        // sx={{marginLeft: disposition === "vertical" && "auto"}}
        className={classes.cardButton}
        startIcon={succeeded ? <DoneIcon /> : <AddCardIcon />}
      >
        {/* {buttonText || (disposition === "vertical" ? succeeded ? "Card added" : "Add Card" : "Add Card")} */}
        {buttonText}
      </OrangeButton>
      <CircularProgressWithBackdrop loading={isLoading} />
    </form>
  );
}

export default CardSection;