import { TextField } from '@mui/material';

import { emojiRegex } from '../stringUtils';

const InputBase = ({ rootComponentProps, onChange, ...props }) => {
  const handleChange = (event) => {
    if (!onChange || emojiRegex.test(event.target.value)) return;

    return onChange(event);
  };

  return (
    <TextField
      {...rootComponentProps}
      {...props}
      onChange={handleChange}
    />
  );
};

InputBase.defaultProps = {
  rootComponentProps: {},
}

export default InputBase;
