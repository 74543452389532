export * from './bookings';
export * from './customerAccesses';
export * from './myAppointments';
export * from './clients';
export * from './waivers';
export * from './invoices';
export * from './salesSettings';
export * from './onlinePaymentRequests';
export * from './appointments';
export * from './account';
export * from './addonsSettings';
export * from './mailchimp';

export const rootPath = () => '/';
