import { forwardRef } from 'react';
import { green } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import BaseButton from './Base';

const useStyles = makeStyles()({
  root: {
    color: '#2FD475',
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: green[50],
      // backgroundColor: '#ddf8e8',
    },
  },
  outlined: {
    color: '#2FD475',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.14)',

    '&:hover': {
      backgroundColor: green[50],
    },
  },
  text: {
    color: '#2FD475',
    backgroundColor: '#ffffff',

    '&:hover': {
      backgroundColor: green[50],
    },
  },
});

const WhiteButton = forwardRef((props, ref) => {
  const {classes} = useStyles();

  return (
    <BaseButton
      color="inherit"
      classes={{
        root: classes.root,
        outlined: classes.outlined,
        text: classes.text,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default WhiteButton;
