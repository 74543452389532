import { forwardRef } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    textTransform: 'none',
    fontSize: '15px',
    // minWidth: '100px',
    // boxShadow: "0 2px 4px 0 rgba(0,0,0,0.24)",
  },
  stretched: {
    flex: 1,
  },
}));

const BaseButton = forwardRef((props, ref) => {
  const {
    children,
    variant,
    color,
    size,
    stretched,
    className,
    ...restProps
  } = props;

  const {classes, cx} = useStyles();

  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      className={cx(
        stretched ? classes.stretched : '',
        classes.root,
        className,
      )}
      {...restProps}
      ref={ref}
    >
      {children}
    </Button>
  );
});

BaseButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
  size: 'large',
  stretched: false,
  className: '',
};

export default BaseButton;
