import Link from 'next/link';
import { chunk } from 'lodash';
import {
  Grid,
  Dialog,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import StaffLoginDialog from './StaffLoginDialog';
import Avatar from './Avatar';
import { getInitials } from '../../../shared_client_utils/formUtils';
import { composeGCSUrl } from '../../../shared_client_utils/googleCloudStorageUtils';
import OutlinedInput from '../../../shared_components/Input';

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    width: '310px',
    backgroundColor: '#ffffff',
    padding: '15px 0',
  },
  header: {
    justifyContent: 'center',
    padding: '0 15px',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    alignItems: "center",
    display: "flex",
    padding: "0 8px",
    cursor: "pointer",
    "&:hover": {
      color: "#FF7147",
    },
  },
  avatarBox: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  loginLogoutButtons: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: '10px',
  },
  staffSearchBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid",
    borderColor: theme.palette.primary.main,
    marginTop: '15px',
    padding: "15px 15px 0 15px",
  },
  staffBox: {
    marginTop: '10px',
    padding: '0 15px',
  },
  staffRow: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '100%',
  },
  staffItem: {
    height: "auto",
    width: '85px',
    overflow: "hidden",
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    padding: '0 5px',
    justifyContent: 'center'
  },
  staffMenuLink: {
    flexDirection: "column",
    width: 'inherit',
  },
  switchUser: {
    textTransform: "uppercase",
    fontWeight: "normal",
    lineHeight: "22px",
    color: 'rgba(0, 0, 0, 0.54)',
  },
  searchStaff: {
    marginTop: "15px",
  },
  smallFirstName: {
    fontSize: "16px",
    marginTop: "5px",
    textAlign: "center",
  },
  bigFirstName: {
    fontSize: "22px",
    fontWeight: '500',
    marginTop: theme.spacing(),
    textAlign: 'center',
  },
  role: {
    fontSize: theme.spacing(5/4),
    textTransform: 'uppercase',
    color: grey[700],
  },
  smallCircle: {
    width: "32px",
    height: "32px",
  },
  smallShrinkName: {
    fontSize: "16px",
  },
}));

const AccountMenu = (props) => {
  const {
    handleCloseMenu,
    auth: { currentStaff },
    staff,
    anchorEl,
    staffSearchValue,
    handleOpenStaffLogin,
    handleStaffSearchChange,
    staffSearchResults,
    isOpenLoginDialog,
    handleExitStaffLogin,
    handleOpenAccountSettingsDialog,
  } = props;

  const {classes, cx} = useStyles();

  let staffList = staffSearchResults;
  if (staffSearchValue.length < 2) {
    staffList = Object.values(staff.byId);
  }

  const { firstName, lastName, avatarUrl, roleName } = currentStaff;

  return (
    <div data-testid="accountMenu">
      <Grid container className={classes.mainContainer}>
        <Grid container className={classes.header}>
          <Grid container className={classes.avatarBox}>
            <Avatar
              size="large"
              src={composeGCSUrl(avatarUrl)}
            >
              {getInitials(firstName, lastName)}
            </Avatar>
            <Grid item className={classes.bigFirstName}>
              {firstName}
            </Grid>
            <Grid item className={classes.role}>
              {roleName}
            </Grid>
          </Grid>

          <Grid container className={classes.loginLogoutButtons}>
            <Grid
              item
              onClick={handleOpenAccountSettingsDialog}
            >
              <a className={classes.link}>
                <Typography color="primary" variant="subtitle1">
                  Account
                </Typography>
              </a>
            </Grid>
            <Grid onClick={handleCloseMenu} data-testid="logoutButton">
              <Link href="/logout">
                <a className={classes.link}>
                  <Typography color="primary" variant="subtitle1">
                    Log out
                  </Typography>
                </a>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.staffSearchBar}>
          <Grid item className={classes.switchUser}>Switch user</Grid>
          <OutlinedInput
            fullWidth
            id="searchStaff"
            placeholder="Search"
            className={classes.searchStaff}
            autoComplete="off"
            value={staffSearchValue}
            onChange={handleStaffSearchChange}
          />
        </Grid>

        <Grid container className={classes.staffBox}>
          {staffList.length > 0 && chunk(staffList, 3).map((group, idx) => (
            <Grid key={idx} className={classes.staffRow}>
              {group.map(oneStaff => (
                <Grid
                  key={oneStaff.id}
                  className={classes.staffItem}
                  onClick={handleOpenStaffLogin(oneStaff)}
                >
                  <Grid container className={cx(classes.link, classes.staffMenuLink)}>
                    <Avatar
                      size="small"
                      src={composeGCSUrl(oneStaff.avatarUrl)}
                    >
                      {getInitials(oneStaff.firstName, oneStaff.lastName)}
                    </Avatar>
                    <Grid item className={classes.smallFirstName}>{oneStaff.firstName}</Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Dialog
        open={isOpenLoginDialog}
        aria-labelledby="staff-login-dialog-title"
        TransitionProps={{
          onExited: handleExitStaffLogin
        }}
      >
        <StaffLoginDialog {...props} />
      </Dialog>
    </div>
  );
}

export default AccountMenu;
