export function objectByField(array, field, copyFiled = "full") {
    return array.reduce((prevValue, currValue) => {
        prevValue[currValue[field]] = copyFiled === "full" ? currValue : currValue[copyFiled];
        return prevValue;
    }, {});
}


export function hexToRgb(hex, opacity = '0.2') {
  let c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    c= hex.substring(1).split('');
    if(c.length== 3){
      c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c= '0x'+c.join('');
    return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')}, ${opacity})`;
  }
  throw new Error('Bad Hex');
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const isPageRefreshed = () => {
  return (new Date().getTime() - Number(getCookie('user'))) < 2000
};

