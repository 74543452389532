import { Fragment, useRef } from 'react';
import { cx } from '@emotion/css'
import {
  Grid,
  TableRow,
  TableCell,
} from '@mui/material';

import { PackageIcon, ScissorsIcon } from '../../../../shared_components/icons';
import Tooltip from '../Tooltip';
import theme from '../../../../shared_client_utils/theme';
import DropdownMenu from './DropdownMenu';
import { MenuItem } from '../MobileMenu';
import TrashButton from '../../../../shared_components/buttons/Trash';
import ScissorsButton from '../../../../shared_components/buttons/Scissors';

const canApplyPackage = (serviceId, clientPackages) => {
  return clientPackages.find((pkg) => {
    return pkg.serviceId === serviceId && pkg.availableQty > 0;
  });
};

const AppointmentLine = (props) => {
  const {
    classes,
    onClickOpenDeleteItemWindow,
    isChangeable,
    clientPackages,
    item: { isPackageApplied, isDiscountApplied, ...item },
  } = props;

  const actionMenuRef = useRef(null);

  let discountIconColor = theme.palette.secondary.main;
  if (isPackageApplied) {
    discountIconColor = theme.palette.disabled.main;
  }

  let packageIconColor = 'primary';
  if (isPackageApplied || isDiscountApplied) {
    packageIconColor = 'disabled';
  }

  let packageTooltipMessage = 'Package already applied to this appointment';
  if (isDiscountApplied) {
    packageTooltipMessage = 'You can not apply package to already discounted appointment';
  }

  const onClickOpenItemDiscountWindow = (event) => {
    if (isPackageApplied) {
      return;
    }
    return props.onClickOpenItemDiscountWindow(item.id, actionMenuRef.current);
  }

  const onClickOpenItemPackageWindow = () => {
    if (isDiscountApplied || isPackageApplied) {
      return;
    }
    return props.onClickOpenItemPackageWindow(item, actionMenuRef.current);
  }

  return (
    <TableRow className={classes.bodyRow}>
      <TableCell
        className={cx(
          classes.bodyCell,
          classes.descriptionCell,
        )}
      >
        {item.description}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.quantity}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.formattedPrice}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.taxName}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.formattedTotalPrice}
      </TableCell>

      <TableCell
        className={cx(
          classes.bodyCell,
          classes.actionsCell,
        )}
      >
        {isChangeable && (
          <Grid container className={classes.actionsBox}>
            {(
              isPackageApplied ||
              canApplyPackage(item.serviceId, clientPackages)
            ) ? (
              <Grid container className={classes.visibleIconBox}>
                <DropdownMenu
                  classes={classes}
                  actionMenuRef={actionMenuRef}
                >
                  <MenuItem>
                    <Tooltip
                      open={isPackageApplied}
                      title="You can not apply discount to already packaged appointment"
                    >
                      <Grid
                        container
                        onClick={onClickOpenItemDiscountWindow}
                        className={classes.actionMenuItem}
                      >
                        <Grid
                          container
                          className={cx(
                            classes.visibleIconBox,
                            classes.actionItemIconBox,
                          )}
                        >
                          <ScissorsIcon
                            className={classes.icon}
                            fillColor={discountIconColor}
                          />
                        </Grid>
                        <Grid item>Apply discount</Grid>
                      </Grid>
                    </Tooltip>
                  </MenuItem>

                  <MenuItem>
                    <Tooltip
                      open={isDiscountApplied || isPackageApplied}
                      title={packageTooltipMessage}
                    >
                      <Grid
                        container
                        onClick={onClickOpenItemPackageWindow}
                        className={classes.actionMenuItem}
                      >
                        <Grid
                          container
                          className={cx(
                            classes.visibleIconBox,
                            classes.actionItemIconBox,
                          )}
                        >
                          <PackageIcon
                            className={classes.icon}
                            color={packageIconColor}
                          />
                        </Grid>
                        <Grid item>Apply package</Grid>
                      </Grid>
                    </Tooltip>
                  </MenuItem>
                </DropdownMenu>
              </Grid>
            ) : (
              <Grid
                container
                onClick={(event) => {
                  return props.onClickOpenItemDiscountWindow(
                    item.id,
                    event.target,
                  );
                }}
                className={classes.visibleIconBox}
              >
                <ScissorsButton />
              </Grid>
            )}

            <Grid
              container
              onClick={onClickOpenDeleteItemWindow(item.id)}
              className={classes.visibleIconBox}
            >
              <TrashButton />
            </Grid>
          </Grid>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AppointmentLine;
