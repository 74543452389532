/* eslint-disable no-prototype-builtins */
/* eslint-disable default-case */
/* eslint-disable react/no-array-index-key */
import { isEmpty, orderBy } from 'lodash';
import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dinero from 'dinero.js';
import moment from 'moment-timezone';
import {
  Grid,
  Box,
  FormControl,
  RadioGroup,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { orange, grey } from '@mui/material/colors';
import { isMobile } from 'react-device-detect';

import AddCardIcon from '@mui/icons-material/AddCard';
import CreateIcon from '@mui/icons-material/Create';
import CustomDialog, {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../../shared_components/CustomDialog';

import DatePicker from '../../../../shared_components/DatePickerInput';
import GreenButton from '../../../../shared_components/buttons/Green';
import { prepareOptions } from '../../../../shared_client_utils/formUtils';
import { formatMoney } from '../../../../shared_client_utils/moneyUtils';
import { loadPaymentTypes } from '../../slices/paymentTypesSlice';
import MoneyInput from '../MoneyInput';
import InputLabel from '../InputLabel';
import Radio from '../../../../shared_components/Radio';
import { OrangeInfoStrip } from '../../../../shared_components/InfoStrip';
import { getFullName } from '../../utils/personUtils';
import OutlinedInput from '../../../../shared_components/Input';
import CircularProgressWithBackdrop from '../../../../shared_components/CircularProgressWithBackdrop';
import { readClientCreditCards, verifyClientCreditCard, createClientCreditCard, paymentWithClientCreditCard } from '../../../../shared_slices/paymentSlice';
import { loadSalesSettings } from '../../slices/salesSettingsSlice';
import Card from '../../../../shared_components/Payment/Payment';
import ConfirmDialog from '../ConfirmDialog';
import RedButton from '../../../../shared_components/buttons/Red';
import GreyButton from '../../../../shared_components/buttons/Grey';

const useStyles = makeStyles()((theme) => ({
  invoiceInfoTitle: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '5px',
  },
  inputRoot: {
    width: '40%',
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  paymentTypes: {
    marginTop: theme.spacing(3),
  },
  paymentInfo: {
    marginTop: theme.spacing(2),
  },
  extraInfo: {
    marginTop: theme.spacing(2),
    width: '50%',
  },
  gridLine: {
    display: 'grid',
    gridAutoRows: '40px',
    gap: 1,
    width: '50%',
  },
  gridMain: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  contentLine: {
    marginBottom: '10px',
    marginLeft: '3px',

    '&:last-of-type': {
      marginBottom: 0,
    },

    '& > div': {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        lineHeight: '14px',
      },
    },
  },
  inputBox: {
    paddingLeft: 0,
    width: '130px',
  },
  button: {
    minWidth: '100px',
  },
  createIcon: {
    color: grey[500],
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      color: '#000',
    },
  },
  addCardIcon: {
    color: orange[500],
    marginLeft: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: '.6',
    },
  },
  radioWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
    '& > label': {
      cursor: 'pointer !important',
      '&:hover': {
        opacity: '.6',
        cursor: 'pointer',
      },
    },
  },
  redButton: {
    minWidth: '100px',
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  confirmTitle: {
    borderLeft: '0.3rem solid',
    color: orange[600],
    background: orange[100],
    padding: '0.1rem 0.1rem 0.1rem 0.5rem'
  },
  confirmBox: {
    flexDirection: 'column',
    padding: theme.spacing(2, 1, 0, 1),
  },
  error: {
    color: theme.palette.error.main,
  },
  confirmInput: {
    marginTop: theme.spacing(),
  },
}));

const preparePayment = (options) => {
  const {
    invoiceId,
    paymentType,
    paymentAmount,
    paymentDate,
    reference,
    staff,
    clientCard,
    businessId,
    client
  } = options;

  const staffName = getFullName(staff.firstName, staff.lastName);

  return {
    invoiceId,
    reference,
    staffName,
    businessId,
    client,
    clientCard,
    paymentTypeId: paymentType.id,
    paymentTypeName: paymentType.name,
    amount: paymentAmount.getAmount(),
    currency: paymentAmount.getCurrency(),
    datetime: paymentDate.toISOString(),
    processedBy: staffName,
  };
};

const ApplyPayment = (props) => {
  const {
    onClose,
    invoice,
    paymentTypes,
    handleAddPayment,
    currency,
    outstandingAmount,
    business: { timezone },
    auth: { currentStaff },
    handleDisplayFlashMessage,
    client,
    loadPaymentTypes,
    getClientCards,
    verifyClientCard,
    salesSettings,
    createCreditCard,
    serviceCardPayment,
  } = props;
  const {classes, cx} = useStyles();

  const [isLoading, setLoading] = useState(true);

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [clientCard, setClientCard] = useState({});
  const [isAddDialogOpened, setAddDialogOpened] = useState(false);

  const preparedPaymentTypes = prepareOptions(paymentTypes.byId || []);
  const [selectedTypeId, setSelectedTypeId] = useState('');

  const [confirmInput, setConfirmInput] = useState('');
  const [isConfirmInputError, setConfirmInputError] = useState(false);

  const setConfirmInputValue = (value) => {
    setConfirmInput(value)
    if (value.toLowerCase() === 'confirm') {
      setConfirmInputError(false);
    }
  }

  const setIsConfirmDialogOpen = (key) => {
    setConfirmDialogOpen(key)
    setConfirmInput('')
  }

  const handleCloseCreditCardForm = () => {
    setAddDialogOpened(false)
  }

  const creditCardHandler = async (stripeResponse) => {
    if(stripeResponse.hasOwnProperty('paymentMethod') && client.id !== '') {
      setAddDialogOpened(false)
      const { paymentMethod, paymentMethod: { card } } = stripeResponse
      setLoading(true)
      try {
        const cardVerify = await verifyClientCard(
          {
            clientId: client.id,
            name: `${client.firstName} ${client.lastName}`,
            email: client.email,
            phone: client.mobileNumber,
            source: paymentMethod.id,
            businessId: salesSettings?.BusinessId
          });
        setLoading(false);

        if(cardVerify?.payload.status === 'succeeded') {
          setLoading(true)
          const createClientCardData = {
            token: paymentMethod.id,
            clientId: client.id,
            brand: card.brand,
            customerId: cardVerify.payload.customerId,
            businessId: salesSettings?.BusinessId,
            country: card.country,
            expMonth: card.exp_month,
            expYear: card.exp_year,
            funding: card.funding,
            last4: card.last4,
          };

          await createCreditCard(createClientCardData);
          const { payload: { rows } } = await getClientCards(client.id)
          rows[0].name = `${rows[0].brand} *** ${rows[0].last4}`
          setClientCard(...rows)
          handleDisplayFlashMessage('Payment method has been successfully added');
          setLoading(false);
        } else {
          setLoading(false);
          setAddDialogOpened(false)
          handleDisplayFlashMessage(`Adding payment method ${cardVerify?.payload.code}`, 'error');
        }
      } catch (error) {
        setLoading(false);
        if (error?.message?.includes('Session is expired')) {
          handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
        } else {
          handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
        }
      }
    }
  }

  useEffect(() => {
    const fetchPaymentTypes = async () => {
      setLoading(true);
      try {
        // const { payload: [firstType] } =
        await loadPaymentTypes();
        // if (firstType) {
        //   setSelectedTypeId(firstType.id);
        setLoading(false);
        // }
      } catch (error) {
        if (error?.message?.includes('Session is expired')) {
          handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
        } else {
          handleDisplayFlashMessage(error.message || error, 'error')
        }
        setLoading(false);
      }
    }
    fetchPaymentTypes();

    const fetchClientCards = async () => {
      try {
        setLoading(true);
        const { payload: { count, rows } } = await getClientCards(client?.id);
        if(count > 0) {
          rows[0].name = `${rows[0].brand} *** ${rows[0].last4}`
          setClientCard(...rows)
          setLoading(false);
        }
      } catch (error) {
        if (error?.message?.includes('Session is expired')) {
          handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
        } else {
          handleDisplayFlashMessage(error.message || error, 'error')
        }
        setLoading(false);
      }
    }
    fetchClientCards();
  }, []);

  const [paymentAmount, setPaymentAmount] = useState(() => {
    return formatMoney(outstandingAmount);
  });
  const [moneyAmount, setMoneyAmount] = useState(() => {
    return Dinero(outstandingAmount.toJSON());
  });
  const handleChangePaymentAmount = (value, money) => {
    setPaymentAmount(value);
    setMoneyAmount(money);
  };

  const [paymentDate, setPaymentDate] = useState(() => {
    return moment.tz(timezone);
  });

  const handleChangeDate = (value) => {
    const newDate = moment.tz(value, timezone);
    setPaymentDate(newDate);
  }

  const [reference, setReference] = useState('');

  const handleSavePayment = async () => {
    const selectedType = preparedPaymentTypes.find(type => type.id === selectedTypeId);
    const isCreditCard = selectedType.name === 'Credit card';
    if (moneyAmount.isZero() || moneyAmount.isNegative()) {
      handleDisplayFlashMessage('Payment amount should be greater than zero', 'error');
      return;
    }
    if (isCreditCard && confirmInput.toLowerCase() !== 'confirm') {
      setConfirmInputError(true);
      return;
    }

    if(isCreditCard) {
      setLoading(true)
      try {
        const { payload } = await serviceCardPayment({
          invoiceId: invoice.id,
          businessId: salesSettings?.BusinessId,
          client,
          clientCard: clientCard.id,
          paymentTypeId: selectedType?.id,
          paymentTypeName: selectedType.name,
          amount: moneyAmount.getAmount(),
          currency: invoice.currency,
        });

        if(payload.status === 'succeeded') {
          handleDisplayFlashMessage('Payment was succeeded');
          setLoading(false)
          const payment = preparePayment({
            reference,
            paymentDate,
            paymentAmount: moneyAmount,
            paymentType: selectedType,
            invoiceId: invoice.id,
            staff: currentStaff,
          });
          payment.stripeCardOperationId = payload.id
          payment.StripeCardOperation = { ClientCard: {brand: payload?.brand, last4: payload?.last4 }}

          onClose()
          return handleAddPayment(payment);
        }else{
          handleDisplayFlashMessage('Something went wrong', 'error')
          setIsConfirmDialogOpen(false)
          setLoading(false)
        }
      } catch (error) {
        handleDisplayFlashMessage(error.message || error, 'error')
        setIsConfirmDialogOpen(false)
        setLoading(false)
      }
    }else{
      const payment = preparePayment({
        reference,
        paymentDate,
        paymentAmount: moneyAmount,
        paymentType: selectedType,
        invoiceId: invoice.id,
        staff: currentStaff,
      });

      return handleAddPayment(payment);
    }
  }

  const handleConfirmPayment = async () => {
    const selectedType = preparedPaymentTypes.find(type => type.id === selectedTypeId);

    if (moneyAmount.isZero() || moneyAmount.isNegative()) {
      handleDisplayFlashMessage('Payment amount should be greater than zero', 'error');
      return;
    }
    if(selectedType.name === 'Credit card') {
      setIsConfirmDialogOpen(true)
    }else{
      await handleSavePayment()
    }
  }

  const sortedPaymentTypes = orderBy(preparedPaymentTypes, 'order')

  return (
    <>
      <CustomDialogTitle onClose={onClose}>
        Apply payment to invoice
      </CustomDialogTitle>

      <CustomDialogContent>
        <OrangeInfoStrip>
          <Grid item className={classes.invoiceInfoTitle}>
            Invoice {invoice.formattedInvoiceNumber}
          </Grid>
          <Grid item>
            The invoice total is {invoice.totalPrice.toFormat()} and amount outstanding is {outstandingAmount.toFormat()}
          </Grid>
        </OrangeInfoStrip>

        <FormControl component="fieldset" className={classes.paymentTypes}>
          <InputLabel
            shrink
            htmlFor="paymentTypes"
            className={classes.label}
            focused={false}
          >
            Choose payment type
          </InputLabel>
          <RadioGroup
            aria-label="paymentTypes"
            name="paymentTypes"
            value={selectedTypeId}
            style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}
            onChange={({ target: { value }}) => setSelectedTypeId(value)}
          >
            { sortedPaymentTypes.map(item => {
              if (item.name === 'Credit card') {
                return (
                  <div
                    key={`group_${item.id}`}
                    className={cx(
                      classes.gridLine,
                    )}
                  >
                    {
                      !salesSettings.stripeConnected ? (
                        null
                      ) : (
                        <Box
                          className={classes.radioWrapper}
                        >
                          <Radio
                            disabled={item.name === 'Credit card' && isEmpty(clientCard?.name)}
                            value={item.id}
                            label={(
                              <Grid
                                onClick={() => item?.name === 'Credit card' && isEmpty(clientCard?.name) ? setAddDialogOpened(true) : null}
                              >{
                                item?.name === 'Credit card'
                                  ? clientCard?.name !== undefined
                                    ? clientCard?.name?.toUpperCase()
                                    : `${isMobile ? 'Add' : 'Add credit card'}` : item?.name
                                }
                              </Grid>
                            )}
                          />
                          {item?.name === 'Credit card'
                            ? clientCard?.name !== undefined
                              ? <CreateIcon className={classes.createIcon} onClick={() => setAddDialogOpened(true)} fontSize="small"/>
                              : client?.id !== '' && <AddCardIcon className={classes.addCardIcon} onClick={() => setAddDialogOpened(true)} fontSize="small"/>
                            : <> </>}
                        </Box>
                      )
                    }
                  </div>
                )
              } else {
                return (
                  <div
                    key={`groupx_${item.id}`}
                    className={cx(
                      classes.gridLine,
                    )}
                  >
                    <Box className={classes.radioWrapper}>
                      <Radio
                        value={item.id}
                        label={item?.name}
                      />
                    </Box>
                  </div>
                )
              }
            })}
          </RadioGroup>
        </FormControl>
        <div className={cx(
          classes.paymentInfo,
          classes.gridRow,
        )}
        >
          <FormControl>
            <InputLabel
              shrink
              htmlFor="amount"
              className={classes.label}
              focused={false}
            >
              Payment amount
            </InputLabel>
            <MoneyInput
              id="amount"
              name="amount"
              value={paymentAmount}
              currency={currency}
              onChange={handleChangePaymentAmount}
              className={classes.inputBox}
            />
          </FormControl>
          {
            currentStaff?.roleName === 'Owner' || currentStaff?.access?.includes('changePaymentDate') ? (
              <FormControl>
                <InputLabel
                  shrink
                  htmlFor="paymentDate"
                  className={classes.label}
                  focused={false}
                >
                  Payment date
                </InputLabel>
                <DatePicker
                  showYearDropdown
                  id="paymentDate"
                  selected={paymentDate.toDate()}
                  onChange={handleChangeDate}
                  className={classes.inputBox}
                />
              </FormControl>
            ) : <> </>
          }
        </div>
        <FormControl className={classes.extraInfo}>
          <InputLabel
            shrink
            htmlFor="reference"
            className={classes.label}
            focused={false}
          >
            Reference
          </InputLabel>
          <OutlinedInput
            id="reference"
            name="reference"
            value={reference}
            onChange={({ target: { value }}) => setReference(value)}
          />
        </FormControl>
      </CustomDialogContent>

      <ConfirmDialog
        isLoading={isLoading}
        title={<p className={classes.confirmTitle}>Do you confirm this transaction ?</p>}
        descriptionWrapperStyle={{paddingBottom: 0}}
        description={(
          <p>The amount of
            <b style={{color: orange[600], padding: '0.2rem'}}>
              {moneyAmount?.toFormat()}
            </b>
            will be charged to the credit card on file.  Do you confirm this transaction?
          </p>
        )}
        open={isConfirmDialogOpen}
      >
        <Grid container className={classes.confirmBox}>
          <CustomDialogContent>
            <Grid
              item
              className={isConfirmInputError ? classes.error : ''}
            >
              Please type CONFIRM word (case insensitive).
            </Grid>
            <Grid item>
              <OutlinedInput
                id="confirmInput"
                name="confirmInput"
                value={confirmInput || ''}
                onChange={({ target: { value }}) => setConfirmInputValue(value)}
                className={classes.confirmInput}
                error={isConfirmInputError}
              />
            </Grid>
          </CustomDialogContent>
          <CustomDialogActions>
            <GreenButton
              variant="contained"
              size="small"
              onClick={handleSavePayment}
            >
              Confirm
            </GreenButton>
            <GreyButton
              variant="contained"
              size="small"
              onClick={() => setIsConfirmDialogOpen(false)}
            >
              Cancel
            </GreyButton>
          </CustomDialogActions>
        </Grid>
      </ConfirmDialog>

      <CustomDialog
        fullWidth
        placement="top"
        open={isAddDialogOpened}
        onClose={handleCloseCreditCardForm}
      >
        <CustomDialogTitle onClose={handleCloseCreditCardForm}>
          Add/Replace Credit card
        </CustomDialogTitle>
        <Card
          {...props}
          title="Enter credit card details."
          pargraph="This card will be securely stored with Stripe and used as per our policy."
          onClose={handleCloseCreditCardForm}
          creditCardHandler={creditCardHandler}
        />
      </CustomDialog>

      <CustomDialogActions>
        <GreenButton
          variant="contained"
          size="small"
          onClick={handleConfirmPayment}
          className={classes.button}
          disabled={!selectedTypeId?.length}
        >
          Save
        </GreenButton>
        <RedButton
          variant="contained"
          size="small"
          onClick={onClose}
          className={classes.redButton}
        >
          Cancel
        </RedButton>
      </CustomDialogActions>
      <CircularProgressWithBackdrop loading={isLoading} />
    </>
  );
};

const mapStateToProps = ({ paymentTypes }) => ({ paymentTypes });

const mapDispatchToProps = dispatch => ({
  getClientCards: bindActionCreators(readClientCreditCards, dispatch),
  serviceCardPayment: bindActionCreators(paymentWithClientCreditCard, dispatch),
  loadPaymentTypes: bindActionCreators(loadPaymentTypes, dispatch),
  createCreditCard: bindActionCreators(createClientCreditCard, dispatch),
  loadSalesSettings: bindActionCreators(loadSalesSettings, dispatch),
  verifyClientCard: bindActionCreators(verifyClientCreditCard, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyPayment);
