import { makeStyles } from 'tss-react/mui';

import BaseIconButton from './BaseIcon';
import { CloseIcon } from '../icons';

const useStyles = makeStyles()((theme) => ({
  icon: {
    width: theme.spacing(10/4),
    height: theme.spacing(10/4),
  },
}));

const CloseButton = ({ classes: parentClasses, ...props }) => {
  const {classes} = useStyles();

  return (
    <BaseIconButton
      {...props}
      classes={{
        icon: classes.icon,
        ...parentClasses
      }}
      component={CloseIcon}
    />
  );
};

CloseButton.defaultProps = {
  classes: {},
};

export default CloseButton;
