import { useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import PhoneInput from '../../../../shared_components/PhoneInput';
import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import { validateObjectByMap } from '../../../../shared_client_utils/formUtils';
import OutlinedInput from '../../../../shared_components/Input';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
  },
  inputs: {
    flexDirection: 'column',

    '& > div': {
      marginBottom: theme.spacing(2),
    },

    '& > div:last-of-type': {
      marginBottom: 0,
    },
  },
  buttonBox: {
    marginTop: theme.spacing(15 / 8),
    justifyContent: 'flex-end',
  },
  addButton: {
    marginRight: theme.spacing(2),
  },
}));

const ClientForm = (props) => {
  const {
    onClose,
    client,
    handleAddClient,
    clientsErrorMap,
  } = props;

  const {classes} = useStyles();

  const [firstName, setFirstName] = useState(client.firstName || '');
  const [lastName, setLastName] = useState(client.lastName || '');
  const [mobileNumber, setMobileNumber] = useState(client.mobileNumber || '');
  const [email, setEmail] = useState(client.email || '');

  const [clientErrors, setClientErrors] = useState({});

  const onAdd = () => {
    const newClient = {
      firstName,
      lastName,
      mobileNumber,
      email,
      fullName: `${firstName} ${lastName}`,
    };
    const { isValid, errors } = validateObjectByMap(newClient, clientsErrorMap);
    if (!isValid) {
      setClientErrors(errors);
      return;
    }
    setClientErrors({});

    handleAddClient(newClient);
  }

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.inputs}>
        <OutlinedInput
          required
          name="firstName"
          label="First Name"
          value={firstName}
          onChange={({ target }) => setFirstName(target.value)}
          error={clientErrors.firstName}
        />
        <OutlinedInput
          required
          name="lastName"
          label="Last Name"
          value={lastName}
          onChange={({ target }) => setLastName(target.value)}
          error={clientErrors.lastName}
        />
        <OutlinedInput
          required
          name="email"
          label="Email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          error={clientErrors.email}
        />
        <PhoneInput
          required
          name="mobileNumber"
          label="Mobile Number"
          value={mobileNumber}
          onChange={value => setMobileNumber(value)}
          error={clientErrors.mobileNumber}
        />
      </Grid>

      <Grid container className={classes.buttonBox}>
        <GreenButton
          variant="contained"
          size="small"
          onClick={onAdd}
          className={classes.addButton}
        >
          Add
        </GreenButton>
        <GreyButton
          variant="contained"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </Grid>
    </Grid>
  );
};

export default ClientForm;
