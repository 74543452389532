import { Radio as RadioOrigin, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  formControlLabelRoot: {
    marginLeft: 0,
    marginRight: 0,
    alignItems: 'flex-start',
  },
  radioRoot: {
    padding: 0,
    backgroundColor: '#ffffff',
  },
  labeledRadio: {
    marginRight: theme.spacing(),
  },
}));

const Radio = ({ label, className, error, ...props }) => {
  const {classes} = useStyles();

  return (
    <FormControlLabel
      control={
        <RadioOrigin
          disableRipple
          color="primary"
          classes={{
            root: classes.radioRoot,
          }}
          className={label ? classes.labeledRadio : ''}
          {...props}
        />
      }
      label={label}
      className={className}
      classes={{
        root: classes.formControlLabelRoot,
      }}
    />
  );
};

Radio.defaultProps = {
  label: '',
  className: '',
  disabled: false,
};

export default Radio;
