import { Fragment } from 'react';
import { cx } from '@emotion/css'
import _ from 'lodash';
import {
  Grid,
  TableRow,
  TableCell,
} from '@mui/material';

import NaturalNumberInput from '../NaturalNumberInput';
import MoneyInput from '../MoneyInput';
import Select from '../Select';
import TrashButton from '../../../../shared_components/buttons/Trash';
import ScissorsButton from '../../../../shared_components/buttons/Scissors';

const ProductLine = (props) => {
  const {
    item,
    classes,
    auth,
    onClickOpenItemDiscountWindow,
    onClickOpenDeleteItemWindow,
    handleAddProduct,
    handleQuantityChange,
    handleUnitPriceChange,
    products,
    isChangeable,
  } = props;

  const selectedProduct = products.find(({ value }) => value === item.id);

  const isChangeableField = products.find(({ id, isChangeUnitPrice }) => id === item?.productId && isChangeUnitPrice === true)

  return (
    <TableRow className={classes.bodyRow}>
      <TableCell
        className={cx(
          classes.bodyCell,
          classes.descriptionCell,
        )}
      >
        {(isChangeable && _.some(['initial', 'new'], (st) => (st === item.status))) ? (
          <Select
            name="product"
            options={products}
            value={selectedProduct}
            onChange={handleAddProduct(item.id)}
            groupBy={(option) => option.productCategoryName}
            className={classes.fullWidthSelect}
          />
        ) : (
          item.description
        )}
      </TableCell>

      {(item.status === 'initial' || !isChangeable) ? (
        <TableCell className={classes.bodyCell}>
          {item.quantity}
        </TableCell>
      ) : (
        <TableCell className={classes.bodyCell}>
          <NaturalNumberInput
            value={item.quantity}
            onChange={({ target }) => {
              return handleQuantityChange(item.id, target.value);
            }}
          />
        </TableCell>
      )}

      <TableCell className={classes.bodyCell}>
        {isChangeableField !== undefined && isChangeableField?.id === item?.productId ? (
          <MoneyInput
            id="unitPrice"
            name="unitPrice"
            value={item.userFriendlyValue === undefined ? item.formattedPrice : item.userFriendlyValue}
            currency={item.currency}
            onChange={value => handleUnitPriceChange(item.id, value)}
            className={classes.middleWidthInput}
          />
        ) : item.formattedPrice}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.taxName}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.formattedTotalPrice}
      </TableCell>

      <TableCell
        className={cx(
          classes.bodyCell,
          classes.actionsCell,
        )}
      >
        {isChangeable && (
          <Grid container className={classes.actionsBox}>
            <Grid
              container
              onClick={(event) => {
                return onClickOpenItemDiscountWindow(
                  item.id,
                  event.target,
                );
              }}
              className={classes.visibleIconBox}
            >
              <ScissorsButton />
            </Grid>

            <Grid
              container
              onClick={onClickOpenDeleteItemWindow(item.id)}
              className={classes.visibleIconBox}
            >
              <TrashButton />
            </Grid>
          </Grid>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ProductLine;
