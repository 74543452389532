import { forwardRef } from 'react';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import BaseButton from './Base';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#D8D8D8',
    color: '#ffffff',

    '&:hover': {
      backgroundColor: grey[400],
    },
  },
  outlined: {
    backgroundColor: '#ffffff',
    color: '#929191',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.14)',

    '&:hover': {
      backgroundColor: grey[200],
    },
  },
  text: {
    backgroundColor: '#ffffff',
    color: '#929191',

    '&:hover': {
      backgroundColor: grey[200],
    },
  },
});

const GreyButton = forwardRef((props, ref) => {
  const {classes} = useStyles();

  return (
    <BaseButton
      color="inherit"
      classes={{
        root: classes.root,
        outlined: classes.outlined,
        text: classes.text,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default GreyButton;
