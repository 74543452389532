import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Dinero from 'dinero.js';
import { InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { formatMoney } from '../../../shared_client_utils/moneyUtils';
import OutlinedInput from '../../../shared_components/Input';

const useStyles = makeStyles()((theme) => ({
  inputAdorment: {
    height: '100%',
    maxHeight: 'none',
    padding: theme.spacing(1/8, 0, 1/8, 1/8),

    "& p": {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: theme.spacing(3),
      height: '100%',
      // background: '#D8D8D8',
      borderRadius: '3px 0 0 3px'
    },
  },
  adornedStart: {
    paddingLeft: 0,
  },
}));

// Value should only contain digits and single dot
const isMoneyValid = (value) => {
  return !value.match(/[^0-9.]/) && value.split('.').length <= 2;
};

const MoneyInput = ({ value, onChange, currency, ...props}) => {
  const {classes} = useStyles();

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (!isMoneyValid(newValue)) return;

    onChange(newValue);
  };

  const handleFinalCalculation = () => {
    const decimal = value.split('.')[1];
    // increase actual precision by 1 to avoid rounding bug for 70.60 and 80.60 numbers
    // https://github.com/Balance-Development/HiveManager/issues/124
    const precision = decimal ? (decimal.length + 1) : 0;
    const amount = parseInt(Number(value) * Math.pow(10, precision));

    const money = Dinero({ currency, amount, precision });
    const resultMoney = money.convertPrecision(2);
    const newValue = formatMoney(resultMoney);

    onChange(newValue, resultMoney);
  };

  return (
    <OutlinedInput
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleFinalCalculation}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            classes={{
              root: classes.inputAdorment,
            }}
          >
            $
          </InputAdornment>
        ),
        classes: {
          adornedStart: classes.adornedStart,
        },
      }}
    />
  );
}

MoneyInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  currency: PropTypes.string,
};

export default MoneyInput;
