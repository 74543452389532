import { useState, useEffect } from 'react';
import Dinero from 'dinero.js';
import moment from 'moment';
import {
  Grid,
  Typography,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import MoneyInput from '../MoneyInput';
import { formatMoney } from '../../../../shared_client_utils/moneyUtils';
import { CreditsApi } from '../../../../client_http_api';
import { calculateCreditBalance } from '../../../../shared_client_utils/invoiceUtils';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    minWidth: theme.spacing(41),
  },
  inputBox: {
    marginTop: theme.spacing(),
  },
  usedAmountBox: {
    marginTop: theme.spacing(6/4),
    alignItems: 'center',
  },
  usedAmount: {
    width: theme.spacing(13),
  },
  balance: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
  },
  buttonBox: {
    marginTop: theme.spacing(15/8),
    justifyContent: 'space-between',
  },
  formHelperText: {
    color:'red'
  },
  backdrop: {
    borderRadius: '4px',
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const initializeCreditBalance = (currency) => Dinero({
  currency,
  amount: 0,
});

const RedeemCredit = (props) => {
  const {
    onClose,
    handleSaveChanges,
    invoice: { currency, items, clientId, amountDue },
  } = props;

  const {classes} = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const [creditsBalance, setCreditsBalance] = useState(() => {
    return initializeCreditBalance(currency);
  });
  const [usedAmount, setUsedAmount] = useState({ value: '' });
  const [usedAmountError, setUsedAmountError] = useState('');
  const handleChangeUsedAmount = (value, money) => {
    setUsedAmount({ value, money });
    if (usedAmountError) setUsedAmountError('');
  }

  useEffect(() => {
    const loadClientCredits = async () => {
      const creditBalance = await calculateCreditBalance(
        clientId,
        currency,
        items,
        props.auth,
      );
      const creditBalanceMoney = Dinero({ currency, amount: creditBalance });

      setCreditsBalance(creditBalanceMoney);

      let usedBalance;
      if (amountDue.greaterThanOrEqual(creditBalanceMoney)) {
        usedBalance = creditBalanceMoney;
      } else {
        usedBalance = amountDue;
      }

      setUsedAmount({
        value: formatMoney(usedBalance),
        money: Dinero(usedBalance.toJSON()),
      });
      setIsLoading(false);
    }

    loadClientCredits();
  }, []);

  const handleApplyClientCredit = () => {
    if (usedAmount.money.greaterThan(creditsBalance)) {
      setUsedAmountError('Amount must be less or equal to overall credit');
      return;
    }

    const amount = usedAmount.money.getAmount();
    return handleSaveChanges(amount);
  }

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography color="secondary" variant="subtitle1">
          Apply credit
        </Typography>
      </Grid>
      <Grid container className={classes.usedAmountBox}>
        <Grid item>Use&nbsp;</Grid>
        <Grid item>
          <MoneyInput
            id="usedAmount"
            name="usedAmount"
            value={usedAmount.value}
            currency={currency}
            onChange={handleChangeUsedAmount}
            className={classes.usedAmount}
          />
        </Grid>
        <Grid item>&nbsp;out of&nbsp;</Grid>
        <Grid item>
          <span className={classes.balance}>
            {creditsBalance.toFormat()}
          </span>
        </Grid>
      </Grid>
      {usedAmountError && (
        <FormHelperText className={classes.formHelperText}>
          {usedAmountError}
        </FormHelperText>
      )}
      <Grid container className={classes.buttonBox}>
        <GreenButton
          variant="contained"
          size="small"
          onClick={handleApplyClientCredit}
        >
          Apply
        </GreenButton>
        <GreyButton
          variant="text"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </Grid>

      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Grid>
  );
};

export default RedeemCredit;
