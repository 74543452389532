export const clientPath = (id) => `/clients/${id}`;

export const newClientPath = () => '/clients/new';

export const editClientPath = (id) => `/clients/${id}/edit`;

export const salesClientPath = (id) => {
  const mainPath = clientPath(id);

  return `${mainPath}?tab=sales`;
};
