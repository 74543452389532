import { Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
  },
  badge: {
    position: "absolute",
    bottom: "-1px",
    right: 0,
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#fff",
    backgroundColor: "#808190",
  },
  extraLarge: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const greenColor = "#2DC76D";
const Badge = ({ className, badgeStatus = Math.floor(Math.random() * 2) }) => {
  const currentColor = badgeStatus ? greenColor : null;
  return (
    <div className={className} style={{ backgroundColor: currentColor }} />
  );
};

const CustomAvatar = ({ children, showBadge, badgeStatus, size, ...props }) => {
  const {classes} = useStyles();

  return (
    <div className={classes.root}>
      <Avatar
        {...props}
        classes={{
          root: classes[size],
        }}
      >
        {children}
      </Avatar>
      {showBadge && (
        <Badge
          className={classes.badge}
          badgeStatus={badgeStatus}
        />
      )}
    </div>
  );
};

CustomAvatar.defaultProps = {
  showBadge: false,
  size: 'medium',
};

export default CustomAvatar;
