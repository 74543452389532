import { Grid, TableCell } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    borderBottom: 'none',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(),
    },
  },
  iconCell: {
    width: theme.spacing(4),
    paddingRight: 0,
  },
  grid: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SearchCell = ({ children, iconCell, ...props }) => {
  const {classes} = useStyles();

  if (iconCell) {
    return (
      <TableCell
        {...props}
        classes={classes}
        className={classes.iconCell}
      >
        <Grid container className={classes.grid}>
          {children}
        </Grid>
      </TableCell>
    );
  }

  return (
    <TableCell {...props} children={children} classes={classes} />
  );
};

SearchCell.defaultProps = {
  iconCell: false,
};

export default SearchCell;
