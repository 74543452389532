export const bookingPath = (slug) => `/book/${slug}`;

export const newBookingUrl = (slug) => {
  return `${window.location.origin}${bookingPath(slug)}`;
};

export const editBookingPath = (id, token, fromId) => {
  let query = `token=${token}`;
  if (fromId) {
    query += `&fromId=${fromId}`;
  }

  return `/bookings/${id}?${query}`;
};

export const cancelBookingPath = (id, token, fromId) => {
  let query = `token=${token}`;
  if (fromId) {
    query += `&fromId=${fromId}`;
  }

  return `/bookings/${id}/cancel?${query}`;
};

export const completeCancelBookingPath = (id, token, fromId) => {
  let query = `token=${token}`;
  if (fromId) {
    query += `&fromId=${fromId}`;
  }

  return `/bookings/${id}/cancel/confirmed?${query}`;
};
