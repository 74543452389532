import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: 'column',
    width: 'auto',
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    borderLeft: `2px solid ${theme.palette.error.main}`,
    padding: '10px',
  },
}));

const Red = ({ children, className }) => {
  const {classes} = useStyles();

  return (
    <Grid
      container
      className={className}
      classes={{
        root: classes.root,
      }}
    >
      {children}
    </Grid>
  );
};

Red.defaultProps = {
  className: '',
};

export default Red;
