import { hexToRgb } from './functions';

export const buildRGBforBackgroundColor = (hex) => hexToRgb(hex, '0.4');

export const buildRGBforPaddingColor = (hex) => hexToRgb(hex, '0.2');

const removeStaffAttr = ({ staff, ...query }) => query;
export const prepareQueryOptions = (options) => {
  const { selectedStaffId, staff, routerQuery, ...restOptions } = options;

  if (selectedStaffId) {
    return { ...routerQuery, ...restOptions, staff };
  }

  const query = removeStaffAttr(routerQuery);
  return { ...query, ...restOptions };
};

export const getQueryAttrsFromBrowser = () => {
  try {
    const stringData = localStorage.getItem('calendarQueryAttrs');
    return stringData ? JSON.parse(stringData) : null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const keepQueryAttrsInBrowser = (queryAttrs) => {
  const serializedData = JSON.stringify(queryAttrs);
  try {
    return localStorage.setItem('calendarQueryAttrs', serializedData);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = totalMinutes ? Math.floor(totalMinutes / 60) : 0;
  const minutes = totalMinutes ? totalMinutes % 60 : 0;
  return Number(`${hours}${minutes > 0 ? `.${minutes}` : ''}`);
}
