import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: 'rgb(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const CircularProgressWithBackdrop = ({ loading = false, style = {} }) => {
  const {classes} = useStyles();

  return (
    <Backdrop open={loading} className={classes.root} style={style}>
      <CircularProgress />
    </Backdrop>
  );
};

export default CircularProgressWithBackdrop;
