/*
Duplicate file server/invoice-item-types.js
*/
export default {
  appointment: {
    name: 'Appointment',
    type: 'appointment',
  },
  product: {
    name: 'Product',
    type: 'product',
  },
  discount: {
    name: 'Discount',
    type: 'discount',
  },
  giftCardIssue: {
    name: 'Gift card issue',
    type: 'giftCardIssue',
  },
  giftCardRedeem: {
    name: 'Gift card redeem',
    type: 'giftCardRedeem',
  },
  creditRedeem: {
    name: 'Credit redeem',
    type: 'creditRedeem',
  },
  packageIssue: {
    name: 'Package issue',
    type: 'packageIssue',
  },
  packageRedeem: {
    name: 'Package redeem',
    type: 'packageRedeem',
  },
  gratuity: {
    name: 'Gratuity',
    type: 'gratuity',
  },
  creditIssued: {
    name: 'Credit issued',
    type: 'creditIssued',
  },
};
