/* eslint-disable import/no-unresolved */
import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import CustomDialogTitle from './Title';
import CustomDialogContent from './Content';
import CustomDialogActions from './Actions';

const useStyles = makeStyles()((theme) => ({
  paper: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.spacing(),
    position: 'relative',
    overflowY: 'initial',
  },
  paperFullScreen: {
    width: '98%',
    height: 'auto',
    borderRadius: theme.spacing(),
  },
  paperScrollBody: {
  }
}));

const CustomDialog = ({ classes: parentClasses, ...props }) => {
  const {classes, cx} = useStyles();

  return (
    <Dialog
      aria-labelledby="custom-dialog"
      {...props}
      classes={{
        paper: cx(classes.paper, parentClasses.paper),
        paperFullScreen: cx(
          classes.paperFullScreen,
          parentClasses.paperFullScreen,
        ),
        paperScrollBody: cx(classes.paperScrollBody, parentClasses.paperScrollBody),
      }}
    />
  );
};

CustomDialog.defaultProps = {
  scroll: 'body',
  maxWidth: 'lg',
  classes: {
    paper: '',
    paperFullScreen: '',
    paperScrollBody: ''
  },
};

export {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
};

export default CustomDialog;
