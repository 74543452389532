import { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  FormControl,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';

import {
  CustomDialogTitle,
  CustomDialogContent,
} from '../../../../shared_components/CustomDialog';
import GreenButton from '../../../../shared_components/buttons/Green';
import OrangeButton from '../../../../shared_components/buttons/Orange';
import DoneButton from '../../../../shared_components/buttons/Done';
import { timezoneizeObject } from '../../../../shared_client_utils/dateUtils';
import { formatAppointmentDatetime } from '../../utils/appointmentUtils';
import SearchInput, { SearchItem, SearchCell } from '../SearchInput';
import { SearchApi, AppointmentsApi } from '../../../../client_http_api';
import CircularProgressWithBackdrop from '../../../../shared_components/CircularProgressWithBackdrop';
import InputLabel from '../InputLabel';

const useStyles = makeStyles()(theme => ({
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(2),
  },
  searchLine: {
    width: '50%',
    padding: theme.spacing(0, 3),
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  appointmentsTable: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    borderTop: '1px solid #EFEFEF',
    paddingTop: theme.spacing(2),
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr 1fr 80px 26px',
    gap: '10px',
    padding: theme.spacing(0, 3),
  },
  tableHeader: {
    marginBottom: theme.spacing(1 / 2),

    '& > div': {
      color: '#808190',
      fontSize: theme.spacing(2),
    },
  },
  tableBody: {
    height: '220px',
    overflow: 'auto',
  },
  bodyRow: {
    marginTop: '7px',
    backgroundColor: '#FBFBFB',
    minHeight: '60px',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '14px',

      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
        lineHeight: '14px',
      },
    },
  },
  button: {
    height: '24px',
    width: '25px',
    minWidth: 'auto',
    borderRadius: '6px',
    padding: 0,
  },
  icon: {
    width: '20px',
    height: '20px',
  },
}));

const checkItemsSelected = ids => (appointmentId) => {
  return ids.includes(appointmentId);
};

const AddAppointment = (props) => {
  const {
    handleCloseAddAppointment,
    handleSaveChanges,
    invoice: { items },
    auth,
    business: { timezone },
  } = props;

  const {classes, cx} = useStyles();

  const [appointments, setAppointments] = useState([]);

  const [isDataLoading, setDataLoading] = useState(false);
  const handleSearchResultClick = async (result, setSearchValue) => {
    setDataLoading(true);
    setSearchValue(result.fullName);

    try {
      const response = await AppointmentsApi.fetchAppointmentsForInvoice(result.id, auth);
      const newAppointments = response.reduce((acc, appointment) => {
        const item = items.find(item => item.appointmentId === appointment.id);
        if (item) {
          return acc;
        }

        return [
          ...acc,
          timezoneizeObject(appointment, timezone, 'startTime'),
        ];
      }, []);
      setAppointments(newAppointments);
      setDataLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const [selectedIds, setSelectedAppointments] = useState([]);
  const isSelected = checkItemsSelected(selectedIds);
  const handleSelectAppointment = appointmentId => () => {
    setSelectedAppointments([
      ...selectedIds,
      appointmentId,
    ]);
  }
  const handleDeselectAppointment = appointmentId => () => {
    const newIds = selectedIds.filter(id => id !== appointmentId);
    setSelectedAppointments(newIds);
  }

  return (
    <>
      <CustomDialogTitle
        onClose={handleCloseAddAppointment}
        extraButtons={!_.isEmpty(selectedIds) && (
          <DoneButton onClick={handleSaveChanges(selectedIds)} />
        )}
      >
        Add appointment
      </CustomDialogTitle>

      <CustomDialogContent className={classes.content}>
        <FormControl className={classes.searchLine}>
          <InputLabel
            shrink
            htmlFor="clientId"
            className={classes.label}
            focused={false}
          >
            Client
          </InputLabel>
          <SearchInput
            onSearch={value => SearchApi.searchClients(value, auth)}
            onResultLineClick={handleSearchResultClick}
            onOpenPopper={({ isLoading, isDropdownOpened, searchResults }) => {
              return isLoading || (isDropdownOpened && !_.isEmpty(searchResults));
            }}
          >
            {(result, { onClick }) => (
              <SearchItem onClick={onClick}>
                <SearchCell>{result.fullName}</SearchCell>
                <SearchCell align="right">
                  {result.mobileNumber}
                </SearchCell>
              </SearchItem>
            )}
          </SearchInput>
        </FormControl>
        <Grid container className={classes.appointmentsTable}>
          <div className={cx(
            classes.gridRow,
            classes.tableHeader,
          )}
          >
            <div>Client</div>
            <div>Date</div>
            <div>Treatment</div>
            <div>Practitioner</div>
            <div>Status</div>
            <div />
          </div>
          <div className={classes.tableBody}>
            {appointments.map(appointment => (
              <div
                key={appointment.id}
                className={cx(
                  classes.gridRow,
                  classes.bodyRow,
                )}
              >
                <div>{appointment.clientName}</div>
                <div>{formatAppointmentDatetime(appointment.startTimeTz)}</div>
                <div>{appointment.serviceName}</div>
                <div>{appointment.staffName}</div>
                <div>{appointment.statusName}</div>
                <div>
                  {isSelected(appointment.id) ? (
                    <OrangeButton
                      variant="contained"
                      className={classes.button}
                      onClick={handleDeselectAppointment(appointment.id)}
                    >
                      <RemoveIcon className={classes.icon}/>
                    </OrangeButton>
                  ) : (
                    <GreenButton
                      variant="contained"
                      className={classes.button}
                      onClick={handleSelectAppointment(appointment.id)}
                    >
                      <AddIcon className={classes.icon} />
                    </GreenButton>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Grid>
      </CustomDialogContent>

      <CircularProgressWithBackdrop loading={isDataLoading} />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddAppointment);
