/* eslint-disable react/no-unstable-nested-components */

import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import {
  CustomDialogTitle,
  CustomDialogContent,
} from '../../../../shared_components/CustomDialog';
import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import { OrangeInfoStrip } from '../../../../shared_components/InfoStrip';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 1.5rem 1rem 1.5rem'
  },
}));

const CreditOrGratuity = (props) => {
  const {
    handleCloseConfirmCreditOrGratuityDialogOpened,
    handleAddClientCredit,
    handleAddGratuity,
    currentItems = []
  } = props;

  const {classes} = useStyles();

  const isCreditBtnDisabled = currentItems?.some(({type}) => type === 'creditIssued')
  const isGratuityBtnDisabled = currentItems?.some(({type}) => type === 'gratuity')

  const contentItem = isCreditBtnDisabled ? 'GRATUITY' : isGratuityBtnDisabled ? 'CREDIT' : 'CREDIT or GRATUITY';
  const creditOrGratuityTitle = isCreditBtnDisabled && isGratuityBtnDisabled ? 'CREDIT or GRATUITY' : isGratuityBtnDisabled ? 'CREDIT' : 'GRATUITY';
  const creditOrGratuityContent = !isCreditBtnDisabled && !isGratuityBtnDisabled
    ? `The payment amount exceeds the invoice total. Is this a client ${contentItem} ?`
    : 'One invoice cannot contain more than one credit and one gratuity';

  return (
    <div className={classes.wrapper}>
      <CustomDialogTitle titleStyle={{paddingTop: '0'}} onClose={handleCloseConfirmCreditOrGratuityDialogOpened}>
        {creditOrGratuityTitle}
      </CustomDialogTitle>

      <CustomDialogContent contentStyle={{}}>
        <OrangeInfoStrip>
          <Grid item>
            {creditOrGratuityContent}
          </Grid>
        </OrangeInfoStrip>
      </CustomDialogContent>
      <Grid className={classes.actionWrapper}>
        <Grid container>
          <GreenButton
            variant="contained"
            size="small"
            onClick={handleAddClientCredit}
            disabled={isCreditBtnDisabled}
          >
            Credit
          </GreenButton>
          <div style={{width: '2rem'}} />
          <GreenButton
            variant="contained"
            size="small"
            onClick={handleAddGratuity}
            disabled={isGratuityBtnDisabled}
          >
            Gratuity
          </GreenButton>
        </Grid>

        <GreyButton
          variant="contained"
          size="small"
          onClick={handleCloseConfirmCreditOrGratuityDialogOpened}
        >
          Cancel
        </GreyButton>
      </Grid>
    </div>
  );
};

export default CreditOrGratuity;
