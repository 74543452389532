import moment from 'moment-timezone';

// Workaround functions to work with DST dates
export const timeDiffInMinutes = (date, options) => {
  const startDate = options.startDate;
  const customMoment = options.moment || moment;

  const endDate = customMoment(date);
  const utcOffset = endDate.utcOffset();

  let beginDate;
  if (startDate) {
    beginDate = customMoment(startDate).utcOffset(utcOffset, true);
  } else {
    beginDate = customMoment(endDate).startOf('day').utcOffset(utcOffset, true);
  }

  return endDate.diff(beginDate, 'minutes');
};

const getHoursAndMinutes = (originalMinutes) => {
  const hours = Math.floor(originalMinutes / 60);
  const minutes = originalMinutes - (hours * 60);

  return [hours, minutes];
};
export const setHoursAndMinutesForDate = (date, originalMinutes, options) => {
  const customMoment = options.moment || moment;

  const [hours, minutes] = getHoursAndMinutes(originalMinutes);
  return customMoment(date).hour(hours).minute(minutes);
}
// =========================================
