import { forwardRef, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, _, classes) => ({
  root: {
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: '#ffffff',
      '&:not(.react-datepicker__day--disabled)': {
        color: '#000000'
      }
    },

    '& .react-datepicker__close-icon::after': {
      backgroundColor: theme.palette.primary.main,
    },

    '& .react-datepicker-popper': {
      zIndex: 10000,
    },
  },
  currentDate: {
    color: `${theme.palette.primary.dark} !important`,
    [`&.${classes.selectedDate}`]: {
      color: '#ffffff !important',
    },
    '&.react-datepicker__day--disabled': {
      opacity: 0.5
    }
  },
  selectedDate: {
    borderRadius: '0.3rem',
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff !important',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const CustomInput = forwardRef((props, ref) => {
  const {
    rootComponentProps,
    component: Component,
    ...restProps
  } = props;

  return (
    <Component
      {...restProps}
      {...rootComponentProps}
      inputRef={ref}
    />
  );
});

const dateToStr = date => `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
const todayStr = dateToStr(new Date())
const BaseInput = (props) => {
  const {
    component,
    rootComponentProps,
    selected,
    dropdownMode,
    placeholderText,
    dateFormat,
    className,
    everyDayClassName,
    ...restProps
  } = props;

  const {classes, cx} = useStyles();

  const highlightDates = [];
  if (selected) {
    highlightDates.push({
      [classes.selectedDate]: [selected],
    });
  }

  return (
    <div className={cx(className, classes.root)}>
      <DatePicker
        {...restProps}
        selected={selected}
        dropdownMode={dropdownMode}
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        dayClassName={date => {
          if (dateToStr(date) !== todayStr) {
            return everyDayClassName;
          }
          return cx(everyDayClassName, classes.currentDate);
        }}
        highlightDates={highlightDates}
        customInput={(
          <CustomInput
            {...restProps}
            component={component}
            rootComponentProps={rootComponentProps}
          />
        )}
      />
    </div>
  );
}

BaseInput.defaultProps = {
  dropdownMode: 'select',
  placeholderText: 'YYYY-MM-DD',
  dateFormat: 'yyyy-MM-dd',
  className: '',
  everyDayClassName: '',
  rootComponentProps: {},
};

export default BaseInput;
