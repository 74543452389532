import BaseSelect from './Base';
import OutlinedInput from '../../../../shared_components/Input';

const OutlinedSelect = (props) => {
  const {
    id,
    name,
    className,
    classes,
    onChange,
    rootComponentProps,
    ...restProps
  } = props;

  return (
    <BaseSelect
      {...restProps}
      id={id}
      name={name}
      className={className}
      classes={classes}
      onChange={onChange}
      renderInput={(params) => (
        <OutlinedInput {...restProps} {...rootComponentProps} {...params} />
      )}
    />
  );
};

OutlinedSelect.defaultProps = {
  rootComponentProps: {},
};

export default OutlinedSelect;
