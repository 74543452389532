import { useState, Fragment } from 'react';
import moment from 'moment';
import Dinero from 'dinero.js';
import {
  Grid,
  Typography,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import OrangeButton from '../../../../shared_components/buttons/Orange';
import MoneyInput from '../MoneyInput';
import { GiftCardsApi } from '../../../../client_http_api';
import { moneytizeObject, formatMoney } from '../../../../shared_client_utils/moneyUtils';
import { getCurrentClientTime } from '../../../../shared_client_utils/dateUtils';
import OutlinedInput from '../../../../shared_components/Input';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    minWidth: theme.spacing(37),
  },
  inputBox: {
    marginTop: theme.spacing(),
  },
  numberInput: {
    width: '100%',
  },
  usedAmountBox: {
    marginTop: theme.spacing(6/4),
    alignItems: 'center',
  },
  usedAmount: {
    width: theme.spacing(13),
  },
  balance: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  buttonBox: {
    marginTop: theme.spacing(15/8),
    justifyContent: 'space-between',
  },
  formHelperText: {
    color:'red'
  },
}));

const RedeemGiftCard = (props) => {
  const {
    onClose,
    handleSaveChanges,
    invoice: { items, currency, amountDue },
  } = props;

  const {classes} = useStyles();

  const [number, setNumber] = useState();
  const [numberError, setNumberError] = useState('');
  const [isNumberFound, setIsNumberFound] = useState(false);

  const handleChangeNumber = ({ target }) => {
    setIsNumberFound(false);
    setNumber(target.value);
    if (numberError) setNumberError('');
    if (clientGiftCard) setClientGiftCard(null);
  }

  const [currentClientTime, setCurrentClientTime] = useState(null);
  const [clientGiftCard, setClientGiftCard] = useState(null);
  const handleFindGiftCard = async () => {
    if (!number) {
      setNumberError('Enter gift card number');
      return;
    }

    const currentTime = getCurrentClientTime();
    setCurrentClientTime(currentTime);

    const foundClientGiftCard = await GiftCardsApi.getValidClientGiftCardByCode(
      number, currentTime, props.auth,
    );
    if (!foundClientGiftCard) {
      setNumberError('No matching gift card found');
      return;
    }

    const alreadyAddedGiftCard = items.find((item) => {
      return item.clientGiftCardId === foundClientGiftCard.id;
    });
    if (alreadyAddedGiftCard) {
      setNumberError('The gift card already applied to this invoice');
      return;
    }

    const monetizedGiftCard = moneytizeObject(
      foundClientGiftCard,
      'initialValue',
      'balance',
    );
    setClientGiftCard(monetizedGiftCard);

    let usedAmount;
    if (monetizedGiftCard.balanceMoney.lessThanOrEqual(amountDue)) {
      usedAmount = Dinero(monetizedGiftCard.balanceMoney.toObject());
    } else {
      usedAmount = Dinero(amountDue.toObject());
    }

    setUsedAmount({
      value: formatMoney(usedAmount),
      money: usedAmount,
    });

    setIsNumberFound(true);
  }

  const [usedAmount, setUsedAmount] = useState({ value: '' });
  const [usedAmountError, setUsedAmountError] = useState('');
  const handleChangeUsedAmount = (value, money) => {
    setUsedAmount({ value, money });
    if (usedAmountError) setUsedAmountError('');
  }

  const handleAddGiftCard = () => {
    if (usedAmount.money.greaterThan(clientGiftCard.balanceMoney)) {
      setUsedAmountError('Entered amount must be less or equal to overall balance');
      return;
    }

    const amount = usedAmount.money.getAmount();
    return handleSaveChanges(number, currentClientTime, amount);
  }

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography color="secondary" variant="subtitle1">
          Apply gift card
        </Typography>
      </Grid>
      <Grid item className={classes.inputBox}>
        <OutlinedInput
          name="number"
          value={number}
          onChange={handleChangeNumber}
          placeholder="Number"
          autoComplete="off"
          error={numberError}
          className={classes.numberInput}
        />
        {numberError && (
          <FormHelperText className={classes.formHelperText}>
            {numberError}
          </FormHelperText>
        )}
      </Grid>

      {clientGiftCard && (
        <Fragment>
          <Grid container className={classes.usedAmountBox}>
            <Grid item>Use&nbsp;</Grid>
            <Grid item>
              <MoneyInput
                id="usedAmount"
                name="usedAmount"
                value={usedAmount.value}
                currency={currency}
                onChange={handleChangeUsedAmount}
                className={classes.usedAmount}
              />
            </Grid>
            <Grid item>
              &nbsp;out of <span className={classes.balance}>
                {clientGiftCard.balanceMoney.toFormat()}
              </span>
            </Grid>
          </Grid>
          {usedAmountError && (
            <FormHelperText className={classes.formHelperText}>
              {usedAmountError}
            </FormHelperText>
          )}
        </Fragment>
      )}

      <Grid container className={classes.buttonBox}>
        {isNumberFound ? (
          <GreenButton
            variant="contained"
            size="small"
            onClick={handleAddGiftCard}
          >
            Add
          </GreenButton>
        ) : (
          <OrangeButton
            variant="contained"
            size="small"
            onClick={handleFindGiftCard}
          >
            Find
          </OrangeButton>
        )}
        <GreyButton
          variant="text"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </Grid>
    </Grid>
  );
};

export default RedeemGiftCard;
