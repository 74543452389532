import { InputLabel } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  formControl: {
    position: 'initial!important',
    transform: 'none!important',
    fontSize: `${theme.spacing(14/8)}!important`,
  },
});

export default withStyles(InputLabel, styles);
