import { cx } from '@emotion/css'
import {
  TableRow,
  TableCell,
} from '@mui/material';

// mport TrashButton from '../../../../shared_components/buttons/Trash';

const CreditIssuedLine = (props) => {
  const {
    item,
    classes,
    // onClickOpenDeleteItemWindow,
    // isChangeable,
  } = props;

  return (
    <TableRow className={classes.bodyRow}>
      <TableCell
        className={cx(
          classes.bodyCell,
          classes.descriptionCell,
        )}
      >
        {item.description}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.quantity}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.formattedPrice}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.taxName}
      </TableCell>

      <TableCell className={classes.bodyCell}>
        {item.formattedTotalPrice}
      </TableCell>

      <TableCell
        className={cx(
          classes.bodyCell,
          classes.actionsCell,
        )}
      >
        {/* {isChangeable && (
          <Grid container className={classes.actionsBox}>
            <Grid />
            <Grid
              container
              onClick={onClickOpenDeleteItemWindow(item.id)}
              className={classes.visibleIconBox}
            >
              <TrashButton />
            </Grid>
          </Grid>
        )} */}
      </TableCell>
    </TableRow>
  );
};

export default CreditIssuedLine;
