import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import Textarea from '../../../../shared_components/Textarea';
import OutlinedInput from '../../../../shared_components/Input';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    width: '27rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  email: {
    height: '40px',
    marginTop: theme.spacing(),
  },
  textareaBox: {
    marginTop: theme.spacing(15 / 8),
  },
  buttonBox: {
    marginTop: theme.spacing(15 / 8),
    justifyContent: 'space-between',
  },
}));

const Email = (props) => {
  const { onClose, handleSendEmail, invoice: { clientEmail } } = props;

  const {classes} = useStyles();
  const [email, setEmail] = useState(clientEmail);
  const [message, setMessage] = useState();

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography color="secondary" variant="subtitle1">
          Email invoice
        </Typography>
      </Grid>
      <OutlinedInput
        id="email"
        name="email"
        placeholder="Email"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
        className={classes.email}
      />
      <Textarea
        id="message"
        name="message"
        placeholder="Message (optional)"
        value={message}
        onChange={({ target }) => setMessage(target.value)}
        className={classes.textareaBox}
        minRows="5"
      />
      <Grid container className={classes.buttonBox}>
        <GreenButton
          variant="contained"
          size="small"
          onClick={handleSendEmail(email, message)}
        >
          Send
        </GreenButton>
        <GreyButton
          variant="text"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </Grid>
    </Grid>
  );
};

export default Email;
