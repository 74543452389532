import Link from 'next/link';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { RedInfoStrip } from '../../../shared_components/InfoStrip';
import { salesClientPath } from '../../../client_routes';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(1/2, 1),
    borderLeft: 'none',
  },
  link: {
    borderBottom: '1px solid',
    cursor: 'pointer',
  },
}));

const OutstandingBalanceStrip = ({ amount, clientId, className }) => {
  const {classes, cx} = useStyles();

  let amountOrLink = amount;
  if (clientId) {
    amountOrLink = (
      <Link href={salesClientPath(clientId)}>
        <span className={classes.link}>{amount}</span>
      </Link>
    );
  }

  return (
    <RedInfoStrip className={cx(classes.root, className)}>
      <Typography variant="body1">
        Amount Owing &ndash; {amountOrLink}
      </Typography>
    </RedInfoStrip>
  );
};

OutstandingBalanceStrip.defaultProps = {
  className: '',
};

export default OutstandingBalanceStrip;
