import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import has from '../utils/has';

const useStyles = makeStyles()((theme) => ({
  popper: {
    zIndex: 10000,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.50)',
    boxShadow: theme.shadows[2],
    fontSize: '14px',
    maxWidth: 'max-content',
  },
}));

const initOpenOptionsFromProps = (props) => {
  if (!has(props, 'open')) {
    return {};
  }

  const open = props.open;
  return {
    disableHoverListener: !open,
    disableTouchListener: !open,
    disableFocusListener: !open,
  };
};

const CustomTooltip = (props) => {
  const { children, open, ...restProps } = props;

  const {classes} = useStyles();

  const openOptions = initOpenOptionsFromProps(props);

  return (
    <Tooltip
      {...openOptions}
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip,
      }}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
