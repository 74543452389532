import { Component, Fragment } from 'react';

import FlashMessage from '../shared_components/FlashMessage';

const withFlashMessage = (Page) => {
  class FlashedPage extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpenFlashMessage: false,
        flashMessage: '',
      };

      this.handleDisplayFlashMessage = this.handleDisplayFlashMessage.bind(this);
      this.handleCloseFlashMessage = this.handleCloseFlashMessage.bind(this);
    }

    handleDisplayFlashMessage(message, severity = 'success', autoHideDuration = 4000, anchor, style) {
      this.setState({
        isOpenFlashMessage: true,
        flashMessage: message,
        severity,
        autoHideDuration,
        anchor,
        style
      });
    }

    handleCloseFlashMessage() {
      this.setState({
        isOpenFlashMessage: false,
        flashMessage: '',
      });
    }

    render() {
      const { isOpenFlashMessage, flashMessage, severity, autoHideDuration, anchor, style } = this.state;

      return (
        <>
          <Page
            {...this.state}
            {...this.props}
            handleDisplayFlashMessage={this.handleDisplayFlashMessage}
          />

          <FlashMessage
            open={isOpenFlashMessage}
            onClose={this.handleCloseFlashMessage}
            message={flashMessage}
            severity={severity}
            autoHideDuration={autoHideDuration}
            anchor={anchor}
            style={style}
          />
        </>
      );
    }
  }

  return FlashedPage;
};

export default withFlashMessage;
