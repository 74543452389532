/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dinero from 'dinero.js';
import { isEmail } from 'validator';
import {
  Grid,
  FormControl,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { isValidPhoneNumber } from 'react-phone-number-input';

import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../../shared_components/CustomDialog';
import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import Select from '../Select';
import { loadPackages } from '../../slices/packagesSlice';
import { prepareOptions, makeOptionWithField, validateObjectByMap } from '../../../../shared_client_utils/formUtils';
import { SearchApi } from '../../../../client_http_api';
import SearchInput, { SearchItem, SearchCell } from '../SearchInput';
import ClientForm from './ClientForm';
import Tooltip from '../Tooltip';
import InputLabel from '../InputLabel';
import { calculateNewServicePrice } from '../../utils/packageUtils';

const useStyles = makeStyles()(theme => ({
  main: {
    '& > div:last-of-type': {
      marginBottom: 0,
    },
  },
  wholeBox: {
    marginBottom: theme.spacing(2),
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  packageSelect: {
    width: '100%',
  },
  packageInfoBox: {
    marginTop: theme.spacing(2),
  },
  quantity: {
    marginRight: theme.spacing(1 / 2),
  },
  packageInfoTitle: {
    color: grey[700],
  },
  pkgContent: {
    flexDirection: 'column',
    marginTop: theme.spacing(),
  },
  pkgContentLine: {
    marginTop: theme.spacing(),
    alignItems: 'baseline',
  },
  pkgContentLineTitle: {
    color: grey[600],
  },
  pkgContentLineBody: {
    marginLeft: theme.spacing(2),
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },
  discountedPriceTitle: {
    width: 'auto',
  },
  questionIcon: {
    width: '18px',
    height: '18px',
    cursor: 'help',
  },
}));

const initializeClient = () => ({
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  fullName: '',
});

const initializeClientPackage = (client) => ({
  client: client || initializeClient(),
  packageId: '',
  name: '',
  price: 0,
  serviceName: '',
  quantity: 0,
  newServicePrice: 0,
});

const buildNameAndPrice = (pkg) => {
  const priceMoney = Dinero({
    amount: pkg.price,
    currency: pkg.currency,
  });

  const formattedName = `${pkg.name} (${priceMoney.toFormat()})`;

  return {
    ...pkg,
    priceMoney,
    formattedName,
  };
};

const clientsErrorMap = {
  firstName: {
    isValid: (value) => value && value.length > 0,
  },
  lastName: {
    isValid: (value) => value && value.length > 0,
  },
  email: {
    isValid: (value) => value && isEmail(value),
  },
  mobileNumber: {
    isValid: (value) => value && isValidPhoneNumber(value),
  },
};

const clientPackageErrorsMap = {
  packageId: {
    isValid: (value) => value && value.length > 0,
  },
  client: {
    isValid: ({ id }) => id && id.length > 0,
  },
};

const IssuePackage = (props) => {
  const {
    onClose,
    handleSaveChanges,
    client: propsClient,
    loadPackages,
    auth
  } = props;

  const {classes, cx} = useStyles();

  const [clientPackage, setClientPackage] = useState(() => {
    return initializeClientPackage(propsClient);
  });
  const [clientPackageErrors, setClientPackageErrors] = useState({});
  const handleSelectPackage = ({ id, priceMoney, quantity, ...restPkg }) => {
    const newServicePriceMoney = calculateNewServicePrice(priceMoney, quantity);

    setClientPackage((prevValue) => ({
      ...prevValue,
      ...restPkg,
      quantity,
      priceMoney,
      newServicePriceMoney,
      newServicePrice: newServicePriceMoney.getAmount(),
      packageId: id,
    }));
  }

  const [packages, setPackages] = useState([]);
  useEffect(() => {
    const fetchAndStatePackages = async () => {
      const { payload } = await loadPackages();
      const preparedPackages = prepareOptions(payload)
        .map(buildNameAndPrice)
        .map(makeOptionWithField({ labelColumn: 'formattedName' }));
      setPackages(preparedPackages);
    }

    fetchAndStatePackages();
  }, []);

  const handleSelectClient = (result, setSearchValue) => {
    setClientPackage((prevValue) => ({
      ...prevValue,
      client: { ...result },
    }));
    setSearchValue(result.fullName);
  }
  const handleInitiateClient = (parsedFullName) => {
    setClientPackage((prevValue) => ({
      ...prevValue,
      client: {
        ...initializeClient(),
        ...parsedFullName,
      },
    }));
  }
  const handleAddClient = ({ onCloseForm, setSearchValue }) => client => {
    setClientPackage((prevValue) => ({
      ...prevValue,
      client: {
        ...client,
        status: 'new',
      },
    }));
    setSearchValue(client.fullName);
    onCloseForm();
  }

  const onAdd = () => {
    const { isValid, errors } = validateObjectByMap(
      clientPackage,
      clientPackageErrorsMap,
    );
    if (!isValid) {
      setClientPackageErrors(errors);
      return;
    }

    return handleSaveChanges(clientPackage);
  }

  const selectedPackage = packages.find((pkg) => {
    return pkg.id === clientPackage.packageId;
  });

  return (
    <>
      <CustomDialogTitle onClose={onClose}>
        Issue package
      </CustomDialogTitle>

      <CustomDialogContent className={classes.main}>
        <FormControl className={classes.wholeBox}>
          <InputLabel
            shrink
            required
            htmlFor="package"
            className={classes.label}
            focused={false}
            error={clientPackageErrors.packageId}
          >
            Package
          </InputLabel>
          <Select
            id="package"
            name="package"
            options={packages}
            value={selectedPackage}
            onChange={handleSelectPackage}
            className={classes.packageSelect}
            error={clientPackageErrors.packageId}
          />
        </FormControl>
        <FormControl className={classes.wholeBox}>
          <InputLabel
            shrink
            required
            htmlFor="client"
            className={classes.label}
            focused={false}
            error={clientPackageErrors.client}
          >
            Issue to
          </InputLabel>
          <SearchInput
            showCreateLink
            showFormOnCreate
            id="client"
            placeholder="Client"
            onSearch={(value) => SearchApi.searchClients(value, auth)}
            onResultLineClick={handleSelectClient}
            onCreateLineClick={handleInitiateClient}
            value={clientPackage.client.fullName}
            error={clientPackageErrors.client}
            onOpenPopper={({ isLoading, isDropdownOpened, isFormOpened }) => {
              return isLoading || isDropdownOpened || isFormOpened;
            }}
            form={(actions) => (
              <ClientForm
                client={clientPackage.client}
                handleAddClient={handleAddClient(actions)}
                onClose={actions.onCloseForm}
                clientsErrorMap={clientsErrorMap}
              />
            )}
          >
            {(result, { onClick }) => (
              <SearchItem onClick={onClick}>
                <SearchCell>{result.fullName}</SearchCell>
                <SearchCell align="right">{result.mobileNumber}</SearchCell>
              </SearchItem>
            )}
          </SearchInput>
        </FormControl>

        {clientPackage.packageId && (
          <Grid container className={classes.packageInfoBox}>
            <Typography variant="h6" className={classes.packageInfoTitle}>
              Service information
            </Typography>

            <Grid container className={classes.pkgContent}>
              <Grid container className={classes.pkgContentLine}>
                <Grid item className={classes.pkgContentLineTitle}>
                  Name
                </Grid>
                <Grid item className={classes.pkgContentLineBody}>
                  {clientPackage.serviceName}
                </Grid>
              </Grid>

              <Grid container className={classes.pkgContentLine}>
                <Grid item className={classes.pkgContentLineTitle}>
                  Quantity
                </Grid>
                <Grid item className={classes.pkgContentLineBody}>
                  <span className={classes.quantity}>
                    {clientPackage.quantity}
                  </span>
                  <span>visits</span>
                </Grid>
              </Grid>

              <Grid container className={classes.pkgContentLine}>
                <Grid
                  container
                  className={cx(
                    classes.pkgContentLineTitle,
                    classes.discountedPriceTitle,
                  )}
                >
                  Discounted service price&nbsp;
                  <Tooltip
                    title={(
                      <>
                        This price will be used instead of an appointment price.<br />
                        It was calculated as {clientPackage.priceMoney.toFormat()} / {clientPackage.quantity}
                      </>
                    )}
                  >
                    <HelpOutlineIcon
                      className={classes.questionIcon}
                    />
                  </Tooltip>
                </Grid>
                <Grid item className={classes.pkgContentLineBody}>
                  {clientPackage.newServicePriceMoney.toFormat()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CustomDialogContent>

      <CustomDialogActions>
        <GreenButton
          variant="contained"
          size="small"
          onClick={onAdd}
        >
          Add
        </GreenButton>
        <GreyButton
          variant="contained"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </CustomDialogActions>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = dispatch => ({
  loadPackages: bindActionCreators(loadPackages, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssuePackage);
