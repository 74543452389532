import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import BaseButton from './Base';

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',

    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  outlined: {
    backgroundColor: '#ffffff',
    color: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  text: {
    backgroundColor: '#ffffff',
    color: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

const RedButton = forwardRef((props, ref) => {
  const {classes} = useStyles();

  return (
    <BaseButton
      color="inherit"
      classes={{
        root: classes.root,
        outlined: classes.outlined,
        text: classes.text,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default RedButton;
