/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import { useState, useRef, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Popper,
  Paper,
  Table,
  TableBody,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

import OutlinedInput from '../../../../shared_components/Input';
import SearchItem from './Item';
import SearchCell from './Cell';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  searchPopper: {
    width: '100%',
    margin: theme.spacing(1, 0),
    zIndex: theme.zIndex.drawer + 1,
  },
  searchInput: {
    width: '100%',
  },
  grey: {
    color: grey[700],
  },
  paper: {
    maxHeight: '50vh',
    overflowY: 'auto',
  },
}));

const parseFullName = (searchValue) => {
  const [firstName, ...restNames] = searchValue.split(' ');
  const lastName = restNames ? restNames.join(' ') : '';
  return {
    firstName: firstName || '',
    lastName: lastName || '',
  };
};

// const isValidFullName = ({ firstName, lastName}) => firstName && lastName;

const SearchInput = (props) => {
  const {
    children,
    showCreateLink,
    clearResultsOnClick,
    showFormOnCreate,
    form,
    onSearch,
    onResultLineClick,
    onCreateLineClick,
    onOpenPopper,
    rootComponentProps,
    popperProps,
    className,
    value: propsValue,
    ...restProps
  } = props;

  const {classes, cx} = useStyles();

  const [searchValue, setSearchValue] = useState(propsValue);
  const [parsedFullName, setParsedFullName] = useState({});

  const [isDropdownOpened, setDropdownOpened] = useState(false);
  const [isLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [isFormOpened, setFormOpened] = useState(false);

  const searchRef = useRef(null);

  const [searchTimeoutId, setSearchTimeoutId] = useState(null);

  const handleSearchChange = async ({ target: { value } }) => {
    const { checkSkippingSearch } = props;
    if (isFormOpened) setFormOpened(false);

    setSearchValue(value);
    if (checkSkippingSearch(value)) {
      setDropdownOpened(false);
      setSearchLoading(false);
      setSearchResults([]);
      clearTimeout(searchTimeoutId);
      setSearchTimeoutId(null);
      return;
    }

    setSearchLoading(true);

    let result = [];
    try {
      result = await onSearch(value);
    } catch (error) {
      console.log(error)
    }

    clearTimeout(searchTimeoutId);
    const timeoutId = setTimeout(() => {
      setSearchResults(result);
      setSearchLoading(false);
      setDropdownOpened(true);
    }, 1000);
    setSearchTimeoutId(timeoutId);
  };

  const onClickSearchBox = () => {
    // avoid render every click on input
    if (isDropdownOpened) {
      return;
    }
    setDropdownOpened(!_.isEmpty(searchResults) || isFormOpened);
  }

  const handleLeaveSearchBox = () => {
    // avoid render every click outside of element
    if (!isDropdownOpened) {
      return;
    }
    setDropdownOpened(false);
  }

  const handleResultLineClick = result => () => {
    onResultLineClick(result, setSearchValue);
    setDropdownOpened(false);
    if (clearResultsOnClick) setSearchResults([]);
  }

  const handleCreateLineClick = () => {
    const parsedValue = parseFullName(searchValue);
    setParsedFullName(parsedValue);

    if (showFormOnCreate) {
      setFormOpened(true);
    } else {
      setDropdownOpened(false);
    }

    if (clearResultsOnClick) setSearchResults([]);

    onCreateLineClick(parsedValue, { setSearchValue });
  }

  const onCloseForm = () => {
    setDropdownOpened(false);
  }

  const isPopperOpened = onOpenPopper({
    isLoading,
    isDropdownOpened,
    searchResults,
    isFormOpened
  });

  return (
    <ClickAwayListener onClickAway={handleLeaveSearchBox} >
      <div
        className={cx(classes.root, className)}
        ref={searchRef}
      >
        <OutlinedInput
          id="search"
          name="search"
          autoComplete="off"
          className={classes.searchInput}
          {...restProps}
          {...rootComponentProps}
          value={searchValue}
          onChange={handleSearchChange}
          onClick={onClickSearchBox}
        />

        <Popper
          disablePortal
          open={isPopperOpened}
          anchorEl={searchRef.current}
          position="bottom"
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
              },
            ],
          }}
          {...popperProps}
          className={classes.searchPopper}
        >
          <Paper elevation={4} className={classes.paper}>
            {(isFormOpened && isDropdownOpened) ? (
              form({ onCloseForm, setSearchValue })
            ) : (
              <Table padding="normal">
                <TableBody>
                  {isLoading && (
                    <SearchItem>
                      <SearchCell
                        colSpan={100}
                        className={classes.grey}
                      >
                        Searching...
                      </SearchCell>
                    </SearchItem>
                  )}
                  {isDropdownOpened && searchResults.map((result, i) => {
                    return (
                      <Fragment key={result?.id || i}>
                        {children(result, {
                          onClick: handleResultLineClick(result),
                        })}
                      </Fragment>
                    )
                  })}

                  {showCreateLink && isDropdownOpened && !isLoading && (
                    <SearchItem onClick={handleCreateLineClick}>
                      <SearchCell
                        colSpan={100}
                        className={classes.grey}
                      >
                        Create {`"${searchValue || ''}"`}
                      </SearchCell>
                    </SearchItem>
                  )}
                </TableBody>
              </Table>
            )}
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

SearchInput.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.string,
  onSearch: PropTypes.func,
  onResultLineClick: PropTypes.func,
  onOpenPopper: PropTypes.func,
  checkSkippingSearch: PropTypes.func,
  className: PropTypes.string,
  rootComponentProps: PropTypes.object,
  popperProps: PropTypes.object,
  form: PropTypes.func,
  onCreateLineClick: PropTypes.func,
};

SearchInput.defaultProps = {
  value: '',
  className: '',
  rootComponentProps: {},
  popperProps: {},
  form: () => {},
  onSearch: () => {},
  onResultLineClick: () => {},
  onCreateLineClick: () => {},
  onOpenPopper: () => {},
  checkSkippingSearch: (value) => value.length < 3,
};

export { SearchItem, SearchCell };

export default SearchInput;
