/* eslint-disable class-methods-use-this */
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Component, Fragment } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Link from 'next/link';
import Router, { withRouter } from 'next/router';
import {
  Grid,
  AppBar,
  Popover,
  InputAdornment,
  Toolbar,
  IconButton,
  Hidden,
  Drawer,
} from '@mui/material';
import _ from 'lodash';
import {
  Menu as MenuIcon,
  Person as PersonIcon,
  Receipt as ReceiptIcon,
} from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { withStyles } from 'tss-react/mui';

import {
  AnalyticsOneIcon,
  AppointmentIcon,
  InfoIcon,
  SearchIcon,
  SettingsIcon,
  ShoppingCartIcon,
} from "../../../shared_components/icons"
import { loginUser } from '../slices/authSlice';
import { loadLoginStaff } from '../slices/loginStaffSlice';
import AccountMenu from "./AccountMenu";
import SupportMenu from "./SupportMenu";
import SearchInput, { SearchItem, SearchCell } from './SearchInput';
import { AppointmentsApi, SearchApi } from '../../../client_http_api';
import Invoice from './Invoice';
import Avatar from './Avatar';
import { navbarHeightMultiplier, maxWidth } from '../../../shared_client_utils/theme';
import { getInitials } from '../../../shared_client_utils/formUtils';
import CustomDialog from '../../../shared_components/CustomDialog';
import AccountSettings from './AccountSettings';
import { composeGCSUrl } from '../../../shared_client_utils/googleCloudStorageUtils';
import AppBarLogo from './AppBarLogo';
import AccountSideBar from './AccountSideBar';
import { getQueryAttrsFromBrowser } from '../utils/calendarUtils';
import { rootPath } from '../../../client_routes';
import items from '../configs/sidebarItems';
import { getFullName } from '../utils/personUtils';
import { setCriticalStatus } from '../slices/appointmentsSlice';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    // width: '100%',
  },
  navBar: {
    flexDirection: 'row',
    justifyContent: 'center',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
    height: theme.spacing(navbarHeightMultiplier),
  },
  toolbar: {
    maxWidth,
    width: '100%',
    padding: `${theme.spacing(0, 2)}!important`,

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0, 1)}`,
    },
  },
  toolbarRegular: {
    minHeight: theme.spacing(navbarHeightMultiplier),
  },
  leftSidebarButton: {
    padding: theme.spacing(),
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
  content: {
    margin: theme.spacing(0, 1),
  },
  sectionLeft: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionMiddle: {
    justifyContent: 'center',
  },
  sectionRight: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  caneldarIconBox: {
    marginRight: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  dashboard: {
    marginRight: theme.spacing(),
  },
  settings: {
    marginRight: theme.spacing(),
  },
  support: {
    marginRight: theme.spacing(),
  },
  accountBox: {
    width: 'auto',
  },
  accountButton: {
    padding: theme.spacing(),
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(),
    cursor: "pointer",

    "&:hover": {
      color: "#FF7147",
    },
  },
  supportMenuButton: {
    color: theme.palette.primary.main,
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(),
    cursor: "pointer",

    "&:hover": {
      color: "#FF7147",
    },
  },
  search: {
    maxWidth: theme.spacing(40),
  },
  searchIcon: {
    height: theme.spacing(2),
    width: 'auto',
    fill: grey[500],
  },
  drawer: {
    width: 220,
  },
  drawerPaper: {
    zIndex: 10,
    width: 220,
    position: 'inherit',
  },
  paperScrollBody: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      maxWidth: '100% !important',
      width: '100% !important',
    },
  }
});

const isAllowForMenuIcon = (pathname) => {
  return pathname === '/'
    || pathname.match(/^\/settings/)
    || pathname.match(/^\/shift-management/);
};

const getFilterSubItemsAllow = (items) => {
  const result = []
  items.forEach(subItem => {
    subItem?.subItems?.forEach(item => {
      if(item?.noDisplayed) {
        result.push(item.type)
      }
    })
  })
  return result
}

const searchResultOutputMap = {
  client: ({ id, fullName, mobileNumber }, { onClick, classes }) => (
    <SearchItem key={id} onClick={onClick}>
      <SearchCell iconCell title="Client">
        <PersonIcon className={classes.searchIcon} />
      </SearchCell>
      <SearchCell>{fullName}</SearchCell>
      <SearchCell align="right">{mobileNumber}</SearchCell>
    </SearchItem>
  ),
  invoice: ({ id, invoiceNumber }, { onClick, classes }) => (
    <SearchItem key={id} onClick={onClick}>
      <SearchCell iconCell title="Invoice">
        <ReceiptIcon className={classes.searchIcon} />
      </SearchCell>
      <SearchCell>{invoiceNumber}</SearchCell>
      <SearchCell />
    </SearchItem>
  ),
};

const AccountButton = (props) => {
  const {
    classes,
    isMenuOpened,
    onClick,
    avatarUrl,
    firstName,
    lastName,
  } = props;

  return (
    <div
      className={classes.accountButton}
      title="Account"
      data-testid="accountMenuButton"
      aria-owns={isMenuOpened ? "hive-menu-appbar" : null}
      aria-haspopup="true"
      onClick={onClick}
    >
      <Avatar
        size="small"
        src={composeGCSUrl(avatarUrl)}
      >
        {getInitials(firstName, lastName)}
      </Avatar>
    </div>
  );
};

const SupportMenuButton = (props) => {
  const {
    classes,
    isSupportMenuOpened,
    onClick,
  } = props;

  return (
    <div
      className={classes.supportMenuButton}
      title="Support"
      aria-owns={isSupportMenuOpened ? "support-menu-dropdown" : null}
      aria-haspopup="true"
      onClick={onClick}
    >
      <InfoIcon />
    </div>
  );
};

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffSearchValue: '',
      staffSearchResults: [],
      staffLoginLoading: false,
      loadingStaff: false,
      isOpenLoginDialog: false,
      newStaff: {},
      password: '',
      passwordError: '',
      isMenuOpened: false,
      isSupportMenuOpened: false,
      anchorEl: null,
      isInvoiceDialogOpened: false,
      isAccountSettingsDialogOpened: false,
      currentInvoiceId: '',
      isInvoiceNew: true,
      isRightSidebarOpened: false,
      customerPortalLink: null
    };
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleOpenSupportMenu = this.handleOpenSupportMenu.bind(this);
    this.handleCloseSupportMenu = this.handleCloseSupportMenu.bind(this);
    this.handleLoginAsAnotherStaff = this.handleLoginAsAnotherStaff.bind(this);
    this.handleStaffSearchChange = this.handleStaffSearchChange.bind(this);
    this.handleOpenStaffLogin = this.handleOpenStaffLogin.bind(this);
    this.handleCloseStaffLogin = this.handleCloseStaffLogin.bind(this);
    this.handleExitStaffLogin = this.handleExitStaffLogin.bind(this);
    this.handleStaffPassword = this.handleStaffPassword.bind(this);
    this.handleOpenInvoiceDialog = this.handleOpenInvoiceDialog.bind(this);
    this.handleCloseInvoiceDialog = this.handleCloseInvoiceDialog.bind(this);
    this.handleOpenAccountSettingsDialog = this.handleOpenAccountSettingsDialog.bind(this);
    this.handleCloseAccountSettingsDialog = this.handleCloseAccountSettingsDialog.bind(this);
    this.handleSearchResultLineClick = this.handleSearchResultLineClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOpenRightSidebar = this.handleOpenRightSidebar.bind(this);
    this.handleCloseRightSidebar = this.handleCloseRightSidebar.bind(this);
    this.onClickCalendarIcon = this.onClickCalendarIcon.bind(this);
    this.getDefaultSettingsPath = this.getDefaultSettingsPath.bind(this);
  }

  componentDidMount() {
    const { loadLoginStaff } = this.props;
    loadLoginStaff();
  }

  handleOpenMenu(event) {
    this.setState({
      isMenuOpened: true,
      anchorEl: event.currentTarget,
    });
  }

  handleCloseMenu() {
    this.setState({
      isMenuOpened: false,
      anchorEl: null,
    });
  }

  handleOpenSupportMenu(event) {
    this.setState({
      isSupportMenuOpened: true,
      anchorEl: event.currentTarget,
    });
  }

  handleCloseSupportMenu() {
    this.setState({
      isSupportMenuOpened: false,
      anchorEl: null,
    });
  }

  handleOpenStaffLogin = (staff) => () => {
    this.setState({
      newStaff: { ...staff },
      isOpenLoginDialog: true,
    });
  }

  handleCloseStaffLogin() {
    this.setState({ isOpenLoginDialog: false });
  }

  handleOpenAccountSettingsDialog() {
    this.setState({
      isAccountSettingsDialogOpened: true,
    }, this.handleCloseMenu);
  }

  handleCloseAccountSettingsDialog() {
    this.setState({ isAccountSettingsDialogOpened: false });
  }

  handleStaffPassword = ({ target: { value } }) => {
    this.setState({
      password: value,
      passwordError: '',
      customerPortalLink: null
    });
  }

  handleExitStaffLogin = () => {
    this.setState({
      newStaff: {},
      password: '',
      passwordError: '',
      customerPortalLink: null
    });
  }

  handleLoginAsAnotherStaff = async () => {
    const { newStaff: { id, email }, password } = this.state;
    const { loginUser, loadLoginStaff, setCriticalStatus } = this.props;
    if (password.length < 8) {
      this.setState({ passwordError: "Password must be at least 8 characters long" });
      return;
    }
    this.setState({ staffLoginLoading: true });

    try {
      const { payload = {} } = await loginUser({
        email,
        password,
        staffId: id,
      });

      const { token, currentStaff: logStaff } = payload
      if (token && logStaff) {
        const { criticalStatusCount = 0 } = await AppointmentsApi.fetchStatusCounters(
          {...(logStaff.roleName === "Staff" ? {staffId: logStaff.id} : {})},
          { token }
        )
        setCriticalStatus(criticalStatusCount)
      }

      this.setState({
        staffLoginLoading: false,
        isOpenLoginDialog: false,
        isMenuOpened: false,
        anchorEl: null,
      }, loadLoginStaff);

      Router.replace('/');
    } catch (error) {
      console.log('handleLoginAsAnotherStaff_ERROR', error)
      const { body = {}} = error
      const { customerPortalLink } = body;
      this.setState({ staffLoginLoading: false, passwordError: error.message, customerPortalLink });
    }
  };

  handleStaffSearchChange = async ({ target: { value } }) => {
    const { auth } = this.props;
    if (value.length < 2) {
      this.setState({ staffSearchValue: value, staffSearchResults: [] });
      return;
    }
    this.setState({ staffSearchValue: value, loadingStaff: true });
    const staffSearchResults = await SearchApi.searchStaff(
      value,
      auth,
    );
    this.setState({ staffSearchResults, loadingStaff: false });
  };

  handleOpenInvoiceDialog() {
    this.setState({
      isInvoiceDialogOpened: true,
    });
  }

  handleCloseInvoiceDialog() {
    this.setState({
      isInvoiceDialogOpened: false,
      currentInvoiceId: '',
      isInvoiceNew: true,
    });
  }

  handleSearchResultLineClick = (result) => {
    switch (result.type) {
      case 'client':
        Router.push(result.href);
        break;
      case 'invoice':
        this.setState({
          isInvoiceDialogOpened: true,
          currentInvoiceId: result.id,
          isInvoiceNew: false,
        });
        break;
    }
  };

  handleSearch = (value) => {
    const { auth } = this.props;
    if (value.length < 3) {
      return SearchApi.searchInvoices(value, auth);
    }

    return SearchApi.searchByClientsAndInvoices(value, auth);
  };

  handleOpenRightSidebar() {
    this.setState({ isRightSidebarOpened: true });
  }

  handleCloseRightSidebar() {
    this.setState({ isRightSidebarOpened: false });
  }

  onClickCalendarIcon() {
    const calendarQueryAttrs = getQueryAttrsFromBrowser();
    const root = rootPath();

    if (!calendarQueryAttrs) {
      return Router.push(root);
    }

    Router.push({ pathname: root, query: calendarQueryAttrs });
  }

  getDefaultSettingsPath(items, currentStaffAccess) {
    const filteredAccess = currentStaffAccess?.filter(o => !getFilterSubItemsAllow(items).includes(o));
    const item = items.find(item => item.subItems.some(subItems => subItems.type === filteredAccess[0] && !subItems?.noDisplayed));
    return item?.subItems?.find(subItems => subItems.type === filteredAccess[0])?.route || '/';
  }

  render() {
    const {
      anchorEl,
      isInvoiceDialogOpened,
      isMenuOpened,
      isSupportMenuOpened,
      isAccountSettingsDialogOpened,
      currentInvoiceId,
      isInvoiceNew,
      isRightSidebarOpened,
    } = this.state;
    const {
      classes,
      handleOpenLeftSidebar,
      handleCloseLeftSidebar,
      showCriticalBar,
      showStepsBar,
      isLeftSidebarOpened,
      ...restProps
    } = this.props;

    const { auth: { currentStaff }, router: { pathname } } = restProps;
    const { firstName, lastName, avatarUrl, access: currentStaffAccess } = currentStaff;
    const isAccessNotEmpty = currentStaffAccess && currentStaffAccess.filter(o => !getFilterSubItemsAllow(items).includes(o)).length > 0;
    let topIndent = (showCriticalBar && showStepsBar) ? '4.2rem' : (showCriticalBar || showStepsBar) ? '2.1rem' : 0
    if (window?.innerWidth < 600 && (showCriticalBar || showStepsBar)) {
      topIndent = '1.72rem'
    }
    if (window?.innerWidth < 600 && (showCriticalBar && showStepsBar)) {
      topIndent = '3.44rem'
    }

    return (
      <nav className={classes.root}>
        <AppBar color="inherit" className={classes.navBar} style={{top: topIndent}}>
          <Toolbar
            className={classes.toolbar}
            classes={{
              regular: classes.toolbarRegular,
            }}
          >
            {isAllowForMenuIcon(pathname) ? (
              <>
                <Hidden lgUp>
                  <IconButton
                    onClick={isLeftSidebarOpened ? handleCloseLeftSidebar : handleOpenLeftSidebar}
                    className={classes.leftSidebarButton}
                    size="large"
                  >
                    <MenuIcon className={classes.menuIcon} />
                  </IconButton>
                </Hidden>
                <Hidden lgDown>
                  <div><AppBarLogo {...this.props} /></div>
                </Hidden>
              </>
            ) : (
              <div><AppBarLogo {...this.props} /></div>
            )}

            <Grid container className={classes.content}>
              <Hidden smDown>
                <Grid container sm={3} md={4} className={classes.sectionLeft}>
                  <Grid
                    item
                    onClick={this.onClickCalendarIcon}
                    className={classes.caneldarIconBox}
                  >
                    <span className={classes.link} title="calendar">
                      <AppointmentIcon />
                    </span>
                  </Grid>
                  <Grid item>
                    <span
                      onClick={this.handleOpenInvoiceDialog}
                      className={classes.link}
                      title="Create an invoice"
                    >
                      <ShoppingCartIcon />
                    </span>
                  </Grid>
                </Grid>
              </Hidden>

              <Grid container xs={12} sm={6} md={4} className={classes.sectionMiddle}>
                <SearchInput
                  placeholder="Search"
                  onSearch={this.handleSearch}
                  onResultLineClick={this.handleSearchResultLineClick}
                  onOpenPopper={({ isLoading, isDropdownOpened, searchResults }) => {
                    return isLoading || (isDropdownOpened && !_.isEmpty(searchResults));
                  }}
                  rootComponentProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="primary" />
                        </InputAdornment>
                      ),
                    },
                  }}
                  checkSkippingSearch={() => false}
                  className={classes.search}
                >
                  {({ type, ...result }, options) => (
                    searchResultOutputMap[type](result, { ...options, classes })
                  )}
                </SearchInput>
              </Grid>

              <Hidden smDown>
                <Grid container sm={3} md={4} className={classes.sectionRight}>
                  <Grid item className={classes.dashboard}>
                    <Link href="/dashboard">
                      <span className={classes.link} title="Dashboard">
                        <AnalyticsOneIcon />
                      </span>
                    </Link>
                  </Grid>

                  {(currentStaff.isOwner || isAccessNotEmpty)
                    && (
                      <Grid item className={classes.settings}>
                        <Link href={currentStaff.isOwner ? "/settings/details" : this.getDefaultSettingsPath(items, currentStaffAccess)}>
                          <span className={classes.link} title="Settings">
                            <SettingsIcon />
                          </span>
                        </Link>
                      </Grid>
                    )}
                  <Grid item className={classes.support}>
                    <SupportMenuButton
                      classes={classes}
                      isSupportMenuOpened={isSupportMenuOpened}
                      onClick={this.handleOpenSupportMenu}
                    />
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>

            <Grid container className={classes.accountBox}>
              <Hidden smDown>
                <AccountButton
                  classes={classes}
                  isMenuOpened={isMenuOpened}
                  onClick={this.handleOpenMenu}
                  avatarUrl={avatarUrl}
                  firstName={firstName}
                  lastName={lastName}
                />
              </Hidden>
              <Hidden smUp>
                <AccountButton
                  classes={classes}
                  isMenuOpened={isMenuOpened}
                  onClick={this.handleOpenRightSidebar}
                  avatarUrl={avatarUrl}
                  firstName={firstName}
                  lastName={lastName}
                />
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>

        <Hidden lgUp>
          <Drawer
            variant="temporary"
            className={classes.drawer}
            anchor="right"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={isRightSidebarOpened}
            onClose={this.handleCloseRightSidebar}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile
            }}
          >
            <AccountSideBar
              {...this.state}
              {...restProps}
              handleOpenAccountSettingsDialog={this.handleOpenAccountSettingsDialog}
              handleCloseRightSidebar={this.handleCloseRightSidebar}
            />
          </Drawer>
        </Hidden>

        <Popover
          id="hive-menu-appbar"
          anchorEl={anchorEl}
          open={isMenuOpened}
          onClose={this.handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={3}
        >
          <AccountMenu
            {...restProps}
            {...this.state}
            handleCloseMenu={this.handleCloseMenu}
            handleOpenStaffLogin={this.handleOpenStaffLogin}
            handleCloseStaffLogin={this.handleCloseStaffLogin}
            handleExitStaffLogin={this.handleExitStaffLogin}
            handleStaffSearchChange={this.handleStaffSearchChange}
            handleStaffPassword={this.handleStaffPassword}
            handleLoginAsAnotherStaff={this.handleLoginAsAnotherStaff}
            handleOpenAccountSettingsDialog={this.handleOpenAccountSettingsDialog}
            handleCloseAccountSettingsDialog={this.handleCloseAccountSettingsDialog}
          />
        </Popover>

        <Popover
          id="support-menu-dropdown"
          anchorEl={anchorEl}
          open={isSupportMenuOpened}
          onClose={this.handleCloseSupportMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={3}
        >
          <SupportMenu
            name={getFullName(currentStaff.firstName, currentStaff.lastName)}
            email={currentStaff.email}
          />
        </Popover>

        <Hidden mdDown>
          <CustomDialog
            fullWidth
            maxWidth="xs"
            open={isAccountSettingsDialogOpened}
            onClose={this.handleCloseAccountSettingsDialog}
          >
            <AccountSettings
              {...this.state}
              {...this.props}
              onClose={this.handleCloseAccountSettingsDialog}
            />
          </CustomDialog>
        </Hidden>
        <Hidden mdUp>
          <CustomDialog
            fullScreen
            maxWidth={false}
            open={isAccountSettingsDialogOpened}
            onClose={this.handleCloseAccountSettingsDialog}
          >
            <AccountSettings
              {...this.state}
              {...this.props}
              onClose={this.handleCloseAccountSettingsDialog}
            />
          </CustomDialog>
        </Hidden>

        <CustomDialog
          scroll="body"
          maxWidth="lg"
          open={isInvoiceDialogOpened}
          classes={{
            paperScrollBody: classes.paperScrollBody,
          }}
        >
          <Invoice
            {...restProps}
            currentInvoiceId={currentInvoiceId}
            isInvoiceNew={isInvoiceNew}
            isClientInputShown
            handleCloseInvoiceDialog={this.handleCloseInvoiceDialog}
            onExitCallback={() => {}}
          />
        </CustomDialog>
      </nav>
    );
  }
}

const mapStateToProps = ({ auth, loginStaff }) => ({
  auth,
  staff: loginStaff,
});

const mapDispatchToProps = (dispatch) => ({
  loadLoginStaff: bindActionCreators(loadLoginStaff, dispatch),
  loginUser: bindActionCreators(loginUser, dispatch),
  setCriticalStatus: bindActionCreators(setCriticalStatus, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(withStyles(NavBar, styles));
