import {
  Fragment,
  useState,
  useRef,
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import {
  MenuList,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import IconButton from '../../../../shared_components/buttons/Icon';
import MenuItem from './MenuItem';

const useStyles = makeStyles()((theme) => ({
  buttonRoot: {
    height: 'inherit',
    width: 'auto',
    padding: theme.spacing(),
  },
}));

const addOnClickToChild = (onClick) => (child) => {
  if (!isValidElement(child)) {
    return child;
  }

  return cloneElement(child, { onClick });
};

const customPlacement = 'bottom-end';

const MobileMenu = (props) => {
  const { children, className } = props;

  const {classes} = useStyles();
  const [isMenuOpened, setMenuOpened] = useState(false);
  const onClickCloseMenu = () => setMenuOpened(false);

  const menuAnchor = useRef(null);

  const updatedChildren = Children.map(
    children,
    addOnClickToChild(onClickCloseMenu),
  );

  return (
    <>
      <IconButton
        ariaControls="actions-menu"
        onClick={() => setMenuOpened(prevValue => !prevValue)}
        ref={menuAnchor}
        className={className}
        classes={{
          root: classes.buttonRoot,
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>

      <Popper
        transition
        open={isMenuOpened}
        anchorEl={menuAnchor.current}
        role={undefined}
        placement={customPlacement}
        style={{ zIndex: 10000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === customPlacement ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickCloseMenu}>
                <MenuList
                  id="actions-menu"
                  autoFocusItem={isMenuOpened}
                  onClick={onClickCloseMenu}
                >
                  {updatedChildren}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

MobileMenu.defaultProps = {
  menuProps: {},
  className: '',
};

export { MenuItem };

export default MobileMenu;
