/* eslint-disable import/no-unresolved */
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import CloseButton from '../buttons/Close';

const useStyles = makeStyles()((theme) => ({
  root: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2, 2),
    },
  },
  title: {
    color: '#4C4C4C',
    fontFamily: 'Roboto',
    fontSize: theme.spacing(5 / 2),
    fontWeight: 500,
    width: 'fit-content',
    paddingBottom: theme.spacing(1 / 2),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  buttons: {
    alignItems: 'center',
    width: 'auto',
  },
  extraButtonsBox: {
    width: 'auto',
    marginRight: theme.spacing(1 / 2),
  },
}));

const CustomDialogTitle = ({ extraButtons, className, children, onClose, titleStyle = {}, ...otherProps }) => {
  const {classes} = useStyles();

  return (
    <Grid
      container
      className={className}
      style={titleStyle}
      classes={{
        root: classes.root,
      }}
      {...otherProps}
    >
      <div className={classes.title}>
        {children}
      </div>

      <Grid container className={classes.buttons}>
        {extraButtons && (
          <Grid container className={classes.extraButtonsBox}>
            {extraButtons}
          </Grid>
        )}
        <Grid item>
          <CloseButton onClick={onClose} />
        </Grid>
      </Grid>
    </Grid>
  );
};

CustomDialogTitle.defaultProps = {
  className: '',
};

export default CustomDialogTitle;
