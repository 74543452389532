import { useState } from 'react';
import {
  Popper,
  Paper,
  ClickAwayListener,
} from '@mui/material';

import styles from './popper.module.css';

const CustomPopper = (props) => {
  const {
    open,
    anchorEl,
    placement,
    onClickAway,
    popperStyle,
    children,
  } = props;

  const [arrowRef, setArrowRef] = useState();

  return (
    <Popper
      // disablePortal
      id={styles.tooltip}
      open={open}
      anchorEl={anchorEl}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
              padding: 5,
            },
          },
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
      placement={placement}
      style={{
        zIndex: '2000',
        padding: 0,
        ...popperStyle,
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Paper elevation={6}>
          {children}
          <div
            id={styles.arrow}
            ref={setArrowRef}
          />
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

CustomPopper.defaultProps = {
  popperStyle: {},
};

export default CustomPopper;
