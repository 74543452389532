import { Done as DoneIcon } from '@mui/icons-material';

import BaseIcon from './BaseIcon';

const DoneButton = (props) => (
  <BaseIcon
    {...props}
    component={DoneIcon}
    iconProps={{
      color: 'secondary',
      viewBox: '3 3 18 18',
    }}
  />
);

export default DoneButton;
