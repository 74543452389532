import { DialogActions } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root:{
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3, 2),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  spacing: {
    '& > :not(:first-child)' : {
      marginLeft: theme.spacing(2),
    },
  },
}));

const CustomDialogActions = ({ className, children }) => {
  const {classes} = useStyles();

  return (
    <DialogActions
      className={className}
      classes={{
        root: classes.root,
        spacing: classes.spacing,
      }}
    >
      {children}
    </DialogActions>
  );
};

CustomDialogActions.defaultProps = {
  className: '',
};

export default CustomDialogActions;
