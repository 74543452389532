import Link from 'next/link'
import {
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';

import Avatar from './Avatar';
import { getInitials } from '../../../shared_client_utils/formUtils';
import { composeGCSUrl } from '../../../shared_client_utils/googleCloudStorageUtils';
import { navbarHeightMultiplier } from '../../../shared_client_utils/theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  avatarBox: {
    height: theme.spacing(navbarHeightMultiplier),
    alignItems: 'center',
  },
  staffInfo: {
    flexDirection: 'column',
    width: 'auto',
    marginLeft: theme.spacing(2),
  },
  firstName: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },
  role: {
    fontSize: theme.spacing(5 / 4),
    textTransform: 'uppercase',
    color: grey[700],
  },
  menuBox: {
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    padding: theme.spacing(),
  },
}));

const AccountSideBar = (props) => {
  const {
    handleOpenAccountSettingsDialog,
    handleCloseRightSidebar,
    auth: { currentStaff },
  } = props;
  const { firstName, lastName, avatarUrl, roleName } = currentStaff;
  const {classes} = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.avatarBox}>
        <Avatar
          size="small"
          src={composeGCSUrl(avatarUrl)}
        >
          {getInitials(firstName, lastName)}
        </Avatar>
        <Grid container className={classes.staffInfo}>
          <Grid item className={classes.firstName}>
            {firstName}
          </Grid>
          <Grid item className={classes.role}>
            {roleName}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.menuBox}>
        <Link href="/shift-management">
          <Grid
            item
            onClick={handleCloseRightSidebar}
            className={classes.link}
          >
            <Typography color="primary" variant="subtitle1">
              Shift Management
            </Typography>
          </Grid>
        </Link>
        <Grid
          item
          onClick={handleOpenAccountSettingsDialog}
          className={classes.link}
        >
          <Typography color="primary" variant="subtitle1">
            Account
          </Typography>
        </Grid>
        <Link href="/logout">
          <Grid
            item
            onClick={handleCloseRightSidebar}
            className={classes.link}
          >
            <Typography color="primary" variant="subtitle1">
              Log out
            </Typography>
          </Grid>
        </Link>
      </Grid>
    </Grid>
  );
};

export default AccountSideBar;
