/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from 'tss-react/mui';
import { useEffect } from 'react';
import Link from 'next/link';
import {
  Hidden,
} from '@mui/material';
import { setCriticalStatus } from '../../slices/appointmentsSlice';
import { AppointmentsApi } from '../../../../client_http_api';

const styles = (theme) => ({
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#da493e',
    fontWeight: '600',
    padding: '3px 1rem',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
  },
  action: {
    width: '15rem',
    padding: '2px',
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '.2rem',
    color: 'black',
    fontWeight: '700',
    cursor: 'pointer',
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f5f5f5',
      color: '#f29200'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '.75rem',
      width: '7rem',
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '80%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      fontSize: '.75rem',
    },
  },
  icon: {
    marginRight: '0.5rem',
  },
});

const CriticalBar = (props) => {
  const {
    appointments,
    classes,
    auth,
    setCriticalStatus,
    handleDisplayFlashMessage,
  } = props

  const { isCritical } = appointments;

  const setStatus = async () => {
    const isStaffAllow = auth?.currentStaff?.roleName === "Staff" && !auth?.currentStaff.access.includes('personalDashboard')
    try {
      const { criticalStatusCount } = await AppointmentsApi.fetchStatusCounters(
        {...(isStaffAllow ? {staffId: auth.currentStaff.id} : {})},
        auth
      )
      setCriticalStatus(criticalStatusCount)
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error.message || error, 'error')
      }
    }
  }

  useEffect(() => {
    setStatus()
  }, [appointments.byId])

  const message = `ALERT: ${isCritical} CRITICAL APPOINTMENTS`
  if (!isCritical) {
    return null
  }

  return (
    <div className={classes.bar}>
      <div className={classes.textContainer}>
        <span className={classes.message}>{message}</span>
      </div>
      <Hidden mdDown>
        <Link href="/dashboard?critical=true">
          <a className={classes.action}>Complete Now</a>
        </Link>
      </Hidden>
      <Hidden mdUp>
        <div />
      </Hidden>
    </div>
  );
}
const mapStateToProps = ({
  appointments,
}) => ({
  appointments,
});
const mapDispatchToProps = (dispatch) => ({
  setCriticalStatus: bindActionCreators(setCriticalStatus, dispatch),
});
const page = connect(mapStateToProps, mapDispatchToProps)(withStyles(CriticalBar, styles));
export default page;
