import { TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    cursor: 'pointer',
  },
}));

const SearchItem = (props) => {
  const {classes} = useStyles();

  return (
    <TableRow
      hover
      {...props}
      classes={classes}
    />
  );
};

export default SearchItem;
