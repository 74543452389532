import { forwardRef } from 'react';
import PhoneInput from 'react-phone-number-input/input';

const Input = forwardRef(({ component: Component, ...props }, ref) => (
  <Component {...props} inputRef={ref} />
));

const BaseInput = ({ country, ...props }) => (
  <PhoneInput
    {...props}
    inputComponent={Input}
    defaultCountry={country || 'CA'}
  />
);

export default BaseInput;
