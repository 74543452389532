import { Snackbar, Alert, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const FlashMessage = ({
  onClose,
  closeable,
  message,
  severity,
  anchor = {
    vertical: 'top',
    horizontal: 'right',
  },
  style = {marginTop: '2.1rem'},
  ...props
}) => {
  return (
    <Snackbar
      style={style}
      anchorOrigin={anchor}
      onClose={onClose}
      {...props}
    >
      <Alert
        severity={severity}
        variant={severity !== 'success' ? 'filled' : undefined}
        action={(
          <IconButton
            size="small"
            onClick={onClose}
            color="inherit"
          >
            <CloseIcon
              fontSize="small"
            />
          </IconButton>
        )}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
FlashMessage.defaultProps = {
  severity: 'success'
}

export default FlashMessage;
