import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import Radio from '../../../../shared_components/Radio';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
  },
  buttonBox: {
    marginTop: theme.spacing(15/8),
    justifyContent: 'space-between',
  },
  addButton: {
    marginRight: theme.spacing(2),
  },
  paddingCheckbox: {
    padding: theme.spacing(0, 0, 0, 2),
  },
}));

const RedeemPackage = (props) => {
  const {
    onClose,
    handleRedeemPackage,
    clientPackages,
    appointmentItem: { serviceId },
  } = props;

  const {classes} = useStyles();

  const [serviceClientPackages, setServiceClientPackages] = useState([]);
  const [selectedClientPackage, setSelectedClientPackage] = useState(null);

  useEffect(() => {
    const pkgs = clientPackages.filter((pkg) => {
      return pkg.serviceId === serviceId;
    });
    setServiceClientPackages(pkgs);

    const selectedPackage = pkgs.find((pkg) => pkg.availableQty > 0);
    if (selectedPackage) {
      setSelectedClientPackage(selectedPackage);
    }
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography color="secondary" variant="subtitle1">
          Apply package
        </Typography>
      </Grid>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell>Available qty</TableCell>
            <TableCell>Discounted price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceClientPackages.map((pkg) => (
            <TableRow key={pkg.id}>
              <TableCell
                classes={{
                  root: classes.paddingCheckbox,
                }}
              >
                <Radio
                  disabled={pkg.availableQty === 0}
                  checked={pkg.id === selectedClientPackage.id}
                  onChange={() => {
                    return setSelectedClientPackage(pkg);
                  }}
                  label={false}
                />
              </TableCell>
              <TableCell>{pkg.name}</TableCell>
              <TableCell>{pkg.availableQty}</TableCell>
              <TableCell>{pkg.newServicePriceMoney.toFormat()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Grid container className={classes.buttonBox}>
        <GreenButton
          variant="contained"
          size="small"
          onClick={() => handleRedeemPackage(selectedClientPackage)}
          className={classes.addButton}
        >
          Apply
        </GreenButton>
        <GreyButton
          variant="text"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </Grid>
    </Grid>
  );
};

export default RedeemPackage;
